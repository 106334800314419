import axios from "axios";
export const client = axios.create({
    baseURL: "https://stagingott.etvwin.com/",
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 500) {
            //localStorage.clear();
            //window.location.href = "/";
        }
        else if (error.response.status == 401) {
            // localStorage.clear();
            // window.location.href = "/";
        }
        else {
            return error.response
        }
    }

);


