import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  videodetailsitems : [],
  episodesDetails : [],
  isrelatedVideosClicked: false,
}

export const videoSlice = createSlice({
  name: 'videodetails',
  initialState,
  reducers: {
    setVideoDetails: (state, action) => {
      state.videodetailsitems = action.payload.videodetailsitems
    },
    setEpisodesDetails: (state, action) => {
      state.episodesDetails = action.payload.episodesDetails
    },

    setIsrelatedVideosClicked: (state, action) => {
      state.isrelatedVideosClicked = action.payload.isrelatedVideosClicked
    },
  },
})

export const { setVideoDetails, setEpisodesDetails, setIsrelatedVideosClicked} = videoSlice.actions

export default videoSlice.reducer