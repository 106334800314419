import React, { useEffect } from 'react';

const GPTAd = () => {
  useEffect(() => {
    const loadGPT = () => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          try {
            window.googletag
              .defineSlot('/23111733658/ca-pub-6245873721150554-tag/Test1D', [320, 480], 'div-gpt-ad-1724047810370-0')
              .addService(window.googletag.pubads());

            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.display('div-gpt-ad-1724047810370-0');
          } catch (e) {
            console.error('Error setting up GPT:', e);
          }
        });
      } else {
        console.error('GPT is not loaded or googletag.cmd is not available.');
      }
    };

    // Check if GPT script is already loaded
    if (window.googletag && window.googletag.apiReady) {
      loadGPT();
    } else {
      // Listen for GPT script load
      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      script.onload = loadGPT;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div className='text-center d-lg-block d-md-block d-none'>
      <center>
    <div
      id="div-gpt-ad-1724047810370-0"
       className="gpt-ad-container"
      ></div>
      </center>
      </div>
  );
};

export default GPTAd;
