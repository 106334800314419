import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterApplied: false,
};

export const filterAppliedSlice = createSlice({
  name: "filterdata",
  initialState,
  reducers: {
    setFilterApplied: (state, action) => {
        state.filterApplied = action.payload.filterApplied  
      },
  },
});

export const {setFilterApplied} = filterAppliedSlice.actions;

export default filterAppliedSlice.reducer;