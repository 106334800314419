import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loader() {
  return (
    <>
      <div
        className="text-center mt-4"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
         // transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner animation="border" variant="danger" />
      </div>
    </>
  );
}
