import React, { useState } from "react";
import "./inputfield.css";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const InputField = React.forwardRef(
  (
    {
      type,
      name,
      value,
      onChange,
      placeholder,
      error,
      checkboxLabel,
      checked,
      autoComplete,
      handleTogglePassword,
      disabled,
      required
    },
    ref
  ) => {
    const isError = Boolean(error);


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword((prevState) => !prevState);
    };

    const inputType = type === 'password' && showPassword ? 'text' : type;


    return (
      <div
        className={`form-group ${
          type === "checkbox" ? "form-group-checkbox" : ""
        }`}
      >
        {type !== "checkbox" && (
  <>
  <div className="showPassword">
      <input
        // type={type}
        type={inputType}
        disabled={disabled}
        className={`${isError ? "error-border" : ""}`}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={ref}
        required={required}
      />


      {type === 'password' && (
        <span className="password-toggle-icon showIcon" onClick={togglePasswordVisibility}>
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </span>
      )}

  </div>
  </>
)}


        {type === "checkbox" && (
          <>
            <input
              type={type}
              name={name}
              checked={checked}
              onChange={onChange}
              className={` ${checked ? "checked" : ""} agree-terms`}
              ref={ref}
            />

            {/* <label>
              {checkboxLabel}
            <span dangerouslySetInnerHTML={{ __html: checkboxLabel }} />
              </label> */}

            <label>
              I agree to the &nbsp;
              <Link
                className="termsandconditions"
                target="_blank"
                rel="noopener noreferrer"
                to="/termsandconditions"
              >
                TERMS OF USE
              </Link>{" "}
              &nbsp; and &nbsp;{" "}
              <Link
                className="privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
                to="/privacypolicy"
              >
                PRIVACY POLICY
              </Link>
            </label>
          </>
        )}

        {error && <p className="error-message errorMessage mt-2">{error}</p>}
      </div>
    );
  }
);

export default InputField;