import React, { useRef } from "react";
import Header from "../../Componets/Header/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import Footer from "../../Componets/Footer/Footer";
import ImageSlider from "../Homepage/ImageSlider";
import { useEffect } from "react";
import { useState } from "react";
import { bigmodel, horizontalmodel, singelgrid, twogrid, verticalmodel } from "../../layout/gridmodel";
import {
  useComponentUtils,
} from "../../layout/utilscomponent";
import Loader from "../../Componets/loader";
import useFetchCommon from "../../Componets/usefetchcommon";
import { SkeletonLoadSlider, SkeletonLoader } from "../skeletonLoad/skeletonthumbnail";
import CommonBannerSlider from "../../Componets/commonSlider";
import { setCatlog, setContent, setSelectedThumbnail, setTrayName } from "../../Redux/Slice/showSlice";
import { useDispatch, useSelector } from "react-redux";
import { acceptedMediaTypes } from "../../services/config";

import { GoChevronRight } from "react-icons/go";
import { setInnerPagesData } from "../../Redux/Slice/innerPageDataSlice";
const Movies = () => {

  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/twogridimage.jpg";
  const tv_shows_banner_image = "/images/singlegriddefault.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const fourthressplaceholder = "/images/4_3_placeholder.png";
  const threeressplaceholder = "/images/3_4_placeholder.png";
  const loginStatus = localStorage.getItem("login_status")
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [nPageSize, setNPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [moviePageData, setMoviePageData] = useState({});
  const [catalogListData, setCatalogListData] = useState([]);
  const [innerCatalogListData, setInnerCatalogListData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isrecordsfound, setIsrecordsfound] = useState(true);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const { pageName, friendly_id, moreseo_url, category, main } = useParams();
  const [isMoreAdded, setIsMoreAdded] = useState(true);
  const effectRan = useRef(false)
  const navigate = useNavigate()
const dispatch = useDispatch()
  const { renderCategoryBlockWithLayoutType } = useComponentUtils(
    catalogListData,
    hasMore,
    setHasMore,
    setLoading,
    loading,
    setCatalogListData,
    innerCatalogListData,
    setInnerCatalogListData
  );
  localStorage.removeItem("relatedmovietitle");
  localStorage.removeItem("event_title");
  localStorage.removeItem("event_genres");
  localStorage.removeItem("event_identifyunic");
  localStorage.removeItem("event_seo_url_friendly");
  localStorage.removeItem("event_seo_url");
  localStorage.removeItem("relatedmovietitle");

  localStorage.removeItem("event_first");
  localStorage.removeItem("event_secund");
  localStorage.removeItem("event_three");
  localStorage.removeItem("event_four");
  localStorage.removeItem("event_five");
  localStorage.removeItem("SublistTitle");
  // useEffect(() => {

  //   (async () => {
  //     let data = await fetchData();
  //     let updateddata
  //     if (catalogListData.length) {
  //       updateddata = [...catalogListData, ...data?.catlogData]
  //     } else {
  //       updateddata = [...data?.catlogData]
  //     }
  //     if (!moviePageData.catalog_list_items) {
  //       setMoviePageData(data?.homePageData)
  //     }
  //     setCatalogListData(updateddata)
  //   })();

  // }, [page]);


  useEffect(() => {
    if(effectRan.current === false){
      performFetch();
      return () => {
        effectRan.current = true
      }
    }
  }, []);
  const performFetch = async () => {
    if (loading) return; 
    setLoading(true);    

    try {
      let data = await fetchData();  

      if (!data || !data?.catlogData) {
        console.error('Data or catlogData is undefined.');
        setLoading(false); 
        return;
      }

      let updatedData = catalogListData.length
        ? [...catalogListData, ...(data?.catlogData || [])]
        : [...(data?.catlogData || [])];

      if (!moviePageData.catalog_list_items) {
        setMoviePageData(data?.homePageData);
      }
      dispatch(setInnerPagesData({innerPagesData: data?.homePageData}))
      setCatalogListData(updatedData);
      const newPage = page + 1;
      setPage(newPage);
      if(data?.homePageData?.catalog_list_items.length === 0){
        setIsrecordsfound(false);
      }
     
      setTotalItemsCount(data?.homePageData?.total_items_count);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);  
    }
  };
  const { innerPagesData, loadingData } = useSelector((state) => state.inner_page_data);

  // const handleScroll = () => {
  //   const nearBottom = window.innerHeight + window.scrollY > document.body.offsetHeight - 100; 
  //   if (nearBottom && !loading && isrecordsfound && totalItemsCount > 0) {
  //     performFetch();
  //   }
  // };

  const handleScroll = () => {
    const threshold = window.innerWidth <= 768 ? 400 : 100;
    if (
      catalogListData &&
      !loading  &&  isrecordsfound && moviePageData.total_items_count === totalItemsCount &&
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight - threshold 
    ) {
      //fetchMoreDetailsData();
      performFetch();
      window.removeEventListener("scroll", handleScroll);
  
    }
  
  
  }; 


  
  useEffect(() => {
    if(((page) * pageSize > moviePageData.total_items_count)) return;

   // window.addEventListener('scroll', loadMoreData);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [catalogListData,page,totalItemsCount]);

  // useEffect(() => {
  //   window.addEventListener('scroll', loadMoreData);
  // }, [isMoreAdded]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 640);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const loadMoreData = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 100
  //   ) {
  //     if ((page + 1) * pageSize >= moviePageData.total_items_count) return
  //     let pageNumber = page + 1
  //     window.removeEventListener('scroll', loadMoreData);
  //     setPage(pageNumber)
  //     // setTimeout(() => {
  //     //   setIsMoreAdded(!isMoreAdded)
  //     // }, 1000);
  //     setIsMoreAdded(!isMoreAdded);
  //   }
  // };

  const fetchData = useFetchCommon(
    page,
    pageSize,
    nPageSize,
    setInnerCatalogListData,
    setMoviePageData,
    setCatalogListData,
    setLoading,
    toast,
    setPage,
    setHasMore
  );


  const [imageLoadedArray, setImageLoadedArray] = useState(new Array(0).fill(false));
  const handleImageLoad = (index) => {
    setImageLoadedArray(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };
  const FooterCondition = () => {
    const allDataRendered = catalogListData.length > 0 && catalogListData.length === totalItemsCount;
    if (isMobile && !loading && allDataRendered) {
      return <Footer />;
    } else if (!isMobile && allDataRendered && isrecordsfound) {
      return <Footer />;
    }
    return null;
  };
  return (
    <>
      <div className="banner">
        {/* <Header /> */}
        {
(catalogListData &&
        catalogListData.length > 0) ? (
   <CommonBannerSlider
     innerCatalogListData={innerCatalogListData}
     catalogListData={catalogListData}
     homePageData={moviePageData}
   />
 ) : <SkeletonLoadSlider />}
        <div className="banner-layer"></div>

      </div>
      


      <div className="main-block">

      {catalogListData?.map((val, index) => {
  const layoutType = val.web_layout_type;
  let thumbnailSize = null;

  if (layoutType === 'etv-exclusive_banner' || layoutType === 'tv_shows_banner') {
    thumbnailSize = 'web_banner';
  } else if (['episode', 'latest_episode', 'videos', 'movie_poster', 'mini_clips'].includes(layoutType)) {
    thumbnailSize = 'medium_16_9';
  } else if (['tv_shows', 'movies', 'movie', 'top10', 'show'].includes(layoutType)) {
    thumbnailSize = 'medium_3_4';
  } else{
    thumbnailSize = 'medium_3_4';
  }

  const isBannerType = ['top_banner', 'banner', 'continue_watching', 'genre'].includes(layoutType);

  return (
    !isBannerType && (
      <React.Fragment key={index}>
        {renderCategoryBlockWithLayoutType(layoutType, val.display_title, val.layout_structure, thumbnailSize)}
      </React.Fragment>
    )
  );
})}
 {/* val.layout_type === "mini_clips" */}

{catalogListData?.map((val, index) =>  {
  let to_seo_url = window.location.origin + '/movies' + val.seo_url;
  return(
    val.layout_type === 'genre' && <>
     <div className="title-bar">
            <Container fluid className="pading-left-right-class">
              <Row>
                <Col>
                <h3 className="heading-font-size">{val.display_title}</h3>
                </Col>
                <Col xs="auto" style={{ textAlign: "end", padding: "0px" }}>

                <Link
                            key={index}
                            // to={to_seo_url}
                            to={`/${val.friendly_id}`}
                            className="more-heading"
                          >
                            More
                            <span>
                              <FaAngleDoubleRight />
                            </span>
                          </Link>
                </Col>
              </Row>
            </Container>

            {!loading && catalogListData?.length > 0 && <div className="TitleDivider"></div>}
          </div>
    
    {val.catalog_list_items.length > 0 && val.catalog_list_items.map((item, ind) => {

const layoutType = item.web_layout_type;

let isSliding = false;
let sliderProps = {};
let defultLocalimage = "";

if (item?.web_layout_type === 'etv-exclusive_banner') {
  sliderProps = twogrid;
  defultLocalimage = etv_exclusive_banner_image;

} else if (item?.web_layout_type === 'tv_shows_banner') {
  sliderProps = singelgrid;
  defultLocalimage = tv_shows_banner_image;

} else if (item?.web_layout_type === 'top10'  || item?.web_layout_type === 'movie') {
  sliderProps = bigmodel;
  defultLocalimage = top10_image;

} else if (item?.web_layout_type === "tv_shows" || item?.web_layout_type === 'show' || item?.web_layout_type === 'movies') {
  sliderProps = verticalmodel;
  defultLocalimage = horizontaldefultimage;

} else if (item?.web_layout_type === 'latest_episode' || item?.web_layout_type === 'episode' || item?.web_layout_type === "mini_movie_poster" ||  item?.web_layout_type === "movie_poster" || item?.web_layout_type === "videos" ||  item?.web_layout_type === "promo" || item?.web_layout_type === "mini_clips" || item?.web_layout_type === 'recipe' || item?.web_layout_type === "movie_poster") {
  sliderProps = horizontalmodel;
  defultLocalimage = verticaldefultimage;

}


let slidesPerView = 6.5; 
  
if (layoutType === "tv_shows_banner") {
  slidesPerView = 1;
} else if (layoutType === "tv_shows" || layoutType === "movies" || layoutType === "movie") {
  slidesPerView = 9;
} else if (layoutType === "etv-exclusive_banner") {
  slidesPerView = isMobile ? 1 : 2;
} else if (["latest_episode", "short", "episode", "mini_movie_poster", "movie_poster", "videos", "promo", "mini_clips", "recipe"].includes(layoutType)) {
  slidesPerView = 6.5;
}


const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
      <div 
          className={className}
          // className={`arrow ${className}`}
          onClick={onClick}
          style={{ cursor: "pointer" }} 
      >
        <GoChevronRight  />

          {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
      </div>
  );
}

function getPageName() {
  const path = window.location.pathname;
  const parts = path.split('/'); // Split path by '/'
  return parts[1] ?? "home"; // Return the second part after localhost/
}
const param1Value = getPageName();

return (
  
  <React.Fragment key={ind}>
    {(item?.web_layout_type === "tv_shows" && item.catalog_list_items.length > 0) && (
      <div key={index} className="first-block">
        <div className="first-slider">
          <div className="title-bar">
            <Container fluid className="pading-left-right-class">
              <Row>
                <Col>
                <div key={index} className="heading-font-size">
                      <h2>{item.display_title}</h2>
                    </div>
                </Col>
                <Col xs="auto" style={{ textAlign: "end", padding: "0px" }}>
                  {item.catalog_list_items.length >= 10 && (
                      <Link
                        key={index}
                        to={`/${item.friendly_id}`}
                        className="more-heading"
                      >
                        More
                        <span>
                          <FaAngleDoubleRight />
                        </span>
                      </Link>
                    )}
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid className="left-padding-grid">
          <div className={`smooth-slider ${item.title === 'Health & Fitness' ? 'healthlayout' : layoutType}`}>

        {item?.web_layout_type === 'tv_shows' &&
           <Swiper
           {...sliderProps}          
           className="slider-one"
                    navigation={!isMobile ? true : false}  modules={[Pagination, Navigation]}
  slidesPerView={layoutType === "etv-exclusive_banner" ? slidesPerView : 3}
  spaceBetween={0}
  onSlideChange={() => console.log('slide change')}
  onSwiper={(swiper) => SampleNextArrow}
  breakpoints={{
    "640": {
      slidesPerView: 3,
      spaceBetween: 11
    },
    "768": {
      slidesPerView: 4,
      spaceBetween: 11
    },
    "1024": {
      slidesPerView: slidesPerView,
      spaceBetween: 0
    }
  }}
         >
           {item?.catalog_list_items?.map((innerlist, innerIndex) => {
               let thumbnailSize = null;

               if (layoutType === 'etv-exclusive_banner' || layoutType === 'tv_shows_banner') {
                 thumbnailSize = 'web_banner';
               } else if (['episode', 'latest_episode', 'videos', 'movie_poster'].includes(layoutType)) {
                 thumbnailSize = 'medium_16_9';
               } else if (['tv_shows', 'movies', 'top10', ].includes(layoutType)) {
                 thumbnailSize = 'medium_3_4';
               }
               // console.log("pppppp", innerlist.list_item_object.banner_image !== '' && innerlist.list_item_object.banner_image)
             const maxCharacters = 20;
             let displayTitle = innerlist?.title?.length > maxCharacters 
                 ? innerlist?.title?.substring(0, maxCharacters) + "..." 
                 : innerlist?.title;

                 let gotoContentDetails = (link, data,innderindex) => {
                  const listItemlayoutType = (data.web_layout_type || data.layout_type) || 'NA';

                  const dynamicUrl = data?.seo_url;
                  const ourseourl = dynamicUrl?.startsWith("/")
                  ? dynamicUrl
                  : `/${dynamicUrl}`;
                  // console.log(`linklink`, ourseourl);
                  navigate(ourseourl);
                  const thumbnail = data?.thumbnails?.web_banner?.url;
                  dispatch(setSelectedThumbnail(thumbnail));
                  dispatch(setCatlog({ catlogId: data?.catalog_id }));
                  dispatch(setContent({ contentId: data?.content_id }));
                  localStorage.setItem("selectedCatelog", data?.catalog_id);
                  localStorage.setItem("selectedContent", data?.content_id);
                  localStorage.setItem("selectedVideoThumb", thumbnail);

                  if (window && window.sdk && window.sdk.trackEvent) {
                    window.sdk.trackEvent("artwork_tapped", {
                      source:param1Value,
                      video_name:data.title,
                      tray_name: data.display_title,
                      content_type: listItemlayoutType,
                      position_within_tray: innderindex+1,
                      artwork_type: listItemlayoutType,
                      platform: "web",
                      u_id:
                      loginStatus === true
                      ? getuserid
                      : guestuserid,
                 });
                
                  } else {
                    console.error('Analytics SDK is not initialized yet.');
                    // Handle initialization state or queue events for later
                  }

                 
            

                };
                //  console.log("innerlist", innerlist)
             return (
               <SwiperSlide key={innerIndex}>
                 <div
                 style={{cursor: 'pointer'}}
                   className="carousel-car continuewatch"
                   // onClick={() =>
                   //   gotoContentDetails(innerlist.seo_url, innerlist)
                   // }
                   onClick={(event) => {
                     dispatch(setTrayName({ layoutname: item }));
                     gotoContentDetails(innerlist.seo_url, innerlist,innerIndex)
                   }}
                 >
                   <div
                   className={`extra lock-icon-class ${!(item?.web_layout_type === 'tv_shows_banner' || item?.web_layout_type === 'etv-exclusive_banner') && 'cms-container'} cw-card`}
                   >
                       <div className={`thumbnailMovieCard ${(item?.web_layout_type === 'tv_shows_banner' || item?.web_layout_type === "tv_shows"  || item?.web_layout_type === 'etv-exclusive_banner') && 'transfimage'}`}>
                         {/* <img
                           src={innerlist?.thumbnails?.[thumbnailSize]?.url}
                           className="img-border-radis"
                           loading="lazy"
                           width="100%"
                           alt=""
                         /> */}

{ !imageLoadedArray[index]? (
                         <img
                         src={!imageLoadedArray[index] ? defultLocalimage: (innerlist?.thumbnails?.[thumbnailSize]?.url)}
                         width="100%"
                         onLoad={() => handleImageLoad(index)}
                      className={`${displayTitle === 'Health & Fitness' ? 'healthlayout' : layoutType}`}
                      alt={`${index}`}  
                      />
                       ):(
                        <img
                        src={imageLoadedArray[index] ? (innerlist?.thumbnails?.[thumbnailSize]?.url) : defultLocalimage}
                        width="100%"
                        onLoad={() => handleImageLoad(index)}
                        loading="lazy"
                        alt={`${index}`}
                        className={`${displayTitle === 'Health & Fitness' ? 'healthlayout' : layoutType}`}
                        />
                       ) }


                           {item?.web_layout_type === 'top10' && (
         <div className="top-show">
           <div className="top-show-icon">
             Top <span>{innerIndex + 1}</span>
           </div>
         </div>
       )}
                     {!loading && (innerlist?.access_control?.is_free === false && (
                       <div className="lock-postion-class"></div>
                     ))}
 
 {!loading && innerlist?.media_type && acceptedMediaTypes.includes(innerlist.media_type) && (
   <>
     <div className="iconcame">
       <FaPlay />
     </div>
   </>
 )}
 
                       </div>
 
 {!loading && ((innerlist.show_meta_data === 'yes' && innerlist?.media_type) && <p className="displayTitle">{displayTitle}</p>)}
 
                     {/* {!innerlist?.access_control?.is_free && <div className="lock-postion-class"></div>} */}
                   </div>
                 </div>
               </SwiperSlide>
             );
           })}
         </Swiper>
 }
        </div>
      </Container>
        </div>
      </div> 
    )}
  </React.Fragment>
);
})}



    </>
  )
}
 
)}


    {/* layout === "genre" */}

       

        {/* {loading && <Loader />} */}

        {loading && 
     <div
    className="text-center mt-4"
    style={{
      position: "fixed",
      top: "60%",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1050, // This should be higher than the z-index of the footer
    }}
  >
    <Spinner animation="border" variant="danger" />
  </div>
}
      </div>
      {/* {loading && <Loader />} */}

      {isMobile && !loading && <FooterCondition />}
{!isMobile && catalogListData && catalogListData.length > 0 && <Footer />}
    </>
  );
};

export default Movies;
