import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isprofilepicremove: false,
};

export const profilepicremoveSlice = createSlice({
  name: "update profile remove",
  initialState,
  reducers: {
    setIsprofilepicremove: (state, action) => {
      state.isprofilepicremove = action.payload.isprofilepicremove ;
    },
  },
});

export const { setIsprofilepicremove } = profilepicremoveSlice.actions;

export default profilepicremoveSlice.reducer;
