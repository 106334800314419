import React, { useEffect, useRef, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useParams, useLocation } from "react-router";
import { setFilterEpisodeData, setFilterEpisodeItemsData } from "../../Redux/Slice/filterEpisodeSlice";
import { setFilterApplied } from "../../Redux/Slice/filterappliedSlice";
import { setFilterFromDate, setFilterPage, setFilterToDate } from "../../Redux/Slice/filterdateSlice";

export default function FilterByDate() {
  const { episodeDates } = useSelector((state) => state.episodes);
  const {filterPage} = useSelector(
    (state) => state.filter_date
  ); 
  const inputRef = useRef(null);

  const [clickedInsideInput, setClickedInsideInput] = useState(false);

  const { category, seo_url } = useParams();
  const dispatch = useDispatch();
  const regionsData = useSelector((state) => state.region.regionsData);
  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showFromCalendar, setShowFromCalendar] = useState(false);
  const [showToCalendar, setShowToCalendar] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    dispatch(setFilterFromDate({ filterFromDate: date }));
    setShowApplyButton(true);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    dispatch(setFilterToDate({ filterToDate: date }));
    setShowApplyButton(true);
  };
 
  const today = new Date();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };
  const tileClassName = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return episodeDates.some((episodeDate) => {
      const [episodeYear, episodeMonth, episodeDay] = episodeDate
        .split("-")
        .map(Number);
      return (
        year === episodeYear && month === episodeMonth && day === episodeDay
      );
    })
      ? "highlighted"
      : null;
  };

  const fetchFilteredEpisodes = async () => {
    try {
        const formattedFromDate = fromDate ? formatDate(fromDate) : "";
        const formattedToDate = toDate ? formatDate(toDate) : "";
        const dataFilterURL = `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&start_time=${formattedFromDate}&end_time=${formattedToDate}&page=${filterPage}`;
        const response = await axios.get(dataFilterURL);
        if (response.status === 200) {
            dispatch(
                setFilterEpisodeData({
                    filterEpisodeData: response.data.data,
                })
            );

            if (response.data.data.total_items_count >= 0) {
                dispatch(
                    setFilterApplied({
                        filterApplied: true
                    })
                );
            }

            dispatch(
                setFilterEpisodeItemsData({
                    filterEpisodeItemsData: response.data.data.items
                })
            );

            handleClose();
            // dispatch(setFilterPage({ filterPage: 1 })); // Reset filterPage to 1 after applying filters
        }
    } catch (error) {
        console.error("Error fetching details:", error);
        if (error.message === "Request failed with status code 422") {
            console.log(error.message);
        } else if (error?.response?.status === 422) {
            console.log(error?.response?.data?.error?.message);
        }
    }
};

const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

const handleDateInput = (e, setDate) => {
  const inputDate = e.target.value;
  if (isValidDate(inputDate)) {
    setDate(new Date(inputDate));
    setShowApplyButton(true);
  } else {
    setDate(null);
  }
};

  return (
    <>
      <div onClick={handleShow} class="combination">
          <div class="combination">
            <div class="other-icons">
              <FaCalendar />
            </div>
            <div class="text">Filter by</div>
          </div>
        {/* {episodeDates && episodeDates?.length > 0 ? (
        ) : (
          ""
        )} */}
      </div>
      
      <Modal
        show={show}
        onHide={handleClose}
        className="pop-width filterCalculator"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter by date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="align-items-center">
              <Col lg={5} md={5} sm={6} xs={12}>
                <div>
                  <input
                    ref={inputRef}
                    id="from-date"
                    placeholder="From"
                    type="text"
                    value={fromDate ? fromDate.toLocaleDateString() : ""}
                    onChange={(e) => handleDateInput(e, setFromDate)}
                    onClick={() => {
                      setClickedInsideInput(true);
                      setShowFromCalendar(true);
                      setShowToCalendar(false);
                    }}
                    onBlur={() => setClickedInsideInput(false)}
                    // readOnly
                  />
                </div>
              </Col>
              <Col lg={5} md={5} sm={6} xs={12}>
                <div>
                  <input
                    ref={inputRef}
                    id="to-date"
                    placeholder="To"
                    type="text"
                    value={toDate ? toDate.toLocaleDateString() : ""}
                    onChange={(e) => handleDateInput(e, setToDate)}
                    onClick={() => {
                      setClickedInsideInput(true);
                      setShowFromCalendar(false);
                      setShowToCalendar(true);
                    }}
                    onBlur={() => setClickedInsideInput(false)}
                    // readOnly
                  />
                </div>
              </Col>
              <Col lg={2}>
                <Button
                  variant="primary"
                  onClick={fetchFilteredEpisodes}
                  disabled={!showApplyButton}
                  className="aplly-class w-100"
                >
                  Apply
                </Button>
              </Col>
            </Row>
            <div className="backDrop">
              <FaCalendar className="backdropIcon" />
              <p> Select dates to filter episodes</p>
            </div>

            <div className="date-main">
              {showFromCalendar && (
                <div className="calender">
                  <Calendar
                    onChange={(date) => handleFromDateChange(date)}
                    value={fromDate}
                    maxDate={today}
                    tileClassName={tileClassName}
                  />
                </div>
              )}

              {showToCalendar && (
                <div className="calender">
                  <Calendar
                    onChange={(date) => handleToDateChange(date)}
                    value={toDate}
                    maxDate={today}
                    tileClassName={tileClassName}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
