import { useEffect, useState } from "react";

export default function ErrorBoundary({ children }) {
    const [hasError, setHasError] = useState(false);
  
    useEffect(() => {
      const errorHandler = (error, errorInfo) => {
        console.error("Error caught by error boundary:", error, errorInfo);
        setHasError(true);
      };
  
      window.addEventListener("error", errorHandler);
      return () => {
        window.removeEventListener("error", errorHandler);
      };
    }, []);
  
    return hasError ? <div>Something went wrong. Please try again later.</div> : children;
  }