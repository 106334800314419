import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDataFetched: false,
};

export const innerPageDataFetch = createSlice({
  name: "datafetched",
  initialState,
  reducers: {
    setIsDataFetched: (state, action) => {
        state.isDataFetched = action.payload.isDataFetched  
      },
  },
});

export const {setIsDataFetched} = innerPageDataFetch.actions;

export default innerPageDataFetch.reducer;