import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  innerPagesData: [],
  loadingData: true
}

export const innerPageDataSlice = createSlice({
  name: 'innerpage data',
  initialState,
  reducers: {
    setInnerPagesData: (state, action) => {
      state.innerPagesData = action.payload.innerPagesData
    },
    setLoadingData: (state, action) => {
      state.loadingData = action.payload.loadingData
    },
  },
})

export const { setInnerPagesData, setLoadingData } = innerPageDataSlice.actions

export default innerPageDataSlice.reducer