import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginDetails: {},
  signinotpdata: {}
}

export const loginSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    setLoginDetails: (state, action) => {
      state.loginDetails = action.payload.loginDetails
    }, 
    
    setSigninotpdata: (state, action) => {
      state.signinotpdata = action.payload.signinotpdata
    }, 
  },
})

export const { setLoginDetails, setSigninotpdata} = loginSlice.actions

export default loginSlice.reducer