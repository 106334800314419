import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accountData: {},
  accountErrorData: {},
}

export const accountsDataSlice = createSlice({
  name: 'accounts data',
  initialState,
  reducers: {
    setAccountData: (state, action) => {
      state.accountData = action.payload.accountData
    }, 
    
    setAccountErrorData: (state, action) => {
      state.accountErrorData = action.payload.accountErrorData
    },
  },
})

export const { setAccountData, setAccountErrorData } = accountsDataSlice.actions

export default accountsDataSlice.reducer