import axios from 'axios';
import { BASE_URL, access_token, auth_token, sessionID } from '../../services/config';
import { setAccountData, setAccountErrorData } from '../../Redux/Slice/accountsDataSlice';

export const getAccountsData = () => {
  const getUserSessionId = () => localStorage.getItem("session_id") || sessionID;
  return async dispatch => {
    const usersessionID = getUserSessionId();
    const PROFILE_DETAILS = `${BASE_URL}users/${usersessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
    
    try{
      const response = await axios.get(PROFILE_DETAILS)

      if(response.status === 200){
        dispatch(setAccountData({ accountData: response?.data?.data}));
        localStorage.setItem("isUserSubscribed", response?.data?.data.isUserSubscribed)
      }
    }catch(err){
        // console.error(err);
        dispatch(setAccountErrorData({ accountErrorData: err?.response?.data}));
    }
  }
  }

