import React from 'react'
import Header from '../Componets/Header/Header'
import Routers from '../routes/Routers'
import Footer from '../Componets/Footer/Footer'


const Layout = () => {
  return (
    <div className='main-section'>
  
        <main>
            <Routers />
        </main>
      

    </div>
  )
}

export default Layout