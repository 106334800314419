import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  const emptyStars = 5 - fullStars - halfStars;

  const renderStars = () => {
    let stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} />);
    }
    for (let i = 0; i < halfStars; i++) {
      stars.push(<FaStarHalfAlt key={i + fullStars} />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaStar key={i + fullStars + halfStars} />);
    }
    return stars;
  };

  return (
    <div style={{color: "#ffc908", fontSize: "22px"}} className="star-rating">
      {renderStars()}
    </div>
  );
};

export default StarRating;
