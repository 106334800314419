import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  regionsData: { country_code2: "IN" },
};

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRegionsData: (state, action) => {
      state.regionsData = action.payload;
    },
  },
});

export const { setRegionsData, regionsData } = regionSlice.actions;

export default regionSlice.reducer;
