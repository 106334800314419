// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging,isSupported,getToken,onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCzipF-DIrRwovw-A1g8JOW6LYGkaqmgE0",
    authDomain: "etv-win-200809.firebaseapp.com",
    projectId: "etv-win-200809",
    storageBucket: "etv-win-200809.appspot.com",
    messagingSenderId: "881568280179",
    appId: "1:881568280179:web:6d8cebc5cbce50fec00406",
    measurementId: "G-1JYK5B0GE8"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseapp);
//getToken(firebasemessaging, {vapidKey: "BKagOny0KF_2pCJQ3m....moL0ewzQ8rZu"});

let firebaseMessaging;

isSupported().then((supported) => {
  if (supported) {
    firebaseMessaging = getMessaging(firebaseapp);
    // Firebase Messaging is supported, continue with token retrieval and message handling
    getToken(firebaseMessaging, {
      vapidKey: "BJKhcgWgdPCVQwLEDJE_EGAHD2Izcl7gOTkKQEevtS46vW67xBV20SSf0Gb4oCBn1kPcbMGaFvOx2GfqaUXiFxo",
    }).then((currentToken) => {
      if (currentToken) {
        console.log("Firebase Token", JSON.stringify(currentToken));
        window.localStorage.setItem('fcm_token', JSON.stringify(currentToken));
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
        // ...
      }
    }).catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });

    onMessage(firebaseMessaging, function(payload) {
      console.log("Message received. ", payload);
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
        .then(registration => {
          registration.showNotification(
            payload.data.title,
            payload.data
          )
        });
    });
  } else {
    console.log("Firebase Messaging is not supported in this browser.");
  }
}).catch((err) => {
  console.error("Error occurred while checking Firebase Messaging support:", err);
})

export { firebaseapp, firebaseAnalytics,firebaseMessaging };
