import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isprofileload: false,
};

export const profilepicloadSlice = createSlice({
  name: "profile spinner",
  initialState,
  reducers: {
    setIsprofileload: (state, action) => {
      state.isprofileload = action.payload.isprofileload ;
    },
  },
});

export const { setIsprofileload } = profilepicloadSlice.actions;

export default profilepicloadSlice.reducer;
