// useFetchCommon.js
import { useState } from "react";
import { access_token, auth_token, buildApiUrl } from "../services/config";
// import useRegionData from "./regions/regions";
import { useFetchData } from "./usefetch";
import { useSelector } from "react-redux";

const useFetchCommon = (
  page,
  pageSize,
  nPageSize,
  setInnerData,
  setComponentData,
  setListData,
  setLoading,
  toast,
  setPage,
  setHasMore
) => {
  // const { region } = useRegionData();
  const regionsData = useSelector(state => state.region.regionsData)


  const selectedItem = localStorage.getItem("selectedMenuItem");

  const apiUrlParams = {
    item_language: "eng",
    region: regionsData?.country_code2,
    auth_token: auth_token,
    access_token: access_token,
    page: page,
    page_size: pageSize,
    npage_size: nPageSize,
  };
  const currentPath = window.location.pathname;
  const MenuName = currentPath.startsWith("/") ? currentPath.substring(1) : currentPath;

//alert(currentPath);
  const API_DETAILS = buildApiUrl("catalog_lists", MenuName, apiUrlParams);
  const fetchData = useFetchData(
    API_DETAILS,
    setInnerData,
    setComponentData,
    setListData,
    setLoading,
    toast,
    setPage,
    setHasMore
  );
  return fetchData;
};

export default useFetchCommon;
