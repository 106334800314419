import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const JsonLd = ({ json }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(json)}</script>
    </Helmet>
  );
};

JsonLd.propTypes = {
  json: PropTypes.object.isRequired,
};

export default JsonLd;
