import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSubscribeButtonStatus: false,
};

export const subscribeButtonSlice = createSlice({
  name: "subscribebuttonstatus",
  initialState,
  reducers: {
    setShowSubscribeButtonStatus: (state, action) => {
        state.showSubscribeButtonStatus = action.payload.showSubscribeButtonStatus  
      },
  },
});

export const {setShowSubscribeButtonStatus} = subscribeButtonSlice.actions;

export default subscribeButtonSlice.reducer;