import axios from 'axios';
import { BASE_URL, access_token, auth_token } from '../../services/config';
import { setUserConfigData } from '../../Redux/Slice/userConfigSlice';

export const getUserConfigData = (regionsData) => {
    
    return async dispatch => {
    const useParamURL = `${BASE_URL}/catalogs/message/items/app-config-params.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;

    try{
      const response = await axios.get(useParamURL)

      if(response.status === 200){
        dispatch(setUserConfigData({ userConfigData: response?.data?.data}));

      }
    }catch(err){
      console.log(err);
    }
  }
  }
