import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  emailRegisterData: {},
}

export const emailRegisterSlice = createSlice({
  name: 'emailregister',
  initialState,
  reducers: {
    setEmailRegisterData: (state, action) => {
      state.emailRegisterData = action.payload.emailRegisterData
    },
  },
})

export const { setEmailRegisterData } = emailRegisterSlice.actions

export default emailRegisterSlice.reducer