import React from 'react';
import JsonLd from './JsonLd';

const LiveStream = (videoSource) => {
  const jsonLdData = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": "ETV Andhrapradesh",
    "description": "Watch ETV Andhrapradesh Live Tv Streaming On ETV Win",
    "thumbnailUrl": [
      "https://etv-win-image.akamaized.net/etvwin/live/channel/47688/etv-andhra-pradesh-768x432.jpg"
    ],
    "uploadDate": "2024-05-18T07:40:10.000Z",
    "duration": "PT24H00M00S",
    "embedUrl": "https://www.etvwin.com/live/etv-andhra-pradesh-1",
    "regionsAllowed": [
      {
        "@type": "Place",
        "name": "IN"
      }
    ],
    "publication": {
      "@type": "BroadcastEvent",
      "isLiveBroadcast": true,
      "startDate": "2024-05-17T07:40:10.000Z"
    },
    "liveStreaming": true
  };

  return (
    <div>

      <JsonLd json={jsonLdData} />
    </div>
  );
};

export default LiveStream;
