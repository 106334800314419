import axios from "axios";
import React, { useState } from "react";
import { FaPlay, FaUserCheck, FaUserPlus } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import { useDispatch, useSelector } from "react-redux";

export default function FollowFeature({
  detailsData,
}) {
  const dispatch = useDispatch();
  const regionsData = useSelector(state => state.region.regionsData)

  const isUserLoggedIn = localStorage.getItem("login_status");
  const session_id = localStorage.getItem("session_id");
  const [followdata, setFollowdata] = useState({});

  const [contentFollowed, setContentFollowed] = useState(false);
  const [unfollowresponse, setUnfollowresponse] = useState({});

  const followURL = `${BASE_URL}users/${session_id}/playlists/favourite`;

  const handleFollowButton = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        followURL,
        {
          auth_token: auth_token,
          access_token: access_token,
          listitem: {
            content_id: detailsData?.content_id,
            catalog_id: detailsData?.catalog_id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setContentFollowed(true);
        // toast.success("You have followed this content");
        const followres = response?.data?.data;
        // console.log("followres", followres[0]);
        setFollowdata(followres[0]);
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };

  const unfollowURL = `${BASE_URL}users/${session_id}/playlists/favourite/listitems/${followdata?.listitem_id}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  const handleUnfollowButton = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(unfollowURL);
      if (response?.status === 200) {
        // toast.success("Unfollowed");
        setContentFollowed(false);
        // console.log("unfollow data", response?.data);
        setUnfollowresponse(response?.data);
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };

  return (
    <>
      <div class="combination">
        <div class="other-icons">
          {!contentFollowed ? (
            <>
              {isUserLoggedIn ? (
                <FaUserPlus onClick={handleFollowButton} />
              ) : (
                <Link to="" onClick={() => dispatch(setModalOpen())}>
                  <FaUserPlus />
                </Link>
              )}
            </>
          ) : (
            <FaUserCheck onClick={handleUnfollowButton} />
          )}
        </div>
        <div class="text">Follow</div>
      </div>
    </>
  );
}
