import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  catlogId: "",
  contentId: "",
  selectedThumbnail: "",
  relatedDetails: {},
  layoutname: {}
}

export const showSlice = createSlice({
  name: 'show',
  initialState,
  reducers: {
    setCatlog: (state, action) => {
      state.catlogId = action.payload.catlogId
    },
    setContent: (state, action) => {
      state.contentId = action.payload.contentId
    },
    setSelectedThumbnail: (state, action) => {
      state.contentId = action.payload
    },
    setRelatedDetails: (state, action) => {
      state.relatedDetails = action.payload.relatedDetails
    },

    setTrayName: (state, action) => {
      state.layoutname = action.payload.layoutname
    },
  },
})

export const { setCatlog, setContent, setSelectedThumbnail, setRelatedDetails, setTrayName } = showSlice.actions

export default showSlice.reducer