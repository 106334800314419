import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateMobileNumber: false,
};

export const updateMobilemodalSlice = createSlice({
  name: "update mobile modal",
  initialState,
  reducers: {
    setUpdateMobileModalShow: (state, action) => {
      state.updateMobileNumber = action.payload.updateMobileNumber ;
    },
  },
});

export const { setUpdateMobileModalShow } = updateMobilemodalSlice.actions;

export default updateMobilemodalSlice.reducer;
