import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Otherprivacypolicy = () => {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      {/* <Header /> */}


      <div className=''>



   <Container>
   
   <div className='about-main'>
   <h4 >Privacy Policy</h4>
   <br></br>
  <div className='policy-content'>

    <p>Eenadu Television Private Limited (Company) owns and operates the website “etvwin.com” (site) and associated mobile Apps, mobile sites, Smart TV apps (Android TV & Fire TV Stick), other service/s under the name and style of “ETV Win”, hereinafter referred to as “ETV Win platform”.</p>

    <p>This privacy policy to be read in combination along with “terms of use” made available on ETV Win platform and forms an integral part of the user agreement between the user of ETV Win platform and the Company. Certain capitalized terms not defined herein shall have the meaning as attributed to them under the “terms of use”.</p>
    <p>We understand the importance of your right to privacy, hence have elicited the below privacy statement with regard to the information we collect from you.</p>
    <p>This privacy statement explains our practices, including your choices, regarding the collection, use and disclosure of certain information, including your personal information by ETV Win platform.</p>
    <p>
A user must be a major, that is more than 18 years of age, to subscribe to ETV Win, but a minor can view programmes only under their parental guidance. ETV win advises subscribers with young children at home to be watchful and monitor their children viewing as all content may not be safe and suitable for children.</p>


<p>In case you are not willing to accept this privacy policy or any of the following terms thereof, you are requested to abstain from accessing or using ETV Win platform.</p>
<h6>USER INFORMATION:</h6>

<p>Users of ETV Win Platform are to be aware that any data, information, content or images that you post, submit or upload to ETV Win Platform shall be stored on Company’s servers for the purposes of making the product and services available to you. Hence users are requested not to post any personally identifiable information or sensitive personal data that you do not want to be made available to public at large or to Company.</p>

<p>For all online transactions, ETV Win platform use third party services. If you have questions or concerns regarding this policy, please email to us on Email: privacypolicy@etvwin.com.</p>

<p>User information such as email address, age, name and Date of birth are collected only if user subscribes to ETV Win. ETV Win may also obtain information relating to the user from any correspondence the subscriber has with ETV Win through emails.</p>

<p>
ETV win collects information from their viewers to understand their interests better. Based on the information collected and stored, ETV Win recommends programmes to their viewers suiting their interests and taste.</p>
<p>Any such information collected from the viewers by ETV Win For the purpose of making user specific recommendations shall by displayed to the user under the title “use of user info”</p>
<p>ETV Win stores information like user ID and passwords of the subscriber through cookies, to make the log-in of the user hassle-free, without having to key in user ID and passwords every time the subscriber logs into the platform.</p>
<p>The users are advised that ETV win shall not be responsible in any manner, for any data relating to the user collected by 3rd party applications particularly smart features offered by some OTT platforms.</p>
<p>ETV Win will have access through their partners, of information relating to the user, give such user has shared such information in public domain, if the user is using the same email ID and mobile number both in the public domain and for ETV Win.</p>
<p>If users wish to access any premium content offered by ETV Win, we may collect some additional information from them, including a billing address, a credit card number and a credit card expiration date and tracking information. This information collected from users is NOT shared:</p>
<p>In order to view premium content on ETV Win, users need to take subscription of ETV Win by paying a certain amount periodically. ETV Win redirects the users to payment gateways to make payment. Subscription payment though made to ETV Win is not an “IN APP PURCHASE”. Users are hence advised to read the privacy policies of such payment gateways.</p>


<p>
The information gathered from users of ETV Win platform is shared with any individual or organization only with the consent of the user. Users registering on "ETV Win platform" shall be automatically consenting for receiving calls or Text Messages or notifications as applicable on Mobile Apps of platform and emails</p>


<p>Except where users are expressly informed, “ETV Win platform” does not sell, rent, or loan any identifiable information at the individual level regarding its customers to any third party. All information provided by users is held with the utmost care and security. However, “ETV Win Platform” is also bound to cooperate fully should a situation arise where we are required by law or legal process to provide information about a customer.</p>
<h6>Social Media:</h6>
<p>If users access ETV Win through social media, ETV Win shall have access to all or any information that the users have allowed/permitted such Social Media Platform to share with ETV Win or any information that the users have shared with public through such Social Media Platform.</p>


<p>ETV Win administrates/monitors the user behaviour. Any feedback/ suggestions /recommendations given to ETV Win by the user shall be appreciated and considered by ETV Win. However, lewd comments or hate comments shall be actionable by ETV Win.</p>

<p>ETV Win collects Internet based advertisements, push notifications etc. in order to serve better /recommend programmes ETV Win users.</p>

<h6>Links to external websites:</h6>
<p>
ETV Win platform may contain links to external websites. ETV Win platform shall not be responsible for the privacy practices of external websites and users are advised to check the privacy policy of such third party websites. We request you to be fully aware of this when you leave ETV Win Platform. Do read the privacy statements of each and every website that collects personally identifiable information.</p>



<p>If you want to delete your personal information which is existing with us, you can do so by raising a request to support@etvwin.com. For authentication, please send us the request from your registered mail ID to ensure that the deletion request came from you. Upon confirmation, we will delete your personal information.</p>
<p>This privacy statement applies solely to information collected by ETV Win platform.</p>
<p>The profile created by the user at the time of registering for the subscription of ETV win shall be stored in their servers as the personal information of the subscriber and such information shall be in possession of it win ETV win so long as the subscription of the user continues.</p>
<p>
In case the user does not wish ETV Win to collect his information through cookies, he may make necessary changes in his device settings.</p>

<h6>Cookies on ETV Win platform:</h6>
<p>Cookies on ETV Win platform are used to deliver various services and keep track of your personal preferences. If you do not want cookies to collect information on ETV Win platform, most browsers contain a function that allows a user to deny cookies on websites. Alternatively you can also delete all cookie files on your computer. Please note that disabling the cookie feature on your browser or deleting cookie files from your computer will render you unable to access certain features on ETV Win platform, as the use of cookies is a necessary part of the ETV Win platform technology. ETV Win platform uses cookies only to:</p>


<ul>
<li>Provide its services.</li>
<li>Deliver content specific to your interests.</li>
<li>Save your password (so you do not have to re-enter it each time you visit different pages on our site) and for other purposes.</li>
</ul>


<p>Please note: Cookies are used only to recollect information sent to your computer from ETV Win Platform. We CANNOT access any information not sent by ETV Win platform. Some of our partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</p>
<p>Changes in privacy policy of ETV Win Platform: Company reserves right to amend the privacy policy of ETV Win Platform. Users are advised to periodically check our privacy policy for any amends.</p>
 <p>Laws of India including privacy law and data protection law to the extent available to Indian citizens shall be applicable in respect of any dispute between the User and ETV Win irrespective of the physical location of the parties, servers, computer systems etc.</p>




 <p>Any sensitive information (such as a credit card number) provided during registration/order process information, is encrypted and is protected with the standard encryption software in the industry – SSL as practiced by respective payment gateway.</p>
 
 
 
 <p>Apart from using SSL encryption, ETV Win Platform takes every measure in its power to protect sensitive information Some of the staff users at ETV Win Platform are granted access to such personally identifiable information, needed for performing specific tasks (for example, a billing clerk or a customer service representative).</p>

 <h6>ETV Win Platform uses email extensively and users may receive emails for the following services:</h6>

 <ul>
  <li>Newsletters with updates on the latest features on ETV Win Platform.</li>
  <li>Announcements of special events.</li>
 
 </ul>
 
 
 <p>If you have any questions about this privacy statement, the practices of ETV Win Platform, or your dealings with this website, please email us or contact us at.</p>
 
 
 
  </div>
  <div className='content-details'>
    <h6>Eenadu Television Private Limited</h6>
    <h6>Ramoji Film City</h6>
    <h6>Hyderabad</h6>
    <h6>Phone : 08415-246555</h6>
    <h6>You are also advised to peruse terms and conditions.</h6>
  </div>


</div>

</Container>
    </div>
  

      </>
  )
}

export default Otherprivacypolicy