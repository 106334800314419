import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isRendered: false,
};

export const isDataRendered = createSlice({
  name: "datafetched",
  initialState,
  reducers: {
    setIsRendered: (state, action) => {
        state.isRendered = action.payload.isRendered  
      },
  },
});

export const {setIsRendered} = isDataRendered.actions;

export default isDataRendered.reducer;