import React, { useRef, useState } from 'react'
import Header from '../../Componets/Header/Header'
import { Container } from 'react-bootstrap'
import Footer from '../../Componets/Footer/Footer'
import { TERMS_CONDITONS } from '../../services/config'
import { useEffect } from 'react'
import axios, { Axios } from 'axios'

const TermsAndConditions = () => {
  const effectRan = useRef(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [teamandcondiitons, setTeamandcondiitons] = useState([]);

  useEffect(() => {
    if(effectRan.current === false){
      getTermsData();
      return () => {
        effectRan.current = true
      }
    }
  }, []);
  
  const getTermsData = async () => {
    try {
      const response = await axios.get(TERMS_CONDITONS);
  
      const menuitemslistd = response?.data.static_page;
  
      if (response?.status === 200) {
        console.log("Tearmsconditions:", response?.data);
  
        setTeamandcondiitons(menuitemslistd);
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
     
    }
  };

  




// useEffect(() => {
//   window.scrollTo(0, 0); 
// }, []);




  return (
    <>
    {/* <Header /> */}
  
  

    <div className='main '>
      <Container>

        {/* <h4>{teamandcondiitons.display_title}</h4> */}
        <div className='about-main cms-data'>
        <div className='privachy'>
        <div dangerouslySetInnerHTML={{ __html: teamandcondiitons.description }} />
      
 

</div>
      
        </div>

      </Container>
      <Footer />
    </div>

  </>
  )
}

export default TermsAndConditions