import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "./App.css";
import "./responsive.css";
import App from "./App";
import { Provider } from 'react-redux';
import { Store } from './Redux/Store';
import "../node_modules/react-bootstrap/dist/react-bootstrap.js";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Sco from "./Sco.jsx";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  // <React.StrictMode>
    <Provider store={Store}>
      <HelmetProvider>
        <Sco />
      </HelmetProvider>
      <BrowserRouter>
        <CookiesProvider>

          <App />

        </CookiesProvider>
      </BrowserRouter>
    </Provider >
  // </React.StrictMode>
);