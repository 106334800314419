import React, { useRef, useState } from "react";
import "../pages/Homepage/ImageSlider";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaPlay,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { BASE_URL, access_token, auth_token } from "../services/config";
import { toast } from "react-toastify";
// import useRegionData from "../../Componets/regions/regions";
import { useEffect } from "react";
import { SkeletonLoadSlider } from "../pages/skeletonLoad/skeletonthumbnail";
import { setModalOpen } from "../Redux/Slice/loginModalSlice";
import { FaVolumeHigh, FaVolumeXmark } from "react-icons/fa6";
import Slider from "react-slick";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

function CommonBannerSlider({
  catalogListData,
  homePageData,
  recipeData,
  tvShowsData,
  livePageData,
  healthPageData,
  agriculturePageData,
  NewsPageData,
  moviePageData,
  newsPageData,
  innerCatalogListData,
  loading,
}) {

  const notitleimage = "/images/no-image-1.png";

  const SampleNextArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
          <GoChevronRight  />
  
            {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
        </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
            {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
        </div>
    );
}



  const [activeSlide, setActiveSlide] = useState(0);

  const sliderRef = useRef();
  // console.log("livePageData:", livePageData); 
  // console.log("newsPageData:", newsPageData); 
  // console.log("agriculturePageData:", agriculturePageData); 

  const regionsData = useSelector((state) => state.region.regionsData);

  const dispatch = useDispatch();
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991); 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { sessionId } = useSelector((state) => state.auth);

  const Max_Limit = 5;
  // const { region, regiondetails } = useRegionData();

  const isUserLoggedIn = localStorage.getItem("login_status");
  const { likeData } = useSelector((state) => state.like_dislike_details);
  // console.log("likeDataaaa", likeData)
  const [hovered, setHovered] = useState(false);
  const [mylistaddresponse, setMylistaddesponse] = useState({});
  const session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const [addedtomylist, setAddedtomylist] = useState(false);
  const [addedinMobilelist, setAddedinMobilelist] = useState(false);
  const [foundInMyList, setFoundInMyList] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [noVideoUrl,setNoVideoUrl] = useState(false);
  const effectRan = useRef(false)
  
  const [mylistremoveresponse, setMylistremoveresponse] = useState({});
  const user_id = localStorage.getItem("user_id");
  const WatchLaterURL = `${BASE_URL}users/${session_id}/playlists/watchlater`;
  const storedDetailsDataJSON = localStorage.getItem("videodetailsdata");
  const [isSliderClicked, setIsSliderClicked] = useState(false);
  const [clickDelay, setClickDelay] = useState(null);

  const storedDetailsData = JSON.parse(storedDetailsDataJSON);
  const [myList, setMyList] = useState([]);
  const [currentIndex, setcurrentIndex] = useState(0);

  const [previewImg, setPreviewImg] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");

  const [initialBannerImageLimit, setInitialBannerImageLimit] = useState(0);
  const [maxBannerImageLimit, setMaxBannerImageLimit] = useState(5);
  const [watchLaterData, setWatchLaterData] = useState([]);
  const [listItemIdForDelete, setListItemIdforDelete] = useState("");
  const [isMuted, setIsMuted] = useState(true); 
const playerRef = useRef(null); 

const handleVolumeToggle = () => {
  setIsMuted(prevState => !prevState); 
};

  // console.log("mylistdata", storedDetailsData);

  // const handleMyListAdd = async (event, catalog_id, content_id) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       WatchLaterURL,
  //       {
  //         auth_token: auth_token,
  //         access_token: access_token,
  //         user_id,
  //         list_type: "watchlater",
  //         listitem: {
  //           catalog_id: catalog_id,
  //           content_id: content_id,
  //         },
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response?.status === 200) {
  //       window.sdk.trackEvent('watch_later', {
  //         content_type: storedDetailsData.media_type,
  //         genre: storedDetailsData.genres,
  //         video_name: storedDetailsData.title,
  //         video_language: storedDetailsData.language,
  //        event_id: 7,
  //       });
  //       setAddedtomylist(true);
  //       toast.success("Added to my list");
  //       const mylistc = response?.data?.data;
  //       setMylistaddesponse(mylistc[0]);
  //       console.log("Watch Later data", mylistc[0]);
  //     }
  //   } catch (err) {
  //     console.error("Server responded with an error:", err.response.data);
  //   }
  // };

  const getMyListURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  useEffect(() => {
    if(effectRan.current === false){
      if(session_id){
        fetchGetMyListData();
      }
      return () => {
        effectRan.current = true
      }
    }
  }, [session_id]);

  const fetchGetMyListData = async () => {
    try {
      const response = await axios.get(getMyListURL);
      if (response.status === 200) {
        // setMywatchlistdata(response?.data?.data);
        // setCatalogListData(response?.data?.data?.items);
        // const listitemid = response?.data?.data?.items.map(
        //   (item) => item.listitem_id
        // );
        // const listitemtitle = response?.data?.data?.items.map(
        //   (item) => item.title
        // );
        // setListTitle(listitemtitle);
        // setlistitemid(listitemid);
        setWatchLaterData(response?.data?.data.items);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const handleMyListAdd = async (event, catalog_id, content_id, index) => {
    if (isUserLoggedIn === "false") {
      dispatch(setModalOpen());
    } else {
      try {
        const response = await axios.post(
          WatchLaterURL,
          {
            auth_token: auth_token,
            access_token: access_token,
            user_id,
            list_type: "watchlater",
            listitem: {
              catalog_id: catalog_id,
              content_id: content_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.data
        ) {
          if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent("watch_later", {
              content_type: storedDetailsData?.media_type,
              genre: storedDetailsData?.genres,
              video_name: storedDetailsData?.title,
              video_language: storedDetailsData?.language,
              event_id: 7,
            });
          } else {
            console.error('Analytics SDK is not initialized yet.');
            // Handle initialization state or queue events for later
          }
         
          fetchGetMyListData();
          const mylistc = response?.data?.data;
          setMylistaddesponse(mylistc[0]);
          setMyList([...myList, index]);
        }
      } catch (err) {
        console.error("Server responded with an error:", err?.response?.data);
      }
    }
  };

  // console.log("listItemIdForDelete", listItemIdForDelete)

  const WatchLaterRemoveURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems/${listItemIdForDelete}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  const handleMyListDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(WatchLaterRemoveURL);
      if (response?.status === 200) {
        fetchGetMyListData();
        // console.log("remove mylist data", response?.data);
        setMylistremoveresponse(response?.data);
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };

  const handleMyListDeleteitems = async (event, index, listItemIdForDelete) => {
    event.preventDefault();
    console.log(`Deleting item with index: ${index}, listItemIdForDelete: ${listItemIdForDelete}`);
    
    const WatchLaterRemoveURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems/${listItemIdForDelete}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;
  
    try {
      const response = await axios.delete(WatchLaterRemoveURL);
      if (response?.status === 200) {
        console.log(`Successfully deleted item with index: ${index}, listItemIdForDelete: ${listItemIdForDelete}`);
        fetchGetMyListData();
        setMylistremoveresponse(response?.data);
        setMyList(myList.filter(i => i !== index));
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };
  
  

  useEffect(() => {
    const foundInMyList = watchLaterData.map(item => item.content_id);
    const myListIndexes = bannerContentIDList.map((contentId, index) => {
      if (foundInMyList.includes(contentId)) {
        return index;
      }
      return null;
    }).filter(index => index !== null);
  
    setMyList(myListIndexes);
  }, [watchLaterData]);

  const handleMouseEnter = () => {
    if (!hovered) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hovered) {
      setHovered(false);
    }
  };

  // console.log("livePageData", livePageData);

  // const bannerList =  homePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "top_banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // console.log("gsgsgs", moviePageData.catalog_list_items)
  const getBannerList = (data) => {
    // console.log("datasss", data)
    return data?.catalog_list_items
      ?.map((item, index) =>
        ((item?.web_layout_type ?? item?.layout_type) === "top_banner" || (item?.web_layout_type ?? item?.layout_type) === "banner") &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean) 
      .flat();
  };

  const getBannerListDisplayTitle = (data) => {
    return data?.catalog_list_items
    ?.map((item, index) =>
      ((item?.web_layout_type ?? item?.layout_type)) &&
      item
    ).filter(Boolean) 
      .flat();
    
  };

  const getMoviesBannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "banner" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getNewsbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "news_slider" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getFoodbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "recipe" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getHealthbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "episode" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getAgriculturebannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "videos" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  //  const LtvList = livePageData.layout_type ===  "live" &&
  //  livePageData.layout_structure === "vertical" && livePageData?.catalog_list_items ? livePageData?.catalog_list_items
  //     ?.map((item, index) => item) : null;

  const getLiveTVbannerList = (data) => {
    return data?.catalog_list_items
      ? data?.catalog_list_items.map((item, index) => item)
      : null;
  };

  const bannerList = getBannerList(homePageData);
  const bannerDisplayTitlesList = getBannerListDisplayTitle(homePageData);



  // const bannerDescriptionList = (
  //   bannerList || 
  //   []
  // )
  //   .filter((item) => item && item.description)
  //   .map((item) => item.description)
  //   .slice(initialBannerImageLimit, maxBannerImageLimit);

  const bannerDescriptionList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.description)
    .map((item) => {
      const truncatedDescription = item.description.substring(0, 200);
      return truncatedDescription.length < item.description.length ? `${truncatedDescription}...` : truncatedDescription;
    })
  const bannerURLList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.seo_url)
    .map((item) => item.seo_url)
    //.slice(initialBannerImageLimit, maxBannerImageLimit);

    const mobilebannerURLList = (
      bannerList ||
      []
    )
      .filter((item) => item && item.seo_url)
      .map((item) => item.seo_url)

  const bannerCatalogList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.catalog_id)
    .map((item) => item.catalog_id)
    //.slice(0, Max_Limit);

    const mobilebannerCatalogList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.catalog_id)
      .map((item) => item.catalog_id)

  const bannerContentIDList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.content_id)
    .map((item) => item.content_id)
    //.slice(initialBannerImageLimit, maxBannerImageLimit);


    const mobilebannerContentIDList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.content_id)
      .map((item) => item.content_id)


  const bannerPremiumBadgeList = (
    bannerList || 
    []
  )
    .map((item) => item?.access_control?.is_free)
   // .slice(0, Max_Limit);


    const bannerMobilePremiumBadgeList = (
      bannerList || 
      []
    )
      .map((item) => item?.access_control?.is_free)

  const bannerVideoBadgeList = (
    bannerList || 
    []
  )
    .map((item) => item?.media_type)
   // .slice(0, Max_Limit);

  // console.log("bannerVideoBadgeList", bannerVideoBadgeList)

  // const bannerImages = (
  //   bannerList || 
  //   moviesbannerList || 
  //   liveTVbannerList || 
  //   newsbannersList ||
  //   foodbannersList ||
  //   healthbannersList ||
  //   []
  // )
  //   .filter(
  //     (item) =>
  //       item &&
  //       (item.thumbnails.web_banner
  //         ? item.thumbnails.web_banner.url
  //         : item.thumbnails.high_4_3.url)
  //   )
  //   .map((item) => item.thumbnails.web_banner.url)
  //   .slice(0, Max_Limit);

   const bannerImages = (
    bannerList || 
    []
  )
  .map((item) =>
      item?.thumbnails?.web_tvbanner_image?.url ? item?.thumbnails?.web_tvbanner_image?.url : item?.thumbnails?.high_16_9?.url
  )
    // .filter(
    //   (item) =>
    //     item &&
    //     (item.thumbnails.web_tvbanner_image && item.thumbnails.high_16_9.url !== null
    //       ? item.thumbnails.web_tvbanner_image.url
    //       : item.thumbnails.medium_16_9.url)
    // )
    // .map((item) =>
    //   item.thumbnails.web_tvbanner_image && item.thumbnails.high_16_9.url !== null
    //     ? item.thumbnails.web_tvbanner_image.url
    //     : item.thumbnails.medium_16_9.url
    // )
   // .slice(initialBannerImageLimit, maxBannerImageLimit);

   const bannerMobileViewImages = (bannerList || []).map(item => {
    const mobileBannerUrl = item.thumbnails?.mobile_banner_image?.url;
    const alternativeUrl = item.thumbnails?.medium_3_4?.url;
  
    if (mobileBannerUrl && mobileBannerUrl.includes('default-images')) {
      if (alternativeUrl) {
        return alternativeUrl;
      }
      return mobileBannerUrl;
    }
  
    if (mobileBannerUrl) {
      return mobileBannerUrl;
    }
  
    return alternativeUrl || mobileBannerUrl; 
  });
  

  
  // console.log("bannerMobileViewImages", bannerMobileViewImages)


  const bannerVidList = (
    bannerList || 
    []
  )
    // .filter(
    //   (item) =>
    //     item && item.preview_adaptive_url !== "" && item.preview_adaptive_url
    // )
    .map((item) => item.preview_url_en)
   // .slice(0, Max_Limit);




   function decodeUrl(encodedUrl) {
    if (!encodedUrl || typeof encodedUrl !== 'string') {
      // Return null for null or non-string values
      return encodedUrl;
    }
  
    const parts = encodedUrl.split('.');
    
    if (parts.length !== 2) {
      throw new Error('Invalid encoded URL format');
    }
  
    const urlPart = atob(parts[0]);
    const keyPart = atob(parts[1]);
  
    const secretKey = '2fd66b173c16e012e90e';
    if (keyPart !== secretKey) {
      throw new Error('Invalid secret key');
    }
  
    return urlPart;
  }
  
  
  const bannerVideosList = bannerVidList.map(encodedUrl => {
    try {
      // Decode the encoded URL
      return decodeUrl(encodedUrl);
    } catch (error) {
      console.error("Error:", error.message);
      console.error("Encoded URL:", encodedUrl);
      // Return null for invalid URLs
      return null;
    }
  });
  
  // console.log("bannerVideosList:", bannerVideosList);
  

  const bannerImagesList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.thumbnails.medium_4_3.url)
    .map((item) => item.thumbnails.medium_4_3.url)
   
  const bannerTitleImagesList = (
    bannerList ||
    []
  )
    // .filter((item) => item && item.title_image_display && item.title_image)
    .map((item) => item.title_image_display === true ? item.title_image : item?.thumbnails?.medium_4_3?.url)
   // .slice(initialBannerImageLimit, maxBannerImageLimit);

// console.log("bannerList", bannerList)
const bannerMobileTitleImagesList = (
    bannerList ||
    []
  )
    // .filter((item) => item && item.title_image)
    .map((item) =>  item.title_image_display === true ? item.title_image : item?.thumbnails?.medium_4_3?.url)




  const trendingSequenceList = (
    bannerList || 
    []
  )
    // .filter((item) => item && item.trending_sequence)
    .map((item) => item.trending_sequence)
   // .slice(0, Max_Limit);

    const mobiletrendingSequenceList = (
      bannerList ||
      []
    )
      // .filter((item) => item && item.trending_sequence)
      .map((item) => item.trending_sequence)

  const genresList = (
    bannerList || 
    []
  )
    .map((item) => item.display_genres)
   // .slice(0, Max_Limit);

    const mobilegenresList = (
      bannerList || 
      []
    )
      // .filter((item) => item && item.display_genres)
      .map((item) => item.display_genres)


  const mediatypeList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.theme)
    .map((item) => item.theme)
    .slice(0, Max_Limit);

    const MobilemediatypeList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.theme)
      .map((item) => item.theme)

  // const livetvbannerList = livePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const foodbannerList = tvShowsData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const healthbannerList = healthPageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const agriculturebannerList = agriculturePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const newsbannerList = NewsPageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // console.log('genresList', genresList);

  const [selectedImage, setSelectedImage] = useState(0);
  // const [videoSrc, setVideoSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState(bannerVideosList[0]);
  // console.log("videoSrc", videoSrc)

  const listItemId = bannerContentIDList[currentIndex];

  const findInWatchLaterList = () => {
    let foundItem = watchLaterData.find(
      (item) => item.content_id === listItemId
    );
    
    let foundItemBoolean = !!foundItem;
  //  console.log("foundItem", foundItem)
    setFoundInMyList(foundItem)
    if (foundItemBoolean) {

      setAddedtomylist(true);
      setAddedinMobilelist(true)
      setListItemIdforDelete(foundItem.listitem_id);
    } else {
      setAddedtomylist(false);
      setListItemIdforDelete("");
      foundItem = "";
    }
  };

  useEffect(() => {
    findInWatchLaterList();

  }, [watchLaterData, listItemId]);

  console.log("addedinMobilelist", addedinMobilelist)
  
  const handleVideoEnd = () => {
    // console.log("video completed");
    setNoVideoUrl(false);
     const nextVideoIndex =  selectedImage + 1 < bannerVideosList.length ? selectedImage + 1 : 0;
    //  console.log("7");
     setSelectedImage(nextVideoIndex);
    // setShowVideo(false); // Set to false to show the image initially
    // setVideoSrc(bannerVideosList[nextVideoIndex]);
    // setShowVideo(true); // Set to true after setting the new video source
    // 
    // slide(1);
    // console.log("8");
    if(nextVideoIndex > 3){ slidelogo(nextVideoIndex); }

    handleLogoClick(nextVideoIndex);
  };
 
// const handleLogoClick = (index) => {
//   console.log(1);
//   setcurrentIndex(index);
//   setSelectedImage(index);
//   setShowVideo(false); // Reset to show image
//   setNoVideoUrl(false);
 
//   if (noVideoUrl === false) {
//     const nextIndex = index + 1 < bannerVideosList.length ? index + 1 : 0;

//     setTimeout(() => {
//       // Add a delay before clicking the next logo
//       console.log("Timeout completed");
//       if (bannerVideosList[index]) {
//         console.log("2");
//         videoplayindex(index);
//       } else {
//         console.log("3");
//         handleLogoClick(nextIndex);
//         if (index > 3) {
//           slidelogo(nextIndex);
//         }
//       }
//     }, 4000); // Adjust the delay as needed
//   }

// };

const handleLogoClick = (index) => {
  // console.log(1);
  setcurrentIndex(index);
  setSelectedImage(index);
  setShowVideo(false); // Reset to show image
  setNoVideoUrl(false);
  setIsMuted(true);
  // Clear existing timer if any
  clearTimeout(timerRef.current);

  // Start the timer immediately
  startTimer(index);
};



function getPageName() {
  const path = window.location.pathname;
  const parts = path.split('/'); 
  return parts[1] ?? "home"; 
}
const param1Value = getPageName();

const handleBannerClick = () => {
  const selectedBannerDetails = bannerList[selectedImage];
  const selectedBannerTitlesDetails = bannerDisplayTitlesList[selectedImage];
  localStorage.setItem("traynamedata", selectedBannerTitlesDetails?.display_title)

  if (window && window.sdk && window.sdk.trackEvent) {
    window.sdk.trackEvent("artwork_tapped", {
      source:param1Value,
      video_name:selectedBannerDetails.title,
      tray_name: selectedBannerTitlesDetails?.display_title,
      content_type: bannerDisplayTitlesList[0].web_layout_type || bannerDisplayTitlesList[0].layout_type || 'NA',
      position_within_tray: selectedImage + 1,
      artwork_type: bannerDisplayTitlesList[0].web_layout_type || bannerDisplayTitlesList[0].layout_type || 'NA',
 });

  } else {
    console.error('Analytics SDK is not initialized yet.');
    // Handle initialization state or queue events for later
  }
};


const startTimer = (index) => {
  const nextIndex = index + 1 < bannerVideosList.length ? index + 1 : 0;

  // Start the timer
  timerRef.current = setTimeout(() => {
    // console.log("Timeout completed");

    if (bannerVideosList[index]) {
      // console.log("2");

      
      videoplayindex(index);
    } else {
      // console.log("3");
      handleLogoClick(nextIndex);
      if (index > 3 && index !== bannerVideosList.length) {
        slidelogo(nextIndex);
      }
      const lendecrese = bannerVideosList.length;
      if(index === lendecrese){
       
          slidelogo(lendecrese+2);  
      }

    }
  }, 4000); // Adjust the delay as needed
};
async function checkURLValidity(index) {
  try {
      const response = await fetch(bannerVideosList[index], { method: 'HEAD' });
      if (response.ok) {
        console.error('Response OK. Status:', response);

          return true;
      } else {
          console.error('Response not OK. Status:', response.status);
          // Handle the case where the response is not OK (e.g., file not found)
          return false;
      }
  } catch (error) {
      console.error('Error checking URL validity:', error);
      // Handle other errors (e.g., network issues)
      return false;
  }
}
const videoplayindex = (index) =>{
  // console.log("4");
    setShowVideo(true); 
     
      setVideoSrc(bannerVideosList[index]);
      // console.log("playerRef",playerRef);

       setNoVideoUrl(true);  
      //  if (playerRef.current) {
      //   playerRef.current.playing = true;
      //   setShowVideo(true); 
      // }
}


// Use useRef to maintain the timer reference
const timerRef = useRef(null);
  useEffect(() => {
    // console.log("isSliderClicked changed: ", isSliderClicked);
  }, [isSliderClicked]);
  const handlePrevious = () => {
    setSelectedImage((prevImage) =>
      prevImage > 0 ? prevImage - 1 : bannerVideosList.length - 1
    );
  };
  useEffect(() => {
    let lastScrollTop = 0;
  
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setIsMuted(true); // Scroll down, mute the video
      } else if (currentScrollTop < lastScrollTop) {
       // setIsMuted(false); // Scroll up, unmute the video
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  const handleNext = () => {
    setSelectedImage((prevImage) =>
      prevImage < bannerVideosList.length - 1 ? prevImage + 1 : 0
    );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowVideo(true);
  //     setVideoSrc(bannerVideosList[0])
  //   }, 6000);

  // }, []);

  useEffect(() => {
    setIsSliderClicked(false);
    //handleLogoClick(currentIndex);

    const elementIdToClick = currentIndex;

    // Get the DOM element by id
    const elementToClick = document.getElementById(elementIdToClick);

    // Check if the element exists before attempting to click
    if (elementToClick) {
      // Simulate a click event
      elementToClick.click();
    }
  }, [selectedImage, myList, videoSrc]);




  useEffect(() => {
    // Clear the timeout on component unmount or when another slider is clicked
    return () => {
      if (clickDelay) {
        clearTimeout(clickDelay);
      }
    };
  }, [clickDelay]);


  const playerConfig = {
    file: {
      forceHLS: true, // Force the use of HLS streaming
    },
  };

  const trendingSequence = trendingSequenceList[selectedImage];
  let number = '';
  if (trendingSequence) {
    const match = trendingSequence.match(/\d+/); 
    number = match ? match[0] : '';
  }
  let lastTwoWords = '';
  if (trendingSequence) {
    const wordsArray = trendingSequence.split(" "); 
    if (wordsArray.length >= 2) {
      lastTwoWords = wordsArray.slice(-2).join(" ");
    } else {
      lastTwoWords = trendingSequence;
    }
  }
const genres = genresList[selectedImage];
const mediatype = mediatypeList[selectedImage];
let genresElement;
if (genres && genres.length > 0) {
  genresElement = (
    <React.Fragment>
      {genres.map((genre, index) => (
        <li key={index}>{genre}</li>
      ))}
    </React.Fragment>
  );
} else {
  genresElement = null;
}

  let isSliding = false;

  let trendnum = '';
const settings = {
  dots: true,
  infinite: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const settingsweb = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  const options = {
    items: 5,
    dots:false,
    startPosition: activeSlide

};
  const [currentLogoIndex, setLogoCurrentIndex] = useState(0);

  const slidelogo = (newIndex) => {
   // let newIndex = 1 + index;
    if (newIndex < 0) {
      newIndex = bannerTitleImagesList.length - 1;
    } else if (newIndex >= bannerTitleImagesList.length) {
      newIndex = 0;
    }
     // Check if it's not the last logo
     if (newIndex > 0) {
      setLogoCurrentIndex(newIndex + 1); // Add +1 only if it's not the last logo
    } else {
      setLogoCurrentIndex(newIndex); // Otherwise, set as is
    }
  };
  const slide = (direction) => {
    let newIndex = currentLogoIndex + direction;
    if (newIndex < 0) {
      newIndex = bannerTitleImagesList.length - 1;
    } else if (newIndex >= bannerTitleImagesList.length) {
      newIndex = 0;
    }
    // If it's already the last logo, don't move
    if (currentLogoIndex === bannerTitleImagesList.length - 1) {
      return;
    }

  // Set the transform style directly on the element
  const slider = document.querySelector('.logo-slider-inner');
  slider.style.transform = `translateX(-${newIndex * (100 / bannerTitleImagesList.length)}%)`;

  setLogoCurrentIndex(newIndex+2);
};

const slideleft = (direction) => {
    let newIndex = currentLogoIndex + direction - 2;
    if (newIndex < 0) {
      newIndex = bannerTitleImagesList.length - 1;
    } else if (newIndex >= bannerTitleImagesList.length) {
      newIndex = 0;
    }
     // If it's already the first logo, don't move
     if (currentLogoIndex === 0) {

      return;
    }
     
    // Set the transform style directly on the element
    const slider = document.querySelector('.logo-slider-inner');
    slider.style.transform = `translateX(-${newIndex * (100 / bannerTitleImagesList.length)}%)`;
    
    setLogoCurrentIndex(newIndex);
};

  const handleSlideChange = (event) => {
    setActiveSlide(event.item.index);
  };

  return (
    <>
      {/* {JSON.stringify(bannerList)} */}
      {/* {bannerList  && } */}

      {bannerList?.length > 0  &&
      <div className="image-slider">
        <div className="main-image">
          {/* <img src={images[selectedImage]} alt="Main" /> */}
          {/* <video autoplay muted loop class="background-video">
                            <source src={images[selectedImage]} type="video/mp4"  />
                        </video> */}

          {/* <div className="previous-button" onClick={handlePrevious}>
            <FaChevronLeft onClick={handlePrevious} />
          </div>
          <div className="next-button" onClick={handlePrevious}>
            <FaChevronRight onClick={handleNext} />
          </div> */}

          <div className="">
            <div id="overlay"></div>

            {isMobile ? (
              <>
              
              <Slider
         {...settings}
  className="slider-one"
  beforeChange={() => {
    isSliding = true;
  }}
  afterChange={() => {
    isSliding = false;
  }}
>
{bannerMobileViewImages.map((banimg, index) => {
  let trendingSequence = mobiletrendingSequenceList[index]; 
  let numbers = '';
  let lastTwoWords = '';

  if (trendingSequence) {
    const match = trendingSequence.match(/\d+/);
    numbers = match ? match[0] : '';

    const wordsArray = trendingSequence.split(" ");
    if (wordsArray.length >= 2) {
      lastTwoWords = wordsArray.slice(-2).join(" ");
    } else {
      lastTwoWords = trendingSequence;
    }
  }

  const listItemIdForDelete = watchLaterData.find(item => item.content_id === bannerContentIDList[index])?.listitem_id || '';

  const genresData = (
    <>
      {mobilegenresList[index] && mobilegenresList[index].length > 0 ? (
        <React.Fragment>
          {mobilegenresList[index].map((genre, index) => (
            <li key={index}>{genre}</li>
          ))}
        </React.Fragment>
      ) : null}
    </>
  );


  return (
    <div key={`banner-${index}`}>

      <div className="MobileSliders" style={{
          backgroundImage: `url(${banimg})`
        }} >

        <div className="BannerItems">
          <div className="d-flex item">
            {numbers ? <div className="top-show">
              <div className="top-show-icon">
                  Top  <span>{numbers}</span>
                  </div>
              </div> : ''}
                
              <div className="styling-heading">
              <h4>{lastTwoWords}</h4>
            </div>
          </div>

          <div className="MobileTitleLogo">
              <img src={bannerMobileTitleImagesList[index]} width="" alt="" />
          </div>

          <div className="mvoie mb-2">
              <ul>
                {!bannerMobilePremiumBadgeList[index] ? (
                  <li className="paidMovie">
                     <div className="lock-postion-class-two"></div>
                  </li>
                ) : (
                  ""
                )}
                {/* <li> {MobilemediatypeList[index]} </li> */}
                <>{genresData}</>
              </ul>
          </div>

          <div className="watch-mobile-icons">
            <Row className="align-items-center mt-2">
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="watch-icon w-100">
                  <Link to={mobilebannerURLList[index].startsWith('/') ? mobilebannerURLList[index] : `/${mobilebannerURLList[index]}`} >
                    <FaPlay></FaPlay>
                    &nbsp; Watch Now
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="my-list w-100 d-flex">
                  <div className="plus-buto">
                    <span className="me-1">
                      {!myList.includes(index) ? (
                        <>
                          {isUserLoggedIn === "true" ? (
                            <FaPlus
                              onClick={(event) => {
                                handleMyListAdd(
                                  event,
                                  bannerCatalogList[index],
                                  bannerContentIDList[index],
                                  index
                                );
                              }}
                            />
                          ) : (
                            <Link
                              to="/"
                              onClick={() => dispatch(setModalOpen())}
                            >
                              <FaPlus />
                            </Link>
                          )}
                        </>
                      ) : isUserLoggedIn === "true" ? (
                        <FaCheck onClick={(event) => handleMyListDeleteitems(event, index, listItemIdForDelete)} />
                      ) : (
                        <>
                          <Link to="/">
                            <FaPlus onClick={() => dispatch(setModalOpen())}/>
                          </Link>
                          My List
                        </>
                      )}
                    </span>
                    <Link onClick={(event) => {
                      handleMyListAdd(
                        event,
                        bannerCatalogList[index],
                        bannerContentIDList[index],
                        index
                      );
                    }}>
                    My List
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
})}


</Slider>

              </>
            ) : (
              <>
                <div
                  className="img-layer"
                  style={showVideo ? {} : { display: "none" }}
                >
                  {videoSrc !== "" && (
              //      <video
              //      ref={playerRef}
              //      onEnded={handleVideoEnd}
              //      className={`yout`}
              //      loop
              //      width="100%"
              //      height="100%"                   muted={isMuted}
              //      autoPlay
              //  >
              //      <source src={videoSrc} type="video/mp4" />
              //      Your browser does not support the video tag.
              //  </video>
              <ReactPlayer
                      ref={playerRef}
                      url={videoSrc}
                      className={`yout`}
                      width="100%"
                      height="100%"
                      pip={true}
                      controls={false}
                      playing={true}
                      muted={isMuted}
                      onEnded={handleVideoEnd}
                      // config={playerConfig}
                      onReady={onLoadedData}
                      playsinline={true}
                    />
                  )}
                </div>
                
                <div
                  className="img-layer"
                  style={showVideo ? { display: "none" } : {}}
                >

                  {/* {!showVideo && <img
  src={bannerImages[selectedImage]} 
  className="" 
  alt=""
/>} */}

                  {/* <div className="MainSliderBanner" style={{backgroundImage:`url(bannerImages[selectedImage])`}}></div> */}

                  <div
                    className="MainSliderBanner"
                    style={
                      {
                            backgroundImage: `url(${bannerImages[selectedImage]})`,
                          }
                    }
                  />

                  </div>
                </>
              )}
            </div>
          </div>
          <div className={`sound ${showVideo ? 'videoIcon' : 'imageIcon'}`}>
            <div className="soundMute" onClick={handleVolumeToggle}>
              {isMuted ? <FaVolumeXmark /> : <FaVolumeHigh />}
            </div>
          </div>

      {!isMobile && 
      <>

        <div className={`bannerDescriptionList ${showVideo ? 'videotitle' : 'imagetitle'}`}>
          {bannerTitleImagesList[selectedImage]?(<> <img src={bannerTitleImagesList[selectedImage]} width="180" alt="" />
</>):(<></>)}
         
          <div className="d-flex item">
            {number ? <div className="top-show">
              <div className="top-show-icon">
               Top <br /> {number}
              </div>
            </div> : ''}
            
            <div className="styling-heading">
              <h4>{lastTwoWords}</h4>
            </div>
          </div>
          <div className="mvoie mb-2">
            <ul>
              {/* <li><p>Movie</p></li>
        <li><p>Action</p></li>
        <li><p>Romantic</p></li>
        <li><p>U/A</p></li> */}

              
                {!bannerPremiumBadgeList[selectedImage] ? (
                  <li className="paidMovie">
                     <div className="lock-postion-class-two"></div>
                  </li>
                ) : (
                  ""
                )}
                {/* {(!bannerVideoBadgeList[selectedImage] === "video" || !bannerVideoBadgeList[selectedImage] === 'episode'|| !bannerVideoBadgeList[selectedImage] === 'latest_episode'|| !bannerVideoBadgeList[selectedImage] === 'best_scenes'|| !bannerVideoBadgeList[selectedImage] === 'promo' || !bannerVideoBadgeList[selectedImage] === 'mini_clips') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
              

              {/* <li> <div className="lock-postion-class-two"></div></li> */}
              {/* {mediatypeList[selectedImage] && (
 <li> <p>{mediatypeList[selectedImage].toUpperCase()}</p></li>
)} */}
              <>{genresElement && (
  <p>{genresElement}</p>
)}    </>
            </ul>
           

            {/* {(inne?.media_type === 'video' || inne?.media_type === 'episode'|| inne?.media_type === 'latest_episode'|| inne?.media_type === 'best_scenes'|| inne?.media_type === 'promo' || inne?.media_type === 'mini_clips') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
          </div>

          <div className="watchbitton">
            {/* deskicons start*/}
            <div className="watchbutton-content d-flex">
              <div className="play-buton">
                <Link to={bannerURLList[selectedImage].startsWith('/') ? bannerURLList[selectedImage] : `/${bannerURLList[selectedImage]}`} >
                  <FaPlay onClick={handleBannerClick}></FaPlay>
                </Link>
              </div>

              <h4>Watch Now</h4>
              <div className="myListBtn">
              <div className="plus-buto">
                {/* <FaCheck /> */}
                  {!addedtomylist ? (
                    <>
                      {isUserLoggedIn === "true" ? ( 
                        <p onClick={(event) => {
                            handleMyListAdd(
                              event,
                              bannerCatalogList[selectedImage],
                              bannerContentIDList[selectedImage]
                            );
                            setMyList([...myList, selectedImage]);
                          }}>
                        <FaPlus />
                      </p> ) : (
                         <p onClick={() => dispatch(setModalOpen())}>
                          <FaPlus />
                        </p> )}
                      {/* <FaPlus onClick={(event) => handleMyListAdd(event, bannerCatalogList[selectedImage], bannerContentIDList[selectedImage])} /> */}
                    </>
                  ) : isUserLoggedIn === "true" ? ( <p onClick={handleMyListDelete}>
                    <FaCheck />
                    </p> ) : ( <p onClick={() => dispatch(setModalOpen())}>
                      <FaPlus />
                    </p>)}
              </div>
              <h4>My List</h4>
              </div>
            </div>
          </div>
          <div className="watch-mobile-icons">
            <Row className="align-items-center mt-2">
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="watch-icon w-100">
                  <Link to={bannerURLList[selectedImage].startsWith('/') ? bannerURLList[selectedImage] : `/${bannerURLList[selectedImage]}`}>
                    <FaPlay></FaPlay>
                    &nbsp; Watch Now
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="my-list w-100 d-flex">
                  <div className="plus-buto ">
                    {/* <FaCheck /> */}
                    <span className="me-1">
                      {!addedtomylist ? (
                        <>
                          {isUserLoggedIn === "true" ? (
                            <FaPlus
                              onClick={(event) => {
                                handleMyListAdd(
                                  event,
                                  bannerCatalogList[selectedImage],
                                  bannerContentIDList[selectedImage]
                                );
                              }}
                            />
                          ) : (
                            <Link
                              to="/"
                              onClick={() => dispatch(setModalOpen())}
                            >
                              <FaPlus />
                            </Link>
                          )}

                          {/* <FaPlus onClick={(event) => handleMyListAdd(event, bannerCatalogList[selectedImage], bannerContentIDList[selectedImage])} /> */}
                        </>
                      ) : isUserLoggedIn === "true" ? (
                        <FaCheck onClick={handleMyListDelete} />
                      ) : (
                        <>
                          <Link to="/">
                            <FaPlus  onClick={() => dispatch(setModalOpen())}/>
                          </Link>
                          My List
                        </>
                      )}
                    </span>
                    My List
                  </div>

                        {/* <Link to="/">

                  <FaCheck/> My List
                    
                  </Link> */}
                </div>
              </Col>
            </Row>
          </div>
          {/* mobileplayicons end*/}
          <div className="p-styleting-class">
            <p>{bannerDescriptionList[selectedImage]}</p>

                  {/* {(bannerVideoBadgeList[selectedImage] === "episode" || bannerVideoBadgeList[selectedImage] === 'movie') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
                </div>
              </div>
              <div className="logo-slider">
              {/* <div className="bottom-logo-slider">
       
          <div className="logo-slider-inner"  style={{ transform: `translateX(-${currentLogoIndex * (105 / bannerTitleImagesList.length)}%)` }} >
          {bannerTitleImagesList.map((logo, index) => (
            <div key={index} className={`logo-item ${selectedImage === index ? 'active-line' : ''}`}>
            <img
            key={index}
            className={`logo ${selectedImage === index ? "active" : ""}`}
            src={logo} id={index}
            alt=""
            onClick={() => handleLogoClick(index)}
            />

            </div>
          ))}
          </div>

          <div className="arrow arrow-left" onClick={() => slideleft(-1)}><FaChevronLeft /></div>
          <div className="arrow arrow-right" onClick={() => slide(1)}><FaChevronRight /></div>

    </div> */}
<div className="bottom-logo-slider">
    <div className="logo-slider-inner" style={{ transform: `translateX(-${currentLogoIndex * ((100 / bannerTitleImagesList.length)+10)}%)` }}>
        {bannerTitleImagesList.map((logo, index) => (
            <div key={index} className={`logo-item ${selectedImage === index ? 'active-line' : ''}`}>
                <img
                    key={index}
                    className={`logo ${selectedImage === index ? "active" : ""}`}
                    src={logo}
                    id={index}
                    alt=""
                    onClick={() => handleLogoClick(index)}

                />
            </div>
        ))}
         {bannerTitleImagesList.map((logo, index) => (
            <div key={index} className={`logo-item ${selectedImage === index ? 'active-line' : ''}`}>
                <img
                    key={index} 
                    className={`logo ${selectedImage === index ? "active" : ""}`}
                    src={logo}
                    id={index}
                    alt=""
                    onClick={() => handleLogoClick(index)}
                />
            </div>
        ))}
    </div>
   
</div>
<div className="arrow arrow-left" onClick={() => slideleft(-1)}><SamplePrevArrow /></div>
    <div className="arrow arrow-right" onClick={() => slide(1)}><SampleNextArrow  /></div>
                </div>
             

              
              <div className="logo-slider">
             
              {bannerImagesList.map((logo, index) => (
                 <div
                 
               > 
             <span
                    key={index}
                    className={`dots ${selectedImage === index ? "active" : ""}`}
                    onClick={() => handleLogoClick(index)}
                  ></span>
                    
                      </div>
                ))}
         
                
              </div>
            </>
          }
        </div>
      }
    </>
  );
}

export default CommonBannerSlider;
