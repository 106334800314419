import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useState } from "react";
import { VERIFY_EMAIL, access_token, auth_token } from "../../services/config";

import { Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Successpage({
  confirmationSuccess,
  showSuccess,
  setShowSuccess,
  setShowEmailValidpopup,
}) {

  const EmailValidicon = "/images/emailvalid.png";
  const logo = "/images/win-logo.png";

  // const { confirmationToken } = useParams();

  const handleSuccessemailClose = () => {
    setShowSuccess(false);
    setShowEmailValidpopup(false);
  };
  
  // if(confirmationSuccess){
  //   setShowEmailValidpopup(false);

  // }


  return (
    <>
      {showSuccess ? (
        <>
          <Modal
            show={showSuccess}
            onHide={handleSuccessemailClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            className="border-class"
          >
            <Modal.Body>
              <div className="card-deck row just-content">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                  <div className="">
                    <img src={EmailValidicon} alt="email validation icon" />
                    <h6 className="text-center fw-bold fs-3 my-3">
                      Validation Success
                    </h6>
                    <p>Username: &nbsp; <span className="emailvalid-text fw-bold text-white">{confirmationSuccess?.data?.email_id}</span>  </p>
                    
                    <div className="text-center">
                      <p className="emailvalid-text">
                        {confirmationSuccess ? (
                          <>
                            This email &nbsp;
                            <span className="fw-bold text-white">
                              {confirmationSuccess?.data?.email_id} 
                            </span> &nbsp;

                            is verified
                              successfully
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>To login, please access ETV WIN and type the username above.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
