import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLogoutAllModal: false,
};

export const logoutAllmodalSlice = createSlice({
  name: "logoutallmodal",
  initialState,
  reducers: {
    setShowLogoutAllModal: (state, action) => {
        state.showLogoutAllModal = action.payload.showLogoutAllModal  
      },
  },
});

export const {setShowLogoutAllModal} = logoutAllmodalSlice.actions;

export default logoutAllmodalSlice.reducer;