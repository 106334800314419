import React,{useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFacebook,  FaInstagram, FaInstagramSquare, FaTwitter, FaTwitterSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { FaXTwitter, FaFacebookF  } from "react-icons/fa6"


const Footer = () => {

  const logo = "/images/win-logo.png";
  const facebook = "/images/facebook.png";
  const insta = "/images/insta.png";
  const twiter = "/images/twiter.png";
  const googleicon = "/images/google-play-store-btn.png";
  const applicon = "/images/apple-store-btn.png";
  const TvsLogos = "/images/tvs_logos.png";


  const currentYear = new Date().getFullYear()
  return (

    
    <div>
       {/* <hr/> */}

       <div className="footer">
        <Container fluid>
            <ul>
                <li ><Link to="/aboutus">About Us</Link></li>
                <li ><Link to="/contactus">Contact Us</Link></li>
                <li ><Link to="/redeem">Redeem</Link></li>
                <li ><Link to="/privacypolicy">Privacy Policy</Link></li>
                <li ><Link to="/termsandconditions">Terms and Conditions </Link></li>
                <li ><Link to="/faq">FAQ</Link></li>
                <li ><Link to="/feedback">Feedback</Link></li>
                <li ><Link to="/subscription">Subscriptions</Link></li>
              </ul>
        </Container>
      </div>

      <div className='footer-bottom'>
   <Container fluid className=''>
      <Row>
        
        <Col lg={8} md={12} className='order-lg-1'>
        <Row className='justify-content-lg-center'>
        <Col lg={4} md={4} sm={6} className='order-lg-0'>
            <div className='aligment-class-two'>
              <div className="social-media-icons downloadApp">
                <p>Get in Touch with us</p>
                <ul>  
                    <li className='Facebook'>
                      <a href="https://www.facebook.com/etvwin" target='_blank'><FaFacebookF /></a>
                    </li>
                    <li className='Twitter'>
                      <a href="https://twitter.com/etvwin" target='_blank'><FaXTwitter /></a>
                    </li>
                    <li className='Instagram'>
                      <a href="https://www.instagram.com/etvwin/" target='_blank'><FaInstagram /></a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={6}>
            <div className='downloadApp'>
              <div className='text-left'>
                <div className='play-icon'>
                  <p>Download App</p>
                  <ul>
                    <li className='icon-style'> <a href='https://play.google.com/store/apps/details?id=com.etvwin.mobile' target='_blank'><img src={googleicon}></img></a> </li>
                    <li className='icon-style'> <a href='https://itunes.apple.com/app/id1245077673' target='_blank'> <img src={applicon}></img></a> </li>
                  </ul>
                
                </div>
              </div>
            </div>
          </Col>
          

          <Col lg={4} md={4} sm={6}>
          <div className='downloadApp'>
              <div className='text-left'>
                <div className='play-icon'>
                  <p>Also Available on</p>
                  <ul>
                    <li className='Webapp'> <a href='#' target='_blank'><img src={TvsLogos}></img></a> </li>
                  
                  </ul>
                
                </div>
              </div>
            </div>
          </Col>
        </Row>
        </Col>

        <Col lg={4} md={12}>
        <div className='copy-right'>
          <img src={logo}  alt="" />
            <p>© Eenadu Television Pvt. Ltd. {currentYear}. All Rights Reserved</p>
          </div>
        </Col>
      </Row>


     

      {/* <hr/> */}
      
     
      
  
    
   </Container>

   </div>



    </div>
  )
}

export default Footer