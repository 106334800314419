import { useEffect } from "react";

const useGoogleIMA = (callback) => {
  useEffect(() => {
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="${url}"]`);
        if (existingScript) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Script load error for ${url}`));
        document.head.appendChild(script);
      });
    };

    const initializeSDK = async () => {
      try {
        await loadScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js");
        if (callback && typeof callback === "function") {
          callback();
        }
      } catch (error) {
        console.error("Failed to load Google IMA SDK:", error);
      }
    };

    initializeSDK();
  }, [callback]);
};

export default useGoogleIMA;
