import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { getAnalytics,logEvent  } from 'firebase/analytics';

export default function WatchListFeature() {
  const {videodetailsitems} = useSelector(
    (state) => state.video_details
  );

  // console.log("hhshs", videodetailsitems)
  const dispatch = useDispatch();
  const regionsData = useSelector((state) => state.region.regionsData);
  const isUserLoggedIn = localStorage.getItem("login_status");
  const user_id = localStorage.getItem("user_id");
  const [mylistremoveresponse, setMylistremoveresponse] = useState({});
  const { sessionId } = useSelector((state) => state.auth);
  const session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId; 
     const WatchLaterURL = `${BASE_URL}users/${session_id}/playlists/watchlater`;
  const [addedtomylist, setAddedtomylist] = useState(false);
  const [mylistaddresponse, setMylistaddesponse] = useState({});
  const [listItemId, setListItemId] = useState("");
  const [mylistemItemID, setMylistemItemID] = useState("");
  const firebaseAnalytics = getAnalytics();

  const fetchWatchLaterStatusUrl = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}&content_id=${videodetailsitems?.last_episode?.content_id || videodetailsitems?.content_id}&catalog_id=${videodetailsitems?.catalog_id}`;
  const fetchWatchLaterStatus = async () => {
    try {
      const response = await axios.get(fetchWatchLaterStatusUrl);
      if (response.status === 200) {
        setAddedtomylist(true);
        // console.log("fetchWatchLaterStatus", response?.data?.data)
        setListItemId(response?.data?.data?.items[0]?.listitem_id);
      }
    } catch (err) {
      console.error("Server responded with an error:", err?.response?.data);
    }
  };

  const handleMyListAdd = async (event) => {
    event.preventDefault();
    if (user_id) {
      try {
        const response = await axios.post(
          WatchLaterURL,
          {
            auth_token: auth_token,
            access_token: access_token,
            // user_id,
            list_type: "watchlater",
            listitem: {
              content_id: videodetailsitems?.last_episode?.content_id || videodetailsitems?.content_id,
              catalog_id: videodetailsitems?.catalog_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          // console.log("addtowatchsdsdsd", videodetailsitems.genres.join(', '))
          setMylistemItemID(response?.data?.data[0]?.listitem_id);
          if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent("watch_later", {
              content_type: videodetailsitems.media_type,
              genre: videodetailsitems.genres.join(', '),
              video_name: videodetailsitems.title,
              video_language: videodetailsitems.language,
              event_id: 7,
            });

          }else{
            console.warn("Tracking event not available");
          }

          // firebaseAnalytics.logEvent('watch_later', {
          //   content_type: videodetailsitems?.media_type?videodetailsitems?.media_type : 'NA',
          //   video_name: videodetailsitems?.title,
          //   genre: videodetailsitems?.genres ? videodetailsitems?.genres : 'NA',
          //   video_language: videodetailsitems?.language
          // });

          logEvent(firebaseAnalytics, 'watch_later', {
            content_type: videodetailsitems?.media_type?videodetailsitems?.media_type : 'NA',
            video_name: videodetailsitems?.title,
            genre: videodetailsitems?.genres ? videodetailsitems?.genres : 'NA',
            video_language: videodetailsitems?.language
          });

          setAddedtomylist(true);
          // toast.success("Added to my list");
          const mylistc = response?.data?.data;
          setMylistaddesponse(mylistc[0]);
          // fetchWatchLaterStatus();
        }
      } catch (err) {
        // if(err?.response?.data?.error?.message === "Invalid Session Id"){
        //   dispatch(setModalOpen());
        // }
        if(err?.response?.status === 422){
          dispatch(setModalOpen());
        }
        console.error("Server responded with an error:", err?.response?.data);
      }
    } else {
      dispatch(setModalOpen());
    }
  };

  // console.log("mylistemItemID", mylistemItemID)
  // console.log("listItemId", mylistaddresponse)

  const WatchLaterRemoveURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems/${mylistemItemID || listItemId}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  const handleMyListDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(WatchLaterRemoveURL);
      if (response?.status === 200) {
        // toast.success("Removed from my list");
        setAddedtomylist(false);
        // console.log("remove mylist data", response?.data);
        setMylistremoveresponse(response?.data);
        // fetchWatchLaterStatus();
      }
    } catch (err) {
      console.error("Server responded with an error:", err?.response?.data);
    }
  };

  useEffect(() => {
    if (session_id) {
      fetchWatchLaterStatus();
    }
  }, [session_id]);

  console.log("sjdjsd", isUserLoggedIn)

  return (
    <>
      <div class="combination secondcombo">
        <>
          {!addedtomylist ? (
            <div class="other-icons" onClick={handleMyListAdd}>
              <>
                {isUserLoggedIn ? (
                  <FaPlus />
                ) : (
                  <Link onClick={() => dispatch(setModalOpen())}>
                    <FaPlus />
                  </Link>
                )}
              </>
            </div>
          ) : (
            <div class="other-icons" onClick={handleMyListDelete}>
              <FaCheck />
            </div>
          )}
        </>
        <div class="text">My List</div>
      </div>
    </>
  );
}
