// useFetchData.js
import axios from 'axios';

export const useFetchData = (apiUrl, setInnerCatalogListData, setData, setCatalogListData, setLoading, toast) => {
  const fetchData = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(apiUrl);
      const responseData = response?.data?.data;

      if (response?.status === 200) {
        const flattenedData = responseData?.catalog_list_items.map(
          (item) => item.catalog_list_items || []
        );

        setInnerCatalogListData(flattenedData);
        // setLoading(false);
        return {catlogData: responseData?.catalog_list_items || [], homePageData: responseData};
      } else {
        // setLoading(false);
        toast.error('Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data");
      // setLoading(false);
    }
  };

  return fetchData;
};
