import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterEpisodeData: {},
  filterEpisodeItemsData: [],
}

export const filterEpisodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    setFilterEpisodeData: (state, action) => {
      state.filterEpisodeData = action.payload.filterEpisodeData
    },
    setFilterEpisodeItemsData: (state, action) => {
      state.filterEpisodeItemsData = action.payload.filterEpisodeItemsData
    },
  },
})

export const { setFilterEpisodeData, setFilterEpisodeItemsData } = filterEpisodeSlice.actions

export default filterEpisodeSlice.reducer