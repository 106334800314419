import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'
import { ABOUT_US } from '../services/config'

import axios from 'axios'

const Tvaboutus = () => {
    const [aboutdata, setAboutdata] = useState([]);



    useEffect(()=>{
      const Aboutcontent = async () =>{


        try {
            const response = await axios.get(ABOUT_US);
        
            const menuitemslistd = response?.data.static_page;
        
            if (response?.status === 200) {
            //   console.log("Tearmsconditions:", menuitemslistd);
        
              setAboutdata(menuitemslistd);
            }
          } catch (error) {
            console.error("Error fetching data:", error?.message);
           
          }
     }

     return () => Aboutcontent()


    },[])


  return (
    <>


        <div className=' '>
            <Container>
          
            {/* <h4>{aboutdata.display_title}</h4> */}
            <div className='about-main policy-content'>
            <div dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
           
            </div>

            </Container>
        </div>

    </>
  )
}

export default Tvaboutus