import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoginModalOpen: false,
};

export const loginmodalSlice = createSlice({
  name: "loginmodal",
  initialState,
  reducers: {
    setModalOpen: (state) => {
      state.isLoginModalOpen = true;
    }, 
    setModalClose: (state) => {
      state.isLoginModalOpen = false;
    },
  },
});

export const { setModalOpen,setModalClose } = loginmodalSlice.actions;

export default loginmodalSlice.reducer;
