import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  getallDetails: {},
}

export const getallDetailsSlice = createSlice({
  name: 'getall details',
  initialState,
  reducers: {
    setGetallDetails: (state, action) => {
      state.getallDetails = action.payload.getallDetails
    },
  },
})

export const { setGetallDetails } = getallDetailsSlice.actions

export default getallDetailsSlice.reducer 




