import React, { useRef } from "react";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { Container, Col, Row } from "react-bootstrap";
// import "./ActiveTv.css";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import {
  GENERATE_TOKEN_URL,
  GENERATE_SESSION_URL,
  access_token,
  auth_token,
  BASE_URL,
  userregionapi,
} from "../../services/config";
import { useSelector } from "react-redux";

const ActiveTv = () => {
  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id") : sessionId;
  const [activatetvotp, setActivatetvotp] = useState(['', '', '', '', '', '']);
  const [currentIndex, setCurrentIndex] = useState(null);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [timer, setTimer] = useState(35);
  const regionsData = useSelector(state => state.region.regionsData)


  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);


  const handleChange = (index, value) => {
    const newOtp = [...activatetvotp];
    newOtp[index] = value;
    setActivatetvotp(newOtp);

    if (value !== '' && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && activatetvotp[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };



  const handleGenerateTVToken = async (event) => {
    event.preventDefault();

     const enteredTVOTP = activatetvotp.join("");

     if (!enteredTVOTP) {
      setError("Please enter code");
      return;
    }

    // if (!enteredTVOTP) {
    //   setError("Please enter OTP");
    //   return;
    // }
  
  var token = enteredTVOTP;
 var user = {"token":token,"session_id":sessionID}
 
    try {
      const response = await axios.post(
        BASE_URL+ "generate_session_tv",
        {
          "auth_token":auth_token, "access_token":access_token,"user":user,"region":regionsData?.country_code2,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

     // setTokenmessage(response.data.message)
      //setToken(response.data.token)

      // console.log("tv otp data:", response);
      if (response?.status === 200) {
        // setSuccess(response.data.data.message)
        setSuccess("connecting...")
        setActivatetvotp(['', '', '', '', '', '']);
          // toast.success(response.data.data.message);
          window.location.href="/";
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);

      if (err?.response?.status === 422) {

        console.log("errs", err.response.data)
        setError(err.response.data.error.message)
      }
    }
  };


  const handleGenerateSessionTV = async (event) => {
    event.preventDefault();

    // const enteredTVOTP = activatetvotp.join("");

    // if (!enteredTVOTP) {
    //   setError("Please enter OTP");
    //   return;
    // }

    try {
      const response = await axios.post(
        GENERATE_TOKEN_URL,
        {
          auth_token: auth_token,
          access_token: access_token,

        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // setTokenmessage(response.data.message)
      // setToken(response.data.token)

      // console.log("tv otp data:", response);
      if (response?.status === 200) {
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);

      if (err?.response?.status === 422) {
        if (err?.response?.data?.error?.message === "Enter valid OTP") {
          setError(err.response.data.error.message);
        }
      }
    }
  };

  return (
    <div className="main-page">
      <div className="main background-layer">
        <div class="row widht-658">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10">
            <div className="login-left-styles active-center-two">
              <h6>Activate ETV WIN on your TV</h6>
            
         <p className="text-center text-danger">{error ?  `${error}`  : ''}   </p> 
         <p className="text-center text-success">{success ?  `${success}`  : ''}   </p> 
              <p>Enjoy ETV Win with More Benefits and Features </p>
            </div>
            <div className="form-style">
              <form onSubmit={handleGenerateTVToken}>
                <ul className="otp-input-two">
                  {/* {[0, 1, 2, 3, 4, 5].map((index) => (
                    <li key={index}>
                      <input
                        id={`otp${index + 1}`}
                        value={activatetvotp[index]}
                        onChange={(event) => handleChange(event, index)}
                        autoComplete="off"
                        maxLength={1}
                      />
                    </li>
                  ))} */}

{activatetvotp.map((digit, index) => (
   <li key={index}>

     <input
       key={index}
       autoComplete='off'
       ref={inputRefs[index]}
       type="text"
       value={digit}
       onChange={(e) => handleChange(index, e.target.value)}
       onKeyDown={(e) => handleKeyDown(index, e)}
       maxLength="1"
       onFocus={() => setCurrentIndex(index)} 
       className={currentIndex === index ? 'red-border' : ''}
     />
   </li>
      ))}
                </ul>

                <button type="submit" className="next-button-color">
                  Activate
                </button>
              </form>

              {/* <button onClick={handleGenerateSessionTV}>Generate Session for tv</button> */}
            </div>
          </div>
        </div>
        <div className="padding-betw">
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ActiveTv;
