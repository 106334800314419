import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  registerDetails: {},
  otpVerifyData: {},
  emailSignupData: {}
}

export const RegisterSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setRegisterDetails: (state, action) => {
      state.registerDetails = action.payload.registerDetails
    }, 
    setOTPVerifyData: (state, action) => {
      state.otpVerifyData = action.payload.otpVerifyData
    },
    setEmailSignupData: (state, action) => {
      state.emailSignupData = action.payload.emailSignupData
    },
  },
})

export const { setRegisterDetails, setOTPVerifyData, setEmailSignupData} = RegisterSlice.actions

export default RegisterSlice.reducer