import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showRegisterModal: false,
};

export const registermodalSlice = createSlice({
  name: "registermodal",
  initialState,
  reducers: {
    setShowRegisterModal: (state, action) => {
        state.showRegisterModal = action.payload.showRegisterModal  
      },
  },
});

export const {setShowRegisterModal} = registermodalSlice.actions;

export default registermodalSlice.reducer;