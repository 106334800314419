import { GoChevronLeft, GoChevronRight } from 'react-icons/go';



export  const CustomPrevArrow     = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <div className="">
        {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
        </div>
      </div>
    );
  };


  // const CustomNextArrow = ({ onClick }) => (
  //   <div className="custom-next-arrow" onClick={onClick}>
  //     <FontAwesomeIcon icon={faChevronRight} />
  //   </div>
  // );

  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block" }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block"}}
  //       onClick={onClick}
  //     />
  //   );
  // }


  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
            {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
        </div>
    );
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
      <div 
          className={className}
          // className={`arrow ${className}`}
          onClick={onClick}
          style={{ cursor: "pointer" }} 
      >
        <GoChevronRight  />

          {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
      </div>
  );
}
  


  export var twogrid = {
    dots: false,
    // asNavFor: '.twogrid',
    infinite: false,
    speed: 500,
     lazyLoad: "anticipated",
    autoplay: false,
    delay: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };

 
  export var singelgrid = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyLoad: "anticipated",
    autoplay: false,
    delay: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // swipe: false,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };




  

  export var horizontalmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyLoad: "anticipated",
    // swipe: false,
    autoplay: false,
    centerMode: false,
    delay: 3000,
    slidesToShow: 6.5,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow/>,
    //   nextArrow: <SampleNextArrow wrapperClassName="arrow-wrapper" />,
    // prevArrow: <SamplePrevArrow wrapperClassName="arrow-wrapper" />,
    // prevArrow: null,
    
  

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: true,
          
        },
      },
    ],
  };


  export var verticalmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyLoad: "anticipated",
    autoplay: false,
    delay: 3000,
    slidesToShow: 9,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow/>,
    // prevArrow: null,
    swipeToSlide: true,
    // swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // {
  //   breakpoint: 1024,
  //   settings: {
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     infinite: true,
  //     dots: true,
  //   },
  // },



  export var bigmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyLoad: "anticipated",
    autoplay: false,
    delay: 3000,
    slidesToShow: 6.5,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow/>,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };

