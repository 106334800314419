import React from 'react'
import { Helmet } from 'react-helmet';

const Sco = () => {
    const pageTitle = "ETV WIN ";
    const pageDescription = "Watch your favourite etv telugu shows jabardasth, dhee, alitho saradaga, cash, sridevi drama company &amp; get a super hit collection of all telugu serials on etv win. Now watch the gold collection of all telugu movies on etv win.";
  return (
   <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} data-rh="true"   />
      </Helmet>
      
  )
}

export default Sco