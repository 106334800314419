import React, { useRef } from 'react'
import Header from '../../Componets/Header/Header'
import { Container } from 'react-bootstrap'
// import "./About.css"
import Footer from '../../Componets/Footer/Footer'
import { useEffect } from 'react'
import { ABOUT_US } from '../../services/config'
import { useState } from 'react'
import axios from 'axios'

const AboutUs = () => {
  const effectRan = useRef(false)

    const [aboutdata, setAboutdata] = useState([]);

    useEffect(() => {
      if(effectRan.current === false){
        Aboutcontent();
        return () => {
          effectRan.current = true
        }
      }
    }, []);

    const Aboutcontent = async () =>{


      try {
          const response = await axios.get(ABOUT_US);
      
          const menuitemslistd = response?.data.static_page;
      
          if (response?.status === 200) {
          //   console.log("Tearmsconditions:", menuitemslistd);
      
            setAboutdata(menuitemslistd);
          }
        } catch (error) {
          console.error("Error fetching data:", error?.message);
         
        }
   }

      

 useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
        {/* <Header/> */}

        <div className='main '>
            <Container>
          
            {/* <h4>{aboutdata.display_title}</h4> */}
            <div className='about-main policy-content'>
            <div dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
            {/* <ul>
                <li>
                    <p>ETV is one of the largest satellite television networks in India with a bouquet of 7 SD and 5 HD channel. Incepted in 1995 with ETV-Telugu, the Network has been maintaining a leading position in delivering quality infotainment with an objective to take entertainment to the doorsteps of the Telugu speaking people across the World.</p>
                </li>
                <li>
                    <p>“ETV Win”, owned and operated by Eenadu Television Private Limited, is an online video platform offering variety of Telugu content from ETV Network channels as well as original productions, with many attractive features</p>
                </li>
                <li>
                    <p>“ETV Win” is offered through mobile apps on Android and iOS as well as web version compatible with web browsers.</p>
                </li>
                <li>
                    <p>Visitors / Users of “ETV Win” platform can make use of many paid and free features that the platform offers, such as:</p>
                </li>
                <li>
                   <ul className='ul-styling'>
                    <li><p>Genre-wise preference setting by the users to watch their favourite shows or serials.</p></li>
                    <li><p>Favourite TV Shows and serials from ETV Network channels.</p></li>
                    <li><p>Original fresh web series and shows.</p></li>
                    <li><p>Video quality based on internet bandwidth.</p></li>
                    <li><p>Watch offline.</p></li>
                   </ul>
                </li>
            </ul> */}
            </div>

            </Container>
            <Footer/>
        </div>

    </>
  )
}

export default AboutUs