import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn : false,
  sessionId : ""
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn
      state.sessionId = action.payload.sessionId
    },
  },
})

export const { setAuth } = authSlice.actions

export default authSlice.reducer