import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navItemCalled: false,
    navitemname: {},
    traynamedata: {},
    thumbnailposition: 0
  };
  
  export const navMenuCalledSlice = createSlice({
    name: "navlink menu",
    initialState,
    reducers: {
      setNavItemCalled: (state, action) => {
          state.navItemCalled = action.payload.navItemCalled  
        },

        setNavItemname: (state, action) => {
          state.navitemname = action.payload.navitemname  
        },

        setTraynamedata: (state, action) => {
          state.traynamedata = action.payload.traynamedata  
        },

        setThumbnailposition: (state, action) => {
          state.thumbnailposition = action.payload.thumbnailposition  
        },
    },
  });
  
  export const {setNavItemCalled, setNavItemname, setTraynamedata, setThumbnailposition} = navMenuCalledSlice.actions;
  
  export default navMenuCalledSlice.reducer;