import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menuListData: [],
}

export const menuListSlice = createSlice({
  name: 'menulist',
  initialState,
  reducers: {
    setMenuListData: (state, action) => {
      state.menuListData = action.payload.menuListData
    },
  },
})

export const { setMenuListData } = menuListSlice.actions

export default menuListSlice.reducer