import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLogoutModal: false,
};

export const logoutmodalSlice = createSlice({
  name: "logoutmodal",
  initialState,
  reducers: {
    setShowLogoutModal: (state, action) => {
        state.showLogoutModal = action.payload.showLogoutModal  
      },
  },
});

export const {setShowLogoutModal} = logoutmodalSlice.actions;

export default logoutmodalSlice.reducer;