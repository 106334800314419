import axios from 'axios';

class AnalyticsSdk {
  constructor(trackingId, config) {
    this.trackingId = trackingId;
    this.config = { apiUrl: '', batchSize: 10, timeout: 5000, ...config };
    this.axiosInstance = axios.create({ baseURL: this.config.apiUrl, timeout: this.config.timeout });
    this.eventQueue = [];
  }

  sendRequest(requestConfig) {
    return this.axiosInstance.request(requestConfig);
  }

  sendBatchRequest(dataLayer) {
    return this.sendRequest({ method: 'post', url: '/api/collect', data: dataLayer });
  }

  processEventQueue() {
    if (this.eventQueue.length >= this.config.batchSize) {
      const events = this.eventQueue.splice(0, this.config.batchSize);
      this.sendBatchRequest({ dataLayer: events })
        .then(() => console.log('Batch request sent successfully'))
        .catch((error) => console.error('Failed to send batch request:', error));
    }
  }

  trackPageView(pageUrl, options = {}) {
    const event = { trackingId: this.trackingId, event_name: 'track_page', pageUrl, ...options };
    this.sendBecon('/api/track', event);
  }

  trackEvent(eventName, options = {}) {
    const event = { trackingId: this.trackingId, event_name: eventName, event_time: new Date().toISOString().slice(0, 19), ...options };
    this.eventQueue.push({ value: JSON.stringify(event) });
    this.processEventQueue();
  }

  sendBecon(url, data) {
    fetch(`${this.config.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
      keepalive: true
    });
  }
}

export default AnalyticsSdk;
