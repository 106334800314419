import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { setAuth } from "../../Redux/Slice/authSlice";
import { useDispatch } from "react-redux";
import { setShowLogoutAllModal } from "../../Redux/Slice/logoutAllModalSlice";
import CommonLogout from "./CommonLogout";

export default function LogoutAll() {
  const [errorMes, setErrorMes] = useState('')
  const dispatch = useDispatch();
  const { otpVerifyData } = useSelector((state) => state.signup_data);
  const { emailRegisterData } = useSelector((state) => state.email_data);
  const { sessionId } = useSelector((state) => state.auth);
  let usersessionID = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : sessionId
  const { showLogoutAllModal } = useSelector(
    (state) => state.logoutallModal
  );
  const SIGN_OUT__ALL_URL = `${BASE_URL}users/${usersessionID}/sign_out_all`;
  const handleSignoutAll = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        SIGN_OUT__ALL_URL,
        {
          auth_token: auth_token,
          access_token: access_token,
          session: usersessionID,
          session_id: usersessionID,
          id: otpVerifyData?.user_id || emailRegisterData?.user_id,
          type: "session",
          user: {
            email_id: otpVerifyData?.email_id || emailRegisterData?.email_id,
            mobile_number:
              otpVerifyData?.mobile_number || emailRegisterData?.mobile_number,
            session: otpVerifyData?.session || emailRegisterData?.session,
            login_type: otpVerifyData?.login_type || emailRegisterData?.session,
            user_id: otpVerifyData?.user_id || emailRegisterData?.user_id,
            firstname: otpVerifyData?.firstname || emailRegisterData?.firstname,
            lastname: otpVerifyData?.lastname || emailRegisterData?.lastname,
            profile_pic:
              otpVerifyData?.profile_pic || emailRegisterData?.profile_pic,
            first_time_login:
              otpVerifyData?.first_time_login ||
              emailRegisterData?.first_time_login,
            state: otpVerifyData?.state || emailRegisterData?.state,
            district: otpVerifyData?.district || emailRegisterData?.district,
            city: otpVerifyData?.city || emailRegisterData?.city,
            language: otpVerifyData?.language || emailRegisterData?.language,
            address: otpVerifyData?.address || emailRegisterData?.address,
            add_profile:
              otpVerifyData?.add_profile || emailRegisterData?.add_profile,
            profile_obj: {
              firstname:
                otpVerifyData?.profile_obj?.first_name ||
                emailRegisterData?.profile_obj,
              lastname:
                otpVerifyData?.profile_obj?.last_name ||
                emailRegisterData?.profile_obj?.last_name,
              region:
                otpVerifyData?.profile_obj?.region ||
                emailRegisterData?.profile_obj?.region,
              profile_id:
                otpVerifyData?.profile_obj?.profileid ||
                emailRegisterData?.profile_obj?.profileid,
              default_profile:
                otpVerifyData?.profile_obj?.default_profile ||
                emailRegisterData?.profile_obj?.default_profile,
            },
            is_device_limit_status:
              otpVerifyData?.is_device_limit_status ||
              emailRegisterData?.is_device_limit_status,
            region:
              otpVerifyData?.profile_obj?.region ||
              emailRegisterData?.profile_obj?.region,
          },
        },

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        localStorage.clear();
        localStorage.setItem("login_status", false);
        toast.success(response?.data?.data?.message);
        dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
        dispatch(setShowLogoutAllModal({ showLogoutAllModal: false }))
        window.location.href = '/home';
      } else {
      }
    } catch (error) {
      setErrorMes(error?.response?.data?.error?.message)

      if (error?.response) {
        console.error("Server logout responded with an error:", error);
        toast.error(error?.response?.data?.error?.message);
      }

      if (error?.response?.status === 422) {
        if (error?.response?.data?.error?.message) {
        }
      }
    }
  };

useEffect(() => {
  if(errorMes === "Invalid Session id"){
    dispatch(setShowLogoutAllModal({ showLogoutAllModal: false }))
      localStorage.clear();
      localStorage.setItem("login_status", false);
      dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
      window.location.href = "/home";
  }
}, [errorMes])

  

  const handleLogoutAllClose = () => {
    dispatch(setShowLogoutAllModal({ showLogoutAllModal: false }))
  };

  return (
    <>
      <CommonLogout
      show={showLogoutAllModal}
      handleClose={handleLogoutAllClose}
      handleSignout={handleSignoutAll}
      title="Are you sure you want to logout from all devices?"
      text="logout all"
    />
    </>
  );
}
