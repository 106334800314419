import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscribeSource: false,
};

export const subscribesourceSlice = createSlice({
  name: "subscribesources",
  initialState,
  reducers: {
    setSubscribeSource: (state, action) => {
        state.subscribeSource = action.payload.subscribeSource  
      },
  },
});

export const {setSubscribeSource} = subscribesourceSlice.actions;

export default subscribesourceSlice.reducer;