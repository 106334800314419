import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BASE_URL,
  SIGN_OUT_URL,
  USERS_ENDPOINT,
  access_token,
  auth_token,
  sessionID,
} from "../../services/config";
import { setIsprofilepicremove } from "../../Redux/Slice/profileremoveSlice";

export default function RemoveProfilePicture({
  showRemoveProfilePicture,
  handleRemoveProfilePicModalClose,
  setshowRemoveProfilePicture,
  profiledata,
  fetchProfileDetails,
  setProfiledata,
}) {
  const PROFILE_DETAILS = `${BASE_URL}users/${sessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
const dispatch = useDispatch()

  const handleProfilePicRemoval = async (e) => {
    e.preventDefault();
    try {
      // if (formData.profile_pic) {
      //   await uploadProfilePic(formData.profile_pic);
      // }
      //alert(JSON.stringify(formData));
      await axios.put(
        PROFILE_DETAILS,
        {
          firstname: profiledata.firstname,
          email_id: profiledata.email_id,
          mobile_number: profiledata.mobile_number,
          gender: profiledata.gender,
          birthdate: profiledata.birthdate,
          profile_pic: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(setIsprofilepicremove({ isprofilepicremove: true}));
      toast.success("Profile Picture Removed Successfully!");
      setshowRemoveProfilePicture(false);
      setProfiledata({
        firstname: profiledata.firstname,
        email_id: profiledata.email_id,
        mobile_number: profiledata.mobile_number,
        gender: profiledata.gender,
        birthdate: profiledata.birthdate,
        profile_pic: "",
      });
      await fetchProfileDetails();
      //   window.location.href = '/profile';
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to Remove Profile Pic! Please try again.");
    }
  };

  return (
    <>
      {showRemoveProfilePicture && (
        <Modal
          show={showRemoveProfilePicture}
          onHide={handleRemoveProfilePicModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck row just-content">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-left-styles mb-4">
                  <h6 className="text-center">
                    Are you sure you want to remove your profile picture?
                  </h6>
                </div>

                <div className="form-style">
                  <Row className="">
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="red-button w-100">
                        <Link to="" onClick={handleProfilePicRemoval} className="w-100">
                          Yes
                        </Link>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="cancel-button w-100">
                        <Link onClick={handleRemoveProfilePicModalClose} className="w-100">
                          No
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
