/* global google */
// Don't remove this comment, this is to prevent the eslint error for google not found.

var videoElement;
var adsLoaded = false;
var adDisplayContainer;
var adsLoader;
var adsManager;
var adContainer;
var isAdPlaying;
var adIsPaused = false;
var adVideoElement;
var adTimerInterval;
var adTimer;
var playPauseButton;
var muteButton;
var isMuted = false;
var midRollCalled;
var adInitialized = false;
var isIOSDevice = false;
var isFullScreen = false;
let retryCount = 0;
const maxRetries = 3;
var adsRequest;

const adInitializedEvent = new Event("adInitialized");

export const initializeAds = (
  videoElem,
  container,
  adTagUrl,
  midRoll,
  isIOS
) => {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.ima) {
      reject(new Error("Google IMA SDK not loaded"));
      console.log("IMA SDK not loaded");
      return;
    }

    videoElement = videoElem;
    adContainer = container;
    midRollCalled = midRoll;
    isIOSDevice = isIOS;

    if (!videoElement) {
      reject(new Error("Video element is not defined"));
      console.log("Video element is not defined");
      return;
    }

    adContainer.addEventListener("click", adContainerClick);
    adContainer.addEventListener("dblclick", handleDoubleClick);

    google.ima.settings.setVpaidMode(
      google.ima.ImaSdkSettings.VpaidMode.ENABLED
    );
    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
    adDisplayContainer = new google.ima.AdDisplayContainer(
      adContainer,
      videoElement
    );
    adsLoader = new google.ima.AdsLoader(adDisplayContainer);

    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      (event) => {
        onAdsManagerLoaded(event);
        resolve();
      },
      false
    );

    adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      (event) => {
        onAdError(event);
        reject(new Error(`Ad Error: ${event.getError().toString()}`));
        videoElement.removeEventListener("ended", onContentComplete);
        window.removeEventListener("resize", onWindowResize);
      },
      false
    );

    videoElement.addEventListener("ended", onContentComplete);
    window.addEventListener("resize", onWindowResize);

    adsRequest = new google.ima.AdsRequest();
    adsRequest.adTagUrl =
      window.location.hostname === "localhost"
        ? "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
        : adTagUrl;
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight;

    if (isIOSDevice) {
      adsRequest.setAdWillPlayMuted(true);
    }

    adsLoader.requestAds(adsRequest);
    adInitialized = true;
    window.dispatchEvent(adInitializedEvent);
  });
};

const onContentComplete = () => {
  if (adsLoader) {
    adsLoader.contentComplete();
  }
};

const onWindowResize = () => {
  if (adsManager) {
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    adsManager.resize(width, height, google.ima.ViewMode.FULLSCREEN);
  }
};

function resizeAds() {
  if (adsManager) {
    isFullScreen =
      document.fullscreenElement === videoElement ||
      document.webkitFullscreenElement === videoElement ||
      document.mozFullScreenElement === videoElement ||
      document.msFullscreenElement === videoElement ||
      (isIOSDevice &&
        videoElement.clientHeight === window.innerHeight &&
        videoElement.clientWidth === window.innerWidth);
    if (isFullScreen) {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.FULLSCREEN
      );
    } else {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.NORMAL
      );
    }
  }
}

async function onAdsManagerLoaded(adsManagerLoadedEvent) {
  var adsRenderingSettings = new google.ima.AdsRenderingSettings();
  adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
  if (!isIOSDevice) {
    adsRenderingSettings.enablePreloading = true;
  }
  adsManager = adsManagerLoadedEvent.getAdsManager(
    videoElement,
    adsRenderingSettings
  );

  resizeAds();

  if (
    videoElement &&
    adContainer &&
    adsManager &&
    adInitialized &&
    midRollCalled
  ) {
    loadAds(adsManagerLoadedEvent);
  }

  adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested
  );

  const events = [
    google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
    google.ima.AdEvent.Type.CLICK,
    google.ima.AdEvent.Type.COMPLETE,
    google.ima.AdEvent.Type.FIRST_QUARTILE,
    google.ima.AdEvent.Type.LOADED,
    google.ima.AdEvent.Type.MIDPOINT,
    google.ima.AdEvent.Type.PAUSED,
    google.ima.AdEvent.Type.RESUMED,
    google.ima.AdEvent.Type.STARTED,
    google.ima.AdEvent.Type.THIRD_QUARTILE,
  ];

  for (const eventType of events) {
    adsManager.addEventListener(eventType, onAdEvent);
  }
}

const playIcon = `
<svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill="#ffffff" d="M15.5615866,8.10002147 L3.87056367,0.225209313 C3.05219207,-0.33727727 2,0.225209313 2,1.12518784 L2,16.8748122 C2,17.7747907 3.05219207,18.3372773 3.87056367,17.7747907 L15.5615866,9.89997853 C16.1461378,9.44998927 16.1461378,8.55001073 15.5615866,8.10002147 L15.5615866,8.10002147 Z"></path>
</svg>
`;

const pauseIcon = `
<svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill="#ffffff" d="M6,1 L3,1 C2.4,1 2,1.4 2,2 L2,16 C2,16.6 2.4,17 3,17 L6,17 C6.6,17 7,16.6 7,16 L7,2 C7,1.4 6.6,1 6,1 L6,1 Z"></path>
    <path fill="#ffffff" d="M12,1 C11.4,1 11,1.4 11,2 L11,16 C11,16.6 11.4,17 12,17 L15,17 C15.6,17 16,16.6 16,16 L16,2 C16,1.4 15.6,1 15,1 L12,1 Z"></path>
</svg>
`;

const muteIcon = `
<svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <polygon fill="#ffffff" points="12.4 12.5 14.5 10.4 16.6 12.5 18 11.1 15.9 9 18 6.9 16.6 5.5 14.5 7.6 12.4 5.5 11 6.9 13.1 9 11 11.1"></polygon>
    <path fill="#ffffff" d="M3.78571429,6.00820648 L0.714285714,6.00820648 C0.285714286,6.00820648 0,6.30901277 0,6.76022222 L0,11.2723167 C0,11.7235261 0.285714286,12.0243324 0.714285714,12.0243324 L3.78571429,12.0243324 L7.85714286,15.8819922 C8.35714286,16.1827985 9,15.8819922 9,15.2803796 L9,2.75215925 C9,2.15054666 8.35714286,1.77453879 7.85714286,2.15054666 L3.78571429,6.00820648 Z"></path>
</svg>
`;

const unmuteIcon = `
<svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill="#ffffff" d="M15.5999996,3.3 C15.1999996,2.9 14.5999996,2.9 14.1999996,3.3 C13.7999996,3.7 13.7999996,4.3 14.1999996,4.7 C15.3999996,5.9 15.9999996,7.4 15.9999996,9 C15.9999996,10.6 15.3999996,12.1 14.1999996,13.3 C13.7999996,13.7 13.7999996,14.3 14.1999996,14.7 C14.3999996,14.9 14.6999996,15 14.8999996,15 C15.1999996,15 15.3999996,14.9 15.5999996,14.7 C17.0999996,13.2 17.9999996,11.2 17.9999996,9 C17.9999996,6.8 17.0999996,4.8 15.5999996,3.3 L15.5999996,3.3 Z"></path>
    <path fill="#ffffff" d="M11.2819745,5.28197449 C10.9060085,5.65794047 10.9060085,6.22188944 11.2819745,6.59785542 C12.0171538,7.33303477 12.2772954,8.05605449 12.2772954,9.00000021 C12.2772954,9.93588462 11.851678,10.9172014 11.2819745,11.4869049 C10.9060085,11.8628709 10.9060085,12.4268199 11.2819745,12.8027859 C11.4271642,12.9479755 11.9176724,13.0649528 12.2998149,12.9592565 C12.4124479,12.9281035 12.5156669,12.8776063 12.5978555,12.8027859 C13.773371,11.732654 14.1311161,10.1597914 14.1312523,9.00000021 C14.1312723,8.8299555 14.1286311,8.66015647 14.119665,8.4897429 C14.0674781,7.49784946 13.8010171,6.48513613 12.5978554,5.28197449 C12.2218894,4.9060085 11.6579405,4.9060085 11.2819745,5.28197449 Z"></path>
    <path fill="#ffffff" d="M3.78571429,6.00820648 L0.714285714,6.00820648 C0.285714286,6.00820648 0,6.30901277 0,6.76022222 L0,11.2723167 C0,11.7235261 0.285714286,12.0243324 0.714285714,12.0243324 L3.78571429,12.0243324 L7.85714286,15.8819922 C8.35714286,16.1827985 9,15.8819922 9,15.2803796 L9,2.75215925 C9,2.15054666 8.35714286,1.77453879 7.85714286,2.15054666 L3.78571429,6.00820648 Z"></path>
</svg>
`;

function createPlayPauseButton() {
  if (playPauseButton) {
    playPauseButton.remove();
  }
  playPauseButton = document.createElement("div");
  playPauseButton.id = "ad-play-pause-button";
  playPauseButton.innerHTML = pauseIcon;
  playPauseButton.setAttribute("role", "button");
  playPauseButton.setAttribute("aria-label", "Play/Pause Ad");

  playPauseButton.addEventListener("click", togglePlayPause);
  if (adContainer) {
    adContainer.appendChild(playPauseButton);
  }
}

function updatePlayPauseButton(paused) {
  if (playPauseButton) {
    playPauseButton.innerHTML = paused ? playIcon : pauseIcon;
  }
}

function togglePlayPause() {
  if (!adsManager) {
    console.error("AdsManager is not available");
    return;
  }

  try {
    if (adIsPaused) {
      adsManager.resume();
      adVideoElement.play();
      adIsPaused = false;
      updatePlayPauseButton(false);
    } else {
      adsManager.pause();
      adVideoElement.pause();
      adIsPaused = true;
      updatePlayPauseButton(true);
    }
  } catch (error) {
    console.error("Error toggling play/pause:", error);
  }
}

function removePlayPauseButton() {
  if (playPauseButton) {
    playPauseButton.remove();
    playPauseButton = null;
  }
}

function createMuteButton() {
  if (muteButton) {
    muteButton.remove();
  }
  muteButton = document.createElement("div");
  muteButton.id = "ad-mute-button";
  muteButton.innerHTML = isIOSDevice ? muteIcon : unmuteIcon;
  muteButton.setAttribute("role", "button");
  muteButton.setAttribute("aria-label", isMuted ? "Unmute Ad" : "Mute Ad");

  muteButton.addEventListener("click", toggleMute);
  if (adContainer) {
    adContainer.appendChild(muteButton);
  }
}

function updateMuteButton() {
  if (muteButton) {
    muteButton.innerHTML = isMuted ? muteIcon : unmuteIcon;
  }
}

function toggleMute() {
  if (!adsManager || !adVideoElement) {
    console.error("AdsManager or adVideoElement is not available");
    return;
  }

  isMuted = !isMuted;
  adVideoElement.muted = isMuted;
  adsManager.setVolume(isMuted ? 0 : 1);
  updateMuteButton();
}

function removeMuteButton() {
  if (muteButton) {
    muteButton.remove();
    muteButton = null;
  }
}

function createAdTimer() {
  if (adTimer) {
    adTimer.remove();
  }
  adTimer = document.createElement("div");
  adTimer.id = "ad-timer";

  if (adContainer && adVideoElement) {
    adContainer.appendChild(adTimer);
  }
}

function updateAdTimer() {
  if (adTimer && adVideoElement) {
    const remainingTime = Math.max(
      0,
      Math.ceil(adVideoElement.duration - adVideoElement.currentTime)
    );

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    adTimer.textContent = `Ends in ${minutes}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}

function startAdTimer() {
  createAdTimer();
  adTimerInterval = setInterval(updateAdTimer, 1000);
}

function stopAdTimer() {
  if (adTimerInterval) {
    clearInterval(adTimerInterval);
    adTimerInterval = null;
  }
  if (adTimer) {
    adTimer.remove();
  }
}

async function onAdEvent(adEvent) {
  console.log("adEvent: ", adEvent.type);

  var ad = adEvent.getAd();
  switch (adEvent.type) {
    case google.ima.AdEvent.Type.LOADED:
      if (!ad.isLinear()) {
        try {
          await videoElement.play();
        } catch (error) {
          console.error("Error playing video:", error);
        }
      }
      break;
    case google.ima.AdEvent.Type.STARTED:
      if (adContainer) {
        adVideoElement =
          adContainer.querySelector('video[style*="display: block"]') ||
          adContainer.querySelector("video[src]");
        createPlayPauseButton();
        createMuteButton();
        startAdTimer();
        retryCount = 0;
      }
      break;
    case google.ima.AdEvent.Type.PAUSED:
      adIsPaused = true;
      updatePlayPauseButton(true);
      break;
    case google.ima.AdEvent.Type.RESUMED:
      adIsPaused = false;
      updatePlayPauseButton(false);
      break;
    case google.ima.AdEvent.Type.COMPLETE:
    case google.ima.AdEvent.Type.SKIPPED:
    case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
      stopAdTimer();
      removePlayPauseButton();
      removeMuteButton();
      midRollCalled = false;
      adIsPaused = false;
      retryCount = 0;
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }
      break;

    default:
      break;
  }
}

const adFailedEvent = new Event("adFailed");

function onAdError(adErrorEvent) {
  const adError = adErrorEvent.getError();
  console.error("adError: ", adError);
  window.dispatchEvent(adFailedEvent);

  if (adError) {
    const errorCode = adError.getErrorCode();
    if (
      errorCode === google.ima.AdError.ErrorCode.VAST_MEDIA_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.VAST_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.ADS_REQUEST_NETWORK_ERROR ||
      errorCode === google.ima.AdError.ErrorCode.FAILED_TO_REQUEST_ADS
    ) {
      if (retryCount < maxRetries) {
        retryCount++;
        setTimeout(() => {
          adsLoader.contentComplete();
          adsLoader.requestAds(adsRequest);
        }, 5000);
      } else {
        cancelAds();
        onContentResumeRequested();
      }
    } else {
      cancelAds();
      onContentResumeRequested();
    }
  } else {
    cancelAds();
    onContentResumeRequested();
  }
}
const adPlayingEvent = new Event("adPlaying");
const adStoppedEvent = new Event("adStopped");

async function onContentPauseRequested() {
  if (videoElement) {
    adContainer.style.zIndex = 3;
    isAdPlaying = true;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "none";
      controls.style.opacity = 0;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "none";
      overlaidControls.style.opacity = 0;
    }

    window.dispatchEvent(adPlayingEvent);

    try {
      await videoElement.pause();
    } catch (error) {
      console.error("Error pausing video:", error);
    }
  }
}

async function onContentResumeRequested() {
  if (videoElement) {
    adContainer.style.zIndex = -1;
    isAdPlaying = false;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "flex";
      controls.style.opacity = 1;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "flex";
      overlaidControls.style.opacity = 1;
    }

    window.dispatchEvent(adStoppedEvent);

    try {
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }
      await videoElement.play();
    } catch (error) {
      console.error("Error resuming video playback:", error);
    }
  }
}

function handleDoubleClick(event) {
  if (isAdPlaying) {
    event.preventDefault();
    event.stopPropagation();
  }
}

async function adContainerClick(event) {
  if (videoElement) {
    if (videoElement.paused) {
      try {
        await videoElement.play();
      } catch (error) {
        console.error("Error resuming video playback:", error);
      }
    } else {
      try {
        await videoElement.pause();
      } catch (error) {
        console.error("Error pausing video:", error);
      }
    }
  }
}

export const loadAds = async (event) => {
  if (adsLoaded) {
    return;
  }

  event.preventDefault();

  if (!videoElement || !adDisplayContainer) {
    console.error("Video element or ad display container is not available");
    return;
  }

  if (adInitialized) {
    adDisplayContainer.initialize();
    try {
      setTimeout(async () => {
        if (adsManager && !adsLoaded) {
          if (isFullScreen) {
            await adsManager.init(
              videoElement.clientWidth,
              videoElement.clientHeight,
              google.ima.ViewMode.FULLSCREEN
            );
          } else {
            await adsManager.init(
              videoElement.clientWidth,
              videoElement.clientHeight,
              google.ima.ViewMode.NORMAL
            );
          }
          await adsManager.start();
        }
        adsLoaded = true;
      }, 500);
    } catch (adError) {
      console.error("AdsManager could not be started: ", adError);
      try {
        await videoElement.play();
      } catch (playError) {
        console.error("Error resuming video playback:", playError);
      }
    }
  }
};

export const cancelAds = () => {
  if (adsManager) {
    adsManager.destroy();
    adsManager = null;
  }
  adInitialized = false;
  midRollCalled = false;
  adsLoaded = false;
  clearAllEventListeners();
};

function clearAllEventListeners() {
  if (adContainer) {
    adContainer.removeEventListener("click", adContainerClick);
    adContainer.removeEventListener("dblclick", handleDoubleClick);
  }
  if (playPauseButton) {
    playPauseButton.removeEventListener("click", togglePlayPause);
  }
  if (muteButton) {
    muteButton.removeEventListener("click", toggleMute);
  }

  if (videoElement) {
    videoElement.removeEventListener("ended", onContentComplete);
  }
  window.removeEventListener("resize", onWindowResize);
  if (adsManager) {
    adsManager.removeEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      onAdError
    );
    const events = [
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.CLICK,
      google.ima.AdEvent.Type.COMPLETE,
      google.ima.AdEvent.Type.FIRST_QUARTILE,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.MIDPOINT,
      google.ima.AdEvent.Type.PAUSED,
      google.ima.AdEvent.Type.RESUMED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.THIRD_QUARTILE,
    ];
    for (const eventType of events) {
      adsManager.removeEventListener(eventType, onAdEvent);
    }
  }
}
