import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterPage: 0,
  filterFromDate: null,
  filterToDate: null,
}

export const filterDateSlice = createSlice({
  name: 'filterdate data',
  initialState,
  reducers: {
    setFilterPage: (state, action) => {
      state.filterPage = action.payload.filterPage
    },
    setFilterFromDate: (state, action) => {
      state.filterFromDate = action.payload.filterFromDate;
    },
    setFilterToDate: (state, action) => {
      state.filterToDate = action.payload.filterToDate;
    },
    incrementFilterPage: (state) => {
      state.filterPage += 1;
    },
  },
})

export const {setFilterPage, setFilterFromDate, setFilterToDate, incrementFilterPage } = filterDateSlice.actions

export default filterDateSlice.reducer