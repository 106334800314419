import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export const NoInternetScreen = () => {
  const NoInternetImage = "/images/nonet.gif";

  return (
    <>
        <div className='noInternetPage'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={5} mb={7} xs={12}>
                        <div className='noNetCard'>
                            <div className='gifIcon'>
                                <img src={NoInternetImage} alt="" />
                            </div>
                            <h3 className=''><span>Oops!</span> No Internet</h3>
                            <p>Please check your network connection.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>


        {/* {noInternetScreen && (
        <Modal
          show={noInternetScreen}
          onHide={handleNoInternetScreenClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck">
            <Row className='justify-content-center'>
                    <Col lg={5} mb={7} xs={12}>
                        <div className='noNetCard'>
                            <div className='gifIcon'>
                                <img src="../../../assets/images/nonet.gif" alt="" />
                            </div>
                            <h3 className=''><span>Oops!</span> No Internet</h3>
                            <p>Please check your network connettion.</p>
                            <Link className='backToHome'>try again </Link>
                        </div>
                    </Col>
                </Row>
            </div>
          </Modal.Body>
        </Modal>
      )} */}
    </>
  )
}