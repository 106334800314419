import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userConfigData: {},
};

export const userConfigSlice = createSlice({
  name: "userconfig",
  initialState,
  reducers: {
    setUserConfigData: (state, action) => {
      state.userConfigData = action.payload.userConfigData;
    },
  },
});

export const { setUserConfigData, userConfigData } = userConfigSlice.actions;

export default userConfigSlice.reducer;
