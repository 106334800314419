import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showStreamLimit : false,
}

export const showStreamInfoSlice = createSlice({
  name: 'showstreamlimit',
  initialState,
  reducers: {
    setShowStreamInfoAlertOpen: (state) => {
      state.showStreamLimit = true;
    },
    setShowStreamInfo: (state, action) => {
      state.showStreamLimit = action.payload.showStreamLimit 
    },
  },
})

export const {setShowStreamInfo, setShowStreamInfoAlertOpen} = showStreamInfoSlice.actions

export default showStreamInfoSlice.reducer