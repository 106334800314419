import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoadSlider = ({loading}) => {
    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton height={630} />
        </p>
      </SkeletonTheme>
    )
}

const SkeletonVideoThumbnail = ({height}) => {
  return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <p>
        <Skeleton height={parseInt(height)} /> 
      </p>
    </SkeletonTheme>
  )
}

const SkeletonLoader = ({height, width}) => {
  const defaultWidth = 200;
    return (
        <div
      style={{
        display: 'block',
      }}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
    <p>
      <Skeleton height={parseInt(height)} width={parseInt(width) || defaultWidth} />
      
    </p>
  </SkeletonTheme>
    </div>
    )
}

const SkeletonLoadTitleImage = ({height, width}) => {
  const defaultWidth = 200;

  return (
      <div
    style={{
      // border: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'space-between', // Distribute items evenly

      // lineHeight: 2,
      // padding: '0.5rem',
      // marginBottom: '0.5rem',
      // width: 500,
      // height: 132,
      
    }}
  >
     <div >
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
          <Skeleton height={parseInt(height)} width={parseInt(width) || defaultWidth} />
          </p>
        </SkeletonTheme>
      </div>

    
      
  </div>
  )
}


const SkeletonLoadContent = ({ loading }) => {
  return (
    <div>
      {[...Array(4)].map((_, index) => (
        <div key={index} style={{ marginBottom: '1px' }}>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <p style={{ margin: 0 }}>
              <Skeleton height={8} width={280} />
            </p>
          </SkeletonTheme>
        </div>
      ))}
    </div>
  );
};


const SkeletonLoadImages = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between', // Distribute items evenly
      }}
    >
      {[...Array(5)].map((_, index) => (
        <div key={index} style={{ marginRight: '20px' }}>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <Skeleton circle={true} height={50} width={50} />
            </div>
          </SkeletonTheme>
        </div>
      ))}
    </div>
  );
};

const SkeletonLoadBoxDynamic = ({height, width}) => {
  const defaultWidth = 200;

  return (
      <div
    style={{
      // border: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'space-between', // Distribute items evenly

      // lineHeight: 2,
      // padding: '0.5rem',
      // marginBottom: '0.5rem',
      // width: 500,
      // height: 132,
      
    }}
  >
     <div >
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
          <Skeleton height={parseInt(height)} width={parseInt(width) || defaultWidth} />
          </p>
        </SkeletonTheme>
      </div>

    
      
  </div>
  )
}

const SkeletonLoadBoxMOre = ({loading}) => {
  return (
      <div
    style={{
      // border: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'space-between', // Distribute items evenly

      // lineHeight: 2,
      // padding: '0.5rem',
      // marginBottom: '0.5rem',
      // width: 500,
      // height: 132,
      
    }}
  >
     <div style={{ marginRight: '10px' }}>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>

    
      <div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>
      <div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>
      <div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>
      <div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>
      <div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <p>
            <Skeleton height={110} width={200} />
          </p>
        </SkeletonTheme>
      </div>
  </div>
  )
}


const SkeletonLoadCircle = () => {
    return (
        <div
      style={{
        // border: '1px solid #ccc',
        display: 'block',
        borderRadius: "50%",
        // lineHeight: 2,
        // padding: '0.5rem',
        // marginBottom: '0.5rem',
        // width: 500,
        // height: 132,
        
      }}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
    <p>
      <Skeleton height={132} />
    </p>
  </SkeletonTheme>
    </div>
    )
}

export { SkeletonLoadBoxDynamic,SkeletonLoadBoxMOre, SkeletonVideoThumbnail, SkeletonLoadImages, SkeletonLoadSlider,SkeletonLoadContent,  SkeletonLoadCircle, SkeletonLoader, SkeletonLoadTitleImage };
