import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  episodedatacontent: {},
  episodetypedetails: {},
  episodeDates: [],
  videoTitle: {}
}

export const episodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    setEpisodeData: (state, action) => {
      state.episodedatacontent = action.payload.episodedatacontent
    },
    setEpisodetypedetails: (state, action) => {
      state.episodetypedetails = action.payload.episodetypedetails
    },
    setEpisodeDates: (state, action) => {
      state.episodeDates = action.payload.episodeDates
    },
    setVideoTitle: (state, action) => {
      state.videoTitle = action.payload.videoTitle
    },
  },
})

export const { setEpisodeData, setEpisodetypedetails, setEpisodeDates, setVideoTitle } = episodeSlice.actions

export default episodeSlice.reducer