import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showMobileRegisterModal: false,
};

export const mobileregistermodalSlice = createSlice({
  name: "mobileregistermodal",
  initialState,
  reducers: {
    setShowMobileRegisterModal: (state, action) => {
        state.showMobileRegisterModal = action.payload.showMobileRegisterModal  
      },
  },
});

export const {setShowMobileRegisterModal} = mobileregistermodalSlice.actions;

export default mobileregistermodalSlice.reducer;