import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  seasondropdownData: [],
}

export const seasonSlice = createSlice({
  name: 'dropdown data',
  initialState,
  reducers: {
    setSeasondropdownData: (state, action) => {
      state.seasondropdownData = action.payload.seasondropdownData
    }, 
  },
})

export const { setSeasondropdownData } = seasonSlice.actions

export default seasonSlice.reducer