import axios from 'axios';
import { BASE_URL, access_token, auth_token } from '../../services/config';
import { setRegionsData } from '../../Redux/Slice/regionslice';

export const getRegionData = () => {
  return async dispatch => {
    const autoDetectURL = `${BASE_URL}/regions/autodetect/ip.gzip?auth_token=${auth_token}&access_token=${access_token}`;
    
    try{
      const response = await axios.get(autoDetectURL)

      if(response.status === 200){
        // console.log("regtt", response.data.region)
    
        dispatch(setRegionsData(response?.data?.region))
      }
    }catch(err){
      console.log(err);
    }
  }
  }
