import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activePlanData: {},
}

export const activePlanSlice = createSlice({
  name: 'activeplans',
  initialState,
  reducers: {
    setActivePlanData: (state, action) => {
      state.activePlanData = action.payload.activePlanData
    },
  },
})

export const { setActivePlanData } = activePlanSlice.actions

export default activePlanSlice.reducer