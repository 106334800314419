import React, { Suspense, lazy, useEffect, useState } from "react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// import Tvchanels from "../pages/TvChannels/Tvchanels";
// import VideoDetails from "../pages/videodetails/videodetails";
// import LiveTv from "../pages/liveTv/LiveTv";
// import News from "../pages/News/News";
import Login from "../pages/login/login";
import LoginScreen from "../pages/login/login";
// import Health from "../pages/Health/Health";
// import Agriculture from "../pages/Agriculture/Agriculture";
import ActiveTv from "../pages/ActiveTv/ActiveTv";
import MyProfile from "../pages/MyProfile/MyProfile";
import VideoPlayer from "../pages/Videoplayer/VideoPlayer";
import MyTransactions from "../pages/MyTransactions/MyTransactions";
// import Movies from "../pages/Movies/Movies";
import Filters from "../pages/Filters/Filters";
import MyPreferences from "../pages/MyPreferences/Preferences";
import EmailValidation from "../pages/emailValid/emailValidation";
import Successpage from "../pages/Register/successpage";
import VerifyFailed from "../pages/Register/failedpage";
import About from "../Cmspages/About/aboutus";
import Contact from "../Cmspages/Contact/contactus";
import Faq from "../Cmspages/FAQ/Faq";
import Feedback from "../Cmspages/Feedback/Feedback";
import MoreDetails from "../pages/More/More";
import MorePage from "../pages/MorePage";
import MoreDetailsPage from "../pages/MoreDetails";
import OtpVerification from "../pages/otpverify/signupmobileverify";
import PaymentStatus from "../pages/Paymentstatus/Paymentstatus";
import EmailVerified from "../pages/Register/emailverified";
import ShowVideoView from "../pages/videodetails/showvideoview";
import MyList from "../pages/Mylist/Mylist";
import ResetPassword from "../pages/Register/ResetPassword";
import Movies from "../pages/Movies/Movies";
import PrivacyPolicy from "../Cmspages/PrivacyPolicy/privacypolicy";
import ChangePassword from "../pages/Register/changepassword";
import { NoInternetScreen } from "../pages/nointernet";
import EpisodeMore from "../pages/More/EpisodeMore";
import RedeemScreen from "../pages/redeem";
import AboutUs from "../Cmspages/About/aboutus";
import TermsAndConditions from "../Cmspages/TermsConditions/TermsConditions";
import Header from "../Componets/Header/Header";
import Tvaboutus from "../Otherdevices/tvaboutus";
import Othertermsandconditions from "../Otherdevices/othertermsandconditions";
import OtherFaq from "../Otherdevices/Otherfaq";
import Otherprivacypolicy from "../Otherdevices/Otherprivacypolicy";
import Otherprivacypolicytv from "../Otherdevices/Otherprivacypolicytv";
import Plandetailstv from "../Otherdevices/Plandetailstv";
import Othercontactus from "../Otherdevices/Othercontactus";
import { useSelector } from "react-redux";
import RecipesMoreDetails from "../pages/More/recipesMore";
// import Recipes from "../pages/recipes/recipes";
const LazyLoadHome = lazy(() => import("../pages/Homepage/Home"));
const LazyLoadTvShows = lazy(() => import("../pages/TvChannels/Tvchanels"));
const LazyLoadMovies = lazy(() => import("../pages/Movies/Movies"));
const LazyLoadLiveTv = lazy(() => import("../pages/liveTv/LiveTv"));
const LazyLoadNews = lazy(() => import("../pages/News/NewsData"));
const LazyLoadRecipes = lazy(() => import("../pages/recipes/recipes"));
const LazyLoadHealth = lazy(() => import("../pages/Health/Health"));
const LazyLoadAgriculture = lazy(() =>
  import("../pages/Agriculture/Agriculture")
);
const LazyLoadVideoDetails = lazy(() =>
  import("../pages/videodetails/videodetails")
);
const LazyLoadSubscription = lazy(() =>
  import("../pages/Subscription-Plans/Subscriptionplan")
);

const Routers = () => {
  const [sessionId, setSessionId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [foodfiltershow, setFoodfiltershow] = useState(false);

  const loginstatus = localStorage.getItem("login_status");
  const [isLogin, setIsLogin] = useState(loginstatus ? true : false);
  const [hideHeader, setHideHeader] = useState(false); // State to control header visibility
  const shouldHideHeader = (pathname) => {
    // Define the list of routes where you want to hide the header
    const routesToHideHeader = [
      "/privacypolicy-tv.html",
      "/contactus.html",
      "/plandetailstv.html",
      "/privacypolicy.html",
      "/faq.html",
      "/aboutus.html",
      "/termsandconditions.html",
      "/termsandconditions-tv.html",
    ]; // Add more routes as needed
    //["/login", "/subscription", "/aboutus.html"];
    // Check if the current pathname matches any route where the header should be hidden
    return routesToHideHeader.includes(pathname);
  };
  useEffect(() => {
    // Check if the header should be hidden for the current route
    const pathname = window.location.pathname;
    setHideHeader(shouldHideHeader(pathname));
  }, []);

  const { menuListData } = useSelector((state) => state.menu_list);

  // console.log("menuListData", menuListData)

  const requireAuth = (element) => {
    return isLogin ? element : <Navigate to="/home" replace />;
  };

  // console.log("sessionId from routers", sessionId);
  //   const currentRoute = location.pathname.includes('/recipes') ? '/recipes' : '';
  //  console.log('currentRoute:', currentRoute);
  const predefinedRoutes = [
    // { path: "/tv-shows", element: <LazyLoadTvShows /> },
    { path: "/home", element: <LazyLoadHome /> },
    { path: "/recipes", element: <LazyLoadRecipes /> },
    { path: "/live", element: <LazyLoadLiveTv /> },
    { path: "/news", element: <LazyLoadNews /> },
    {
      path: "/login",
      element: <Login isLogin={isLogin} setIsLogin={setIsLogin} />,
    },
    { path: "/subscription", element: <LazyLoadSubscription /> },
    { path: "/health", element: <LazyLoadHealth /> },
    { path: "/agriculture-all", element: <LazyLoadAgriculture /> },
    { path: "/VideoPlayer", element: <VideoPlayer /> },
    { path: "/mytransactions", element: <MyTransactions /> },
    { path: "/telugu-movies", element: <LazyLoadMovies /> },
    { path: "/Filters", element: <Filters /> },
    { path: "/changepassword", element: <ChangePassword /> },
    { path: "/emailvalid/:confirmationToken", element: <EmailValidation /> },
    // { path: "/More", element: <More /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/contactus", element: <Contact /> },
    { path: "/faq", element: <Faq /> },
    { path: "/feedback", element: <Feedback /> },
    { path: "/privacypolicy", element: <PrivacyPolicy /> },
    { path: "/termsandconditions", element: <TermsAndConditions /> },
    // { path: "/error", element: <NoInternetScreen /> },
    { path: "/error", element: <NoInternetScreen /> },
    { path: "/redeem", element: <RedeemScreen /> },

    { path: "/aboutus.html", element: <Tvaboutus /> },
    { path: "/termsandconditions.html", element: <Othertermsandconditions /> },
    {
      path: "/termsandconditions-tv.html",
      element: <Othertermsandconditions />,
    },
    { path: "/faq.html", element: <OtherFaq /> },
    { path: "/privacypolicy.html", element: <Otherprivacypolicy /> },
    { path: "/privacypolicy-tv.html", element: <Otherprivacypolicytv /> },
    { path: "/plandetailstv.html", element: <Plandetailstv /> },
    { path: "/contactus.html", element: <Othercontactus /> },

    // { path: "/Paymentstatus", element:<Paymentstatus/> }
    // termsandconditions
  ];

  return (
    <div>
      <Suspense fallback="">
        {!hideHeader && <Header />}

        <Routes>
          {predefinedRoutes?.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {menuListData?.map((item) => (
            <Route
              key={item.friendly_id}
              path={`/${item.friendly_id}`}
              element={<LazyLoadTvShows key={item.friendly_id} />}
            />
          ))}
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/home" replace />} />

          {/* <Route
            path="/changepassword"
            element={requireAuth(<ChangePassword />)}
          /> */}
          <Route path="/profile" element={<MyProfile />} />
          {/* <Route path="/preference" element={requireAuth(<MyPreferences />)} /> */}
          <Route path="/watchlater" element={requireAuth(<MyList />)} />
          <Route path="/otp" element={requireAuth(<OtpVerification />)} />
          {/* <Route
            path="/success"
            element={requireAuth(<Successpage />)}
          /> */}

          <Route path="/success" element={<Successpage />} />
          <Route path="/verifyfailed" element={requireAuth(<VerifyFailed />)} />
          <Route path="/Activate-Etv" element={requireAuth(<ActiveTv />)} />

          <Route
            exact
            path={"/movies/telugu-movies/:genere"}
            element={<MorePage />}
          />

          <Route
            exact
            path={"/channels/:genere"}
            element={<MoreDetailsPage />}
          />

          <Route
            exact
            path={"/recipes/:seourl"}
            element={<RecipesMoreDetails />}
          />

          {/* <Route path="/recipes" element={<LazyLoadRecipes />} /> */}

          {/* <Route
  exact
  path="/:category/?:subcategory?/:main?/:seo_url"
  element={<VideoDetails />}
/> */}

          {/* <Route
            exact
            path={"/:movie-name/:movie-genere"}
            element={<MoreDetails />}
          /> */}

          <Route
            exact
            path={"/:category/:seo_url"}
            element={<LazyLoadVideoDetails />}
          />

          <Route
            path={"/:category/:seo_url/:seo_url_friendly"}
            element={<ShowVideoView />}
          />
          {/* <Route path={"/:telugu-movies/:seo_url/:seo_url_friendly"} element={<ShowVideoView />} /> */}

          {/* <Route path={"/:category/:seo_url/:main/:seo_url_friendly"} element={<VideoDetails /> } /> */}

          <Route
            path={"/:category/:seo_url/:main/:seo_url_friendly"}
            element={<ShowVideoView />}
          />

          <Route
            path={"/:category/:seo_url/:main/:seo_url_friendly/:fifth_url"}
            element={<ShowVideoView />}
          />

          {/* <Route
            exact
            path={"/:category/:friendly_id"}
            element={<VideoDetails />}
          /> */}
          {/* 
          <Route
            exact
            path={
              "/:category/:subcategory/:main/:seo_url"
                ? "/:category/:subcategory/:main/:seo_url"
                : "/:category/:seo_url"
            }
            element={<VideoDetails />}
          /> */}
          {/* <Route
            exact
            path={"/:category/:subcategory/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:category/:subcategory/:main/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:category/:friendly_id"}
            element={<MoreDetails />}
          /> */}

          <Route exact path="/:pageName" element={<MoreDetails />} />

          <Route
            exact
            path={"/:movie-name/:movie-genere"}
            element={<MoreDetails />}
          />
          <Route exact path="home/:pageName" element={<MoreDetails />} />
          <Route
            exact
            path="home/:pageName/:channelID"
            element={<MoreDetails />}
          />
          <Route
            exact
            path="more/:pageName/:categoryItem"
            element={<MoreDetails />}
          />
          <Route
            exact
            path="more/recipes/:pageName"
            element={<MoreDetails />}
          />
          <Route exact path="recipes/:pageName" element={<MoreDetails />} />
          <Route
            exact
            path="/:pageName/:pageName2/all"
            element={<EpisodeMore />}
          />
          <Route exact path="/more/:pageName2/all" element={<EpisodeMore />} />

          <Route
            exact
            path="/more/:pageName/:pageName2/:categoryItem"
            element={<MoreDetails />}
          />
          {/* 
     
      <Route
      exact
      path="/channels/etv-telugu/:categoryItem"
      element={<MoreDetails />}
      />
      <Route
      exact
      path="/channels/etv-plus/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-abhiruchi/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-andhra-pradesh/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-telangana/:categoryItem"
      element={<MoreDetails />}
      />
       <Route
      exact
      path="/channels/win-exclusive/:categoryItem"
      element={<MoreDetails />}
      /> */}
          <Route
            exact
            path="more/catalog_lists/:pageName"
            element={<MoreDetails />}
          />

          {/*
                <Route
            exact
            path="channels/:pageName"
            element={<MoreDetails />}
            /> */}
          {/* <Route
            exact
            path="recipes/:pageName"
            element={<MoreDetails />}
            /> */}
          {/* <Route
    exact
    path="/:pageName/:friendly_id"
    element={<MoreDetails />}
  /> */}

          {/* <Route
    exact
    path="/:friendly_id/:moreseo_url/:main"
    element={<MoreDetails />}
  /> */}

          {/* <Route
            exact
            path={"/:main/:submain/:category/:subcategory/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:channel/:channelname/:category/:subcategory/:seo_url"}
            element={<NewsDetails />}
          /> */}

          <Route exact path={"/paymentstatus"} element={<PaymentStatus />} />
          <Route exact path={"/confirmation"} element={<EmailVerified />} />
          <Route exact path={"/reset_password"} element={<ResetPassword />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default Routers;
