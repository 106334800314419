import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showAddProfile : false,
  showSuccessMessage: false
}

export const showProfileSlice = createSlice({
  name: 'showprofile',
  initialState,
  reducers: {
    setShowProfileOpen: (state) => {
      state.showAddProfile = true;
    },
    setShowSuccessMessageClose: (state) => {
      state.showSuccessMessage = false;
    }, 
    setShowProfile: (state, action) => {
      state.showAddProfile = action.payload.showAddProfile 
    },

    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload.showSuccessMessage 
    },
  },
})

export const {setShowProfile, setShowProfileOpen, setShowSuccessMessage, setShowSuccessMessageClose} = showProfileSlice.actions

export default showProfileSlice.reducer