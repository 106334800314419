import React, { useState, useEffect } from "react";
import { FaPlay, FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import axios from "axios";
import { setLikeDetails } from "../../Redux/Slice/likeanddislikeSlice";
import { toast } from "react-toastify";
import { getAnalytics,logEvent  } from 'firebase/analytics';

export default function Likefeature() {
  const {videodetailsitems} = useSelector(
    (state) => state.video_details
  );
  const dispatch = useDispatch();
  const isUserLoggedIn = localStorage.getItem("login_status");
  const [isLiked, setIsLiked] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const { sessionId } = useSelector((state) => state.auth);
  const session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const LikeURL = `${BASE_URL}users/${session_id}/playlists/like`;
  const [likeCount, setLikeCount] = useState("");
  const [likeresponse, setLikeresponse] = useState({});
  const [dislikeresponse, setDislikeresponse] = useState({});
  const regionsData = useSelector((state) => state.region.regionsData);
  const [listItemId, setListItemId] = useState("");
  const [mylistemItemID, setMylistemItemID] = useState("");

  const firebaseAnalytics = getAnalytics();
  const fetchLikeStatusUrl = `${BASE_URL}users/${session_id}/playlists/like/listitems?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}&content_id=${videodetailsitems?.last_episode?.content_id || videodetailsitems?.content_id}&catalog_id=${videodetailsitems?.catalog_id}`;

  const fetchLikeStatus = async () => {
    try {
      const response = await axios.get(fetchLikeStatusUrl);
      if (response.status === 200) {
        setIsLiked(true);
        setListItemId(response?.data?.data?.items[0]?.listitem_id);
      }
    } catch (err) {
      console.error("Server responded with an error:", err?.response?.data);
    }
  };
  const loginStatus = localStorage.getItem("login_status")
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const handleLikeButton = async (event) => {
    event.preventDefault();
    if (user_id) {
      try {
        const response = await axios.post(
          LikeURL,
          {
            auth_token: auth_token,
            access_token: access_token,
            listitem: {
              content_id: videodetailsitems?.last_episode?.content_id || videodetailsitems?.content_id,
              catalog_id: videodetailsitems?.catalog_id,
              // content_id: videodetailsitems?.content_id,
              // catalog_id: videodetailsitems?.catalog_id,
              like_count: videodetailsitems?.like_count && "true",
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          setIsLiked(true);
          // localStorage.setItem('isLiked', JSON.stringify(true));
          dispatch(setLikeDetails({ likeData: response?.data?.data[0] }));
          setLikeCount((prevCount) => prevCount + 1);
          setMylistemItemID(response?.data?.data[0]?.listitem_id);
          // toast.success("You have liked this video");
          if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent("like_button", {
              content_type: videodetailsitems.media_type,
              content_value: videodetailsitems.content_value,
              genre: videodetailsitems.genres.join(', '),
              video_name: videodetailsitems.title,
              video_language: videodetailsitems.language,
              event_id: 8,
              platform: "web",
              u_id:
              loginStatus === true
              ? user_id
              : guestuserid,
            });
          } else {
            console.warn("Tracking event not available");
          }
          

          
            logEvent(firebaseAnalytics, 'like_button', {
              content_type: videodetailsitems?.media_type ? videodetailsitems?.media_type : 'NA',
              video_name: videodetailsitems?.title,
              genre: videodetailsitems?.genres ? videodetailsitems?.genres : 'NA',
              video_language: videodetailsitems?.language,
              content_value: videodetailsitems?.content_value
            });
          const likedata = response?.data?.data;
          // console.log("Like data", response?.data?.data[0]);
          setLikeresponse(likedata[0]);
          // console.log("lc", likedata[0]?.like_count + 1);
          // setLikeCount(likedata[0]?.like_count + 1);
          // fetchLikeStatus()
        }
      } catch (err) {
        if(err?.response?.status === 422){
          dispatch(setModalOpen());
        }
        console.error("Server responded with an error:", err?.response?.data);
      }
    } else {
      dispatch(setModalOpen());
    }
  };

  const DislikeURL = `${BASE_URL}users/${session_id}/playlists/like/listitems/${mylistemItemID || listItemId}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  const handleDislikeButton = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(DislikeURL);
      if (response?.status === 200) {
        // toast.success("You have unliked this video");
        dispatch(setLikeDetails({ dislikeData: response?.data }));
        setLikeCount((prevCount) => prevCount - 1);
        setIsLiked(false);
        // fetchLikeStatus()
        // localStorage.setItem('isLiked', JSON.stringify(false));
        // console.log("Dislike data", response?.data);
        setDislikeresponse(response?.data);
      }
    } catch (err) {
      console.error("Server responded with an error:", err?.response?.data);
    }
  };


  useEffect(() => {
    if(session_id){
      fetchLikeStatus();
    }
  }, [session_id]);

  return (
    <>
      <div class="combination">
        <>
          {!isLiked ? (
            <div class="other-icons" onClick={handleLikeButton}>
              <>
                {isUserLoggedIn ? (
                  <FaRegThumbsUp />
                ) : (
                  <Link to="" onClick={() => dispatch(setModalOpen())}>
                    <FaRegThumbsUp />
                  </Link>
                )}
              </>
            </div>
          ) : (
            <div class="other-icons" onClick={handleDislikeButton}>
              <>
                <FaThumbsUp />
              </>
            </div>
          )}
        </>
        <div class="text">Like</div>
      </div>
    </>
  );
}
