import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'
import { PLAN_DETAILS_API } from '../services/config'
import { FaCheck } from 'react-icons/fa';
// import "../pages/Subscription-Plans/subscriptionplan.css"

import axios from 'axios'
import { toast } from "react-toastify";

const Plandetailstv = () => {
    const [aboutdata, setAboutdata] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [planDetails, setPlanDetails] = useState([]);
    const [currentsymbal, setcurrentsymbal] = useState("₹");



    useEffect(()=>{
      const Aboutcontent = async () =>{


        try {
            const response = await axios.get(PLAN_DETAILS_API);
            
            console.log("response Subscription", response);
              if (response?.status === 200) {
                const responsedata = response?.data?.data;
                //  console.log("subscription_plas_api", subscription_plas_api);
                // console.log("Subscription details", responsedata);
                var plans = responsedata.items;
                    if (plans.length === 0) return;

                  //  const categories = plans.map(plan => plan.display_title);
                  //   categories.unshift('Features');
                  //   setPlanCategories(categories);

                    // Handling plans with different lengths
                    if (plans.length === 1) {
                          setcurrentsymbal(plans[0].plans[0].currency_symbol);
                          setPlanDetails(plans[0].pack_details);
                        
                    } else {
                          setcurrentsymbal(plans[0].plans[0].currency_symbol);
                          // Merging basic and premium plan details
                          const basicPackDetails = plans[0].pack_details;
                          const premiumPackDetails = plans[1].pack_details;
                          const mergedDetails = basicPackDetails.map(plan => ({
                          ...plan,
                          premiumValue: premiumPackDetails.find(item => item.info.trim() === plan.info.trim())?.value,
                          }));
                          setPlanDetails(mergedDetails);
                    }
                setSubscriptionData(responsedata.items);
            }              
              //setAboutdata(menuitemslistd);
            
          } catch (error) {
            console.error("Error fetching data:", error?.message);
           
          }
     }

     return () => Aboutcontent()


    },[])
    const funInitailPayment = async () => {
        toast.error("Download the ETV WIN app now for an immersive experience like never before.");

    }
  return (
    <>


        <div className=''>
            <Container>
            <div className='about-main policy-content'>
            <h4>Subscription</h4>

            <Container fluid className='pading-left-right-class'>
                    <Row>
                        <Col lg={6}>
                           
                            <div className='colum'>
                                <div className="columns">
                                    <ul className=" prince-left">
                                        <li className="header"><span></span></li>
                                        {Array.isArray(planDetails) && planDetails.map(function (listitem, index) {
                                                return (
                                                    <>

                                                            <li>{listitem.info}</li>
                                                  
                                                    </>

                                                )
                                            
                                        })}
                                    

                                    </ul>
                                </div>



                                {Array.isArray(subscriptionData) && (
                                    <div>
                                        {subscriptionData.map(function (listitem, index) {
                                            
                                            if(index == 0){
                                            return (
                                                <div key={index} className="columns ">
                                                    <div className='colums-in'>
                                                        <ul className="price">
                                                            <React.Fragment>
                                                                <li className="header">{listitem.display_title}</li>
                                                                {listitem.pack_details && listitem.pack_details.map(function (planitem, planindex) {
                                                                    return (<li key={planindex}>{planitem.value == "yes"?<FaCheck />:planitem.value }   </li>);
                                                                })}
                                                            </React.Fragment>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                                            }
                                        })}
                                    </div>
                                    
                                )}

{Array.isArray(subscriptionData) && (
                                    <div>
                                        {subscriptionData.map(function (listitem, index) {
                                            if(index == 1){
                                            return (
                                                <div key={index} className="columns ">
                                                    <div className='colums-in'>
                                                        <ul className="price">
                                                            <React.Fragment>
                                                                <li className="header">{listitem.display_title}</li>
                                                                {listitem.pack_details && listitem.pack_details.map(function (planitem, planindex) {
                                                                    return (<li key={planindex}>{planitem.value == "yes"?<FaCheck />:planitem.value }   </li>);
                                                                })}
                                                            </React.Fragment>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                                            }
                                        })}
                                    </div>
                                    
                                )}


                                {/* <div className="columns">
                                    <ul className="price pricelight">
                                        <li className="header-two">Basic</li>

                                        <li >HD</li>
                                        <li><FaCheck /></li>
                                        <li><FaCheck /></li>
                                        <li>1 ( Mobile Only)</li>
                                        <li><FaCheck /></li>

                                    </ul>
                                </div> */}
                            </div>
                
                            <div className='PlansContent'>
                                HD, Full HD, 4K (2160p) Video Qualities are available only when content is supported in their respective resolutions
                            </div>
                
                        </Col>
                        <Col lg={6}>
                        {Array.isArray(subscriptionData) &&
  subscriptionData.map(function (listitem, listIndex) {
    return (
      <React.Fragment key={listIndex}>
        {listitem.plans &&
          listitem.plans.map(function (planitem, planIndex) {
            const plainindex = listIndex + planIndex;

           
            return (
              <React.Fragment key={plainindex}>
                <div className='form-ground-sub'>
                  <div
                    className={`premium-class `}
                  
                  >
                    <div className='postin-recon'>
                        {planitem.offer_description && <p>{planitem.offer_description}</p>}
                      
                    </div>
                  
                    <div className='premim-left'>
                      <div className='radios-main'>
                        <div className='radios-left'>
                          <label className="checkboxcolor">
                            
                          </label>
                        </div>
                        <div className='radios-right'>
                          <h6>{listitem.display_title}</h6>
                          <span>Per {planitem.title}</span>
                         

                        </div>
                      </div>
                    </div>
                    <div className='premim-right'>
                      <span className='amount-left'>
                        {planitem.striked_price !== "" && planitem.currency_symbol} {planitem.striked_price}
                      </span>
                      <span className='amount-right'>
                        {planitem.currency_symbol} {planitem.price}
                   
                          
                      

                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  })}
 <button type="button" id="buy-now"
  onClick={funInitailPayment}
  className={`next-button-color `} >
                                Buy Now
                                    </button>
<div class="PlansContent" >
<span>Subscribe to ETV Win to watch all the premium content
On your phone or computer go to: <span class="subscribe-url">https://www.etvwin.com/subscribe</span></span>
</div>
                            </Col>
                    </Row>
                </Container>
           
            </div>

            </Container>
        </div>

    </>
  )
}

export default Plandetailstv