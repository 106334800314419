import React, { useEffect } from "react";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Faq = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Header /> */}

      <div className="main ">
        <Container>
          <h1 className="faqh">Frequently Asked Questions</h1>
          <div className=" about-main">
            <p className="faq-question">
              
              How do I create an account on ETV Win?
            </p>
            <p className="faq-content">
              
              You can Signup for ETV Win by using of the below methods: <br />
              <p className="faq-content">
                &#42; Email address, Password creation, Mail Verification &
                followed by Profile Info
              </p>
              <p className="faq-content">
                &#42; Mobile Number, Password creation, OTP Verification &
                followed by Profile info
              </p>
              <p className="faq-content">&#42; Sign up with Google Account</p>
            </p>
            <p className="faq-question">
              
              I forgot my password how can I reset it ?
            </p>
            <p className="faq-content">
              
              In case you have forgotten your password, click on forgot password
              link and an email would sent to you with a link to reset password
              .
            </p>
            <br />
            <p className="faq-question"> How I can subscribe to ETV Win App?</p>
            <p className="faq-content">
              
              Subscribe on ETV Win with simple steps and enjoy the service on
              the web, mobile devices & Smart TVs
            </p>
            <p className="faq-content">
              
              Login to ETV Win with your credentials
            </p>
            <p className="faq-content">
              
              Go to Profile & click on Subscription status
            </p>
            <p className="faq-content"> Select the plan of your choice</p>
            <p className="faq-content"> Click on Subscribe & make Payment</p>
            <p className="faq-content">Or</p>
            <p className="faq-content">
              Login to ETV Win with your credentials
            </p>
            <p className="faq-content">
              Click on any premium content & hit subscribe button
            </p>
            <p className="faq-content">Select the plan of your choice</p>
            <p className="faq-content">
              Click on Subscribe & make Payment
            </p>
            <br />
            <p className="faq-question">
              
              What are the different subscription plans available?
            </p>
            <p className="faq-content">
              In India, currently two plans are available:
            </p>
            <p className="faq-content">Basic Plan:</p>
            <p className="faq-content">99 Youth Pack Quaterly </p>
            <p className="faq-content">Monthly (3 screens) - Rs. 99/- </p>
            <p className="faq-content">Yearly (3 screens) - Rs. 499/- </p>
            {/* <h1 className="faq-headings">Registration - ETV Win</h1> */}
            <br />
            <p className="faq-question">
              
              How many devices I can stream simultaneously?
            </p>
            <p className="faq-content">
              You can Stream in different platforms for different subscription
              Plans
            </p>
            <p className="faq-content">
              &#42; Youth Pack Quarterly &#8211; Rs.99
            </p>
            <p className="faq-content"> For india &#8211; 1 device </p>
            <p className="faq-content">
              &#42; Premium per month &#8211; Rs .99
            </p>
            <p className="faq-content">
              Any Devices but restricts to 1 TV in 3
            </p>
            <p className="faq-content">
              &#42; Premium Per Year &#8211; Rs 499
            </p>
            <p className="faq-content">
              Any 3 Devices but restricts to 1 TV in 3
            </p>
            <br />
            <p className="faq-question">
              
              What payment methods are accepted for subscription?
            </p>
            <p className="faq-content">
              Mobile App (Android) & Website: Credit Cards, Debit Cards, Debit
              Card + ATM Pin, Internet Banking, UPI, Phone Pe, G Pay, Amazon Pay
            </p>
            <p className="faq-content">&#42;Apple Pay </p>
            <p className="faq-content">&#42;Most credit and debit cards </p>
            <p className="faq-content">
              &#42;Store credit, from redeeming gift cards or from adding funds
              to your Apple ID
            </p>
            <p className="faq-content">
              &#42;Country-specific or region-specific additional methods
            </p>
            <br />
            <p className="faq-question">
              
              How do I upgrade my subscription plan?
            </p>
            <p className="faq-content">
              You can upgrade to the yearly pack at any time, depending on the
              type of subscription you currently have(monthly) & it is possible
              only when your subscription period is active. After upgrading,
              your subscription period will be adjusted considering the pack you
              have chosen to upgrade and the number of days used in your current
              subscription.
            </p>
            <p className="faq-content">or</p>
            <p className="faq-content">
              Just wait for your current subscription to end. Once it does, you
              can purchase another one.
            </p>
            <br />
            <p className="faq-question"> Can I cancel my subscription? How?</p>
            <p className="faq-content">
              If the subscription is paid via Net banking, UPI, it does not
              require cancellation and will expire automatically at the end of
              the current subscription period.
            </p>
            <p className="faq-content">&#42;All plans are non-refundable</p>
            <p className="faq-content">
              &#42;Cancellation does not lead to a refund. It only ensures that
              you will not be charged a renewal fee in future
            </p>
            <p className="faq-content">
              &#42;Your access remains active till the end of the current
              billing period even if you cancel membership midway
            </p>
            <p className="faq-content">
              &#42;For any other queries regarding your subscription, please
              reach out to us at &nbsp;
              <a className="supportmails" href="mailto:support@etvwin.com">
                
                support@etvwin.com
              </a>
            </p>
            <br />
            <p className="faq-question">
              
              I have opted for Auto-pay option, how I can deactivate it?
            </p>
            <p className="faq-content">
              To deactivate auto-pay, you'll typically need to follow these
              steps:
            </p>
            <p className="faq-content">
              &#42; Identify the Payment Method: Determine which payment method
              is set up for auto-pay. It could be a credit/debit card, bank
              account, or digital payment service like Paytm , phonepay etc...
            </p>
            <p className="faq-content">
              &#42; Log into Your Account: Access your account on the platform
              or service where auto-pay is set up. This could be a bank's online
              portal, a utility company's website, or a subscription service.
            </p>
            <p className="faq-content">
              &#42; Navigate to Billing or Payment Settings: Look for a section
              related to billing, payments, or subscriptions. There should be an
              option to manage your payment settings.
            </p>
            <p className="faq-content">
              <u>&#42;Find Auto-Pay Settings:</u> Within the payment settings,
              locate the section specifically for auto-pay or recurring
              payments.
            </p>
            <p className="faq-content">
              <u>&#42;Turn Off Auto-Pay:</u> There should be an option to
              disable or turn off auto-pay. Click on this option to deactivate
              it.
            </p>
            <p className="faq-content">
              <u>&#42;Confirm Deactivation (if necessary):</u> Some services may
              require you to confirm the deactivation of auto-pay. Follow any
              additional prompts or steps to complete the process.
            </p>
            <p className="faq-content">
              <u>&#42;Save Changes:</u> After disabling auto-pay, make sure to
              save your changes if there's a "Save" or "Update" button.
            </p>
            <p className="faq-content">
              <u>&#42;Verify Deactivation:</u> Check to ensure that auto-pay has
              been successfully deactivated. You may receive a confirmation
              message or email confirming the change.
            </p>
            <br />
            <p className="faq-question">
              
              Despite the payment successful message why my account is inactive?
            </p>
            <p className="faq-content">
              Sometimes it takes a 24hrs time after a successful payment to
              activate your subscription or Account .
            </p>
            <br />
            <p className="faq-question">How much does a subscription cost?</p>
            <p className="faq-content">
              Our Subscriptions and rights to the content are region &#8211;
              specific . subscription plans purchased in one country will not be
              valid in another country.
            </p>
            <p className="">
              
              <b>Inside India</b>
            </p>
            <p className="">&#42; Youth Pack Quaterly &#8211; Rs 99/-</p>
            <p className="">&#42; Premium per Month &#8211; Rs 99/- </p>
            <p className="">&#42; Premium Per Year &#8211; Rs 499/-</p>
            <p className="">
              
              <b>Outside India </b>
            </p>
            <p className="">&#8226; Monthly (3 screens) &#8211; $7.99</p>
            <p className="">&#8226; Yearly (3 screens) &#8211; $39.99</p>
            <p className="">
              &#8226; Youth pack (4 screens) &#8211; $24.99
            </p>
            <br />
            <p className="faq-question">
              Does the ETV Win App work with Chrome Cast?
            </p>
            <p className="faq-content">
              Yes ETV Win App Support Chrome cast Only For Android TV and Fire
              stick TV For Samsung and LG TV Please Use Third party screen
              sharing apps like :- Mira cast etc..
            </p>
            <br />
            <p className="faq-question">
              
              How to access ETV Win Content on Tv using Chrome cast ?
            </p>
            <p className="faq-content">
              &#42; At first , Make sure that your mobile device and Tv screen
              is connected with the same Wi-Fi
            </p>
            <p className="faq-content">
              &#42;Then Tap on the chrome cast Icon & select the device you want
              to cast on
            </p>
            <br />
            <h2 className="question text-center">Technical Support </h2> <br />
            <p className="faq-content">
              I am unable to login to my account? (Google Login, Mobile Login, ,
              Apple id login, E-mail login)
            </p>
            <p className="faq-content">
              
              If your device is not allowing you to log in Please check your
              internet connection. If the issue persists, Please visit &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link> &nbsp;
              to speak with a member of our customer care team. They will get in
              touch with you and take care of your problem.
            </p>
            <br />
            <p className="faq-question">
              
              My account is deleted how I can recover it?
            </p>
            <p className="faq-content">
              
              Please visit &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus &nbsp;
              </Link> 
              to talk with a member of our customer service team if your account
              is deleted. They will address your issue and get in contact with
              you and they will solve your issue as soon as possible .
            </p>
            <br />
            <p className="faq-question">
              How I can activate ETV Win on my Android TV / Samsung TV / LG TV /
              Fire TV?
            </p>
            <p className="faq-content">
              &#42; Visit &nbsp;
              <Link
                to="/Activate-Etv"
                className="activatetvwin"
                target="_blank"
              >
                https://www.etvwin.com/activatetv &nbsp;
              </Link>
              by typing the URL in the web browser of your mobile/laptop with
              your registered ID.
            </p>
            <p className="faq-content">
              &#42; Enter the code you see on your TV screen and click on verify
            </p>
            <p className="faq-content">or</p>
            <p className="faq-content">
              &#42; Log into your ETV Win mobile app/website, then click on
              Activate TV from the Menu icon & enter the code you see on your TV
              screen & click on Activate
            </p>
            <p className="faq-content">
              &#42; Once you verify the device, all your account information
              will be synced with the device such as your profile, My
              Subscription, Watchlist etc.
            </p>
            <br />
            <p className="faq-question">
              My session is automatically signed out, how I can restore it?
            </p>
            <p className="faq-content">
              &#42; Check Internet Connection: Ensure that you have a stable
              internet connection. Sometimes, a poor or intermittent connection
              can cause sessions to be terminated.
            </p>
            <p className="faq-content">
              &#42; Clear Browser Cache and Cookies: Your Mobile or Mobile
              browser’s cache and cookies may be causing issues with your
              session. Try clearing them and then attempt to sign in again.
            </p>
            <p className="faq-content">
              &#42;Restart Your Device : Close and reopen your web browser or
              Mobile . This can sometimes resolve issues related to session
              management.
            </p>
            <p className="faq-content">
              &#42; Try a Different Browser or Device: If possible, try signing
              in using a different web browser or device. This can help
              determine if the issue is specific to your current setup.
            </p>
            <p className="faq-content">
              &#42; Check Session Timeout Settings: Some websites or
              applications have session timeout settings that automatically sign
              users out after a period of inactivity. If this is the case, you
              may need to sign in again.
            </p>
            <p className="faq-content">
              &#42; <strong>Contact Support:</strong> If none of the above steps
              work, Please Please visit
            </p>
            <p className="faq-content">
              <a className="supportmails" href="mailto:contact@etvwin.com">
                contact@etvwin.com
              </a> &nbsp;
              to speak with a member of our customer care team.
            </p>
            <p className="faq-content">
              They will get in touch with you and take care of your problem.
            </p>
            <br />
            <p className="faq-question">
              
              How do I update the app to the latest version?
            </p>
            <p className="faq-content">
              
              &#42; Navigate to Updates: In the App Store or Google Play Store,
              find the "Updates" tab. This tab typically appears at the bottom
              of the screen.
            </p>
            <p className="faq-content">
              &#42; Check for Updates: Once you're in the Updates tab, the store
              will automatically check for any available updates for your
              installed apps. You should see a list of apps that have updates
              available.
            </p>
            <p className="faq-content">
              &#42; Update All or Selective Update: You usually have the option
              to update all apps at once or to select specific apps for
              updating. Choose the option that suits you best.
            </p>
            <p className="faq-content">
              &#42; Update Confirmation: Confirm the update process. Depending
              on your device settings, you may need to enter your Apple ID
              password, Google account password, or use biometric authentication
              (such as fingerprint or Face ID) to confirm the update.
            </p>
            <p className="faq-content">
              &#42; Wait for Download and Installation: The store will download
              the updates in the background, and once they are downloaded, the
              apps will be automatically updated. Depending on the size of the
              updates and your internet connection speed, this process may take
              a few moment
            </p>
            <p className="faq-content">
              &#42; Restart the App: After the updates are installed, you can
              open the updated app from your device's home screen.
            </p>
            <p className="faq-content">
              Remember, if you have automatic updates enabled on your device,
              apps will update automatically in the background without requiring
              manual intervention
            </p>
            <br />
            <p className="faq-question">
              The app is crashing frequently. How can I fix this?
            </p>
            <p className="faq-content">
              If the application crashes Kindly email
             &nbsp; <a className="supportmails" href="mailto:contact@etvwin.com">
                contact@etvwin.com
              </a> &nbsp;
              or &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link> &nbsp;
              with a screen grab or recorded video describing the problems you
              are having. When they get in touch with you, our Customer Team
              will address your problem.
            </p>
            <br />
            <p className="faq-question">
              ETV Win is loading very slow on my mobile / TV, please help?
            </p>
            <p className="faq-content">
              &#42; Check Your Internet Connection: Slow loading times can often
              be attributed to a poor internet connection. Make sure you're
              connected to a stable and high-speed Wi-Fi network or have a
              strong cellular signal if you're using mobile data.
            </p>
            <p className="faq-content">
              &#42; Restart the Device: Sometimes, simply restarting your mobile
              device or TV can help resolve temporary issues that may be causing
              slow loading times.
            </p>
            <p className="faq-content">
              &#42; Close Background Apps: If you have multiple apps running in
              the background, they could be consuming resources and affecting
              the performance of ETV Win. Close any unnecessary apps to free up
              system resources.
            </p>
            <p className="faq-content">
              &#42; Clear App Cache: Over time, the cache accumulated by the ETV
              Win app can slow down its performance. Try clearing the app cache
              from your device's settings and then relaunch the app.
            </p>
            <p className="faq-content">
              &#42; Update the App: Ensure that you're using the latest version
              of the ETV Win app. Developers often release updates to improve
              performance and fix bugs. Go to the app store on your device and
              check for any available updates for the ETV Win app.
            </p>
            <p className="faq-content">
              &#42; Check for Device Updates: If you're experiencing slow
              loading times on a smart TV or Mobile , make sure that your TV's
              firmware is up to date. Manufacturers often release updates to
              improve performance and compatibility.
            </p>
            <p className="faq-content">
              &#42; Contact ETV Win Support: If you've tried the above steps and
              are still experiencing slow loading times, it's possible that
              there may be an issue on ETV Win's end. Please Please visit
            </p>
            <p className="faq-content">
              <a className="supportmails" href="mailto:contact@etvwin.com">
                contact@etvwin.com
              </a> &nbsp;
              to speak with a member of our customer care team. They will get in
              touch with you and take care of your problem.
            </p>
            <br />
            <p className="faq-question">
              
              Video is constantly buffering on my device (device name), please
              help?
            </p>
            <p className="faq-content">
              
              &#42; Check Your Internet Connection: Slow or unstable internet
              connection is one of the most common causes of buffering issues.
              Run a speed test on your device to check your internet speed. You
              can use websites like speedtest.net or download a speed test app
              from your device's app store. Ensure that you have a stable and
              high-speed connection, especially if you're streaming HD or 4K
              content.
            </p>
            <p className="faq-content">
              &#42; Restart Your Router: Sometimes, a simple router restart can
              help improve internet performance. Unplug your router from power,
              wait for about 30 seconds, and then plug it back in. Allow your
              router to reboot fully, and then check if the buffering issue
              persists.
            </p>
            <p className="faq-content">
              &#42; Move Closer to the Router: If you're streaming over Wi-Fi,
              try moving closer to your router to improve signal strength. Walls
              and other obstructions can weaken Wi-Fi signals, leading to slower
              speeds and buffering issues.
            </p>
            <p className="faq-content">
              &#42; Use Wired Connection (Ethernet): If possible, connect your
              Tv devices directly to your router using an Ethernet cable instead
              of relying on Wi-Fi. Wired connections typically offer more stable
              and faster speeds compared to wireless connections.
            </p>
            <p className="faq-content">
              &#42; Close Background Apps: Other apps running in the background
              on your device could be consuming bandwidth and causing buffering
              issues. Close any unnecessary apps or processes to free up
              resources for streaming.
            </p>
            <p className="faq-content">
              &#42; Lower Video Quality Settings: If you're experiencing
              buffering while streaming HD or 4K content, try lowering the video
              quality settings to reduce the bandwidth requirements. Most
              streaming services allow you to adjust video quality settings
              manually.
            </p>
            <p className="faq-content">
              &#42; Update Your Device Software: Make sure that your device's
              operating system and streaming apps are up to date. Developers
              often release updates to fix bugs and improve performance.
            </p>
            <p className="faq-content">
              &#42; Contact Your Internet Service Provider (ISP): If you've
              tried the above steps and are still experiencing buffering issues,
              it's possible that there may be an issue with your internet
              service provider. Contact your ISP's customer support for
              assistance. They can help diagnose and troubleshoot any issues
              with your internet connection.
            </p>
            <br />
            <p className="faq-question">
              
              Why I am unable to screen cast ETV Win on my TV?
            </p>
            <p className="faq-content">
              
              &#42; <strong>App Restrictions:</strong> For Some Un verifed
              Devices we have restrictions that prevent screen casting or
              mirroring to external devices like TVs. This restriction could be
              intentional to protect copyrighted content or due to technical
              limitations of the app.
            </p>
            <p className="faq-content">
              &#42; <strong>Device Compatibility:</strong> Not all devices
              support screen casting or screen mirroring. Make sure that both
              your mobile device or computer and your TV support the same screen
              casting technology, such as Chrome cast, Air Play,
            </p>
            <p className="faq-content">
              &#42; <strong>Network Issues:</strong> Screen casting often relies
              on a stable Wi-Fi network. If your Wi-Fi network is experiencing
              issues or if there's congestion on the network, it may affect your
              ability to screen cast effectively.
            </p>
            <p className="faq-content">
              &#42; <strong>App or Device Updates:</strong> Ensure that both the
              ETV Win app and your device's operating system are up to date.
              Developers often release updates to fix bugs and improve
              compatibility with external devices.
            </p>
            <p className="faq-content">
              &#42; <strong>Screen Casting Method:</strong> Depending on your
              device and TV, there may be different methods for screen casting,
              such as using built-in features like Chrome cast or Air Play, or
              using third-party apps. Make sure you're using the correct method
              for your devices.
            </p>
            <p className="faq-content">
              &#42; <strong>Troubleshoot Specific Devices:</strong> If you're
              using a specific device or TV brand, consult the manufacturer's
              support documentation or website for troubleshooting tips related
              to screen casting.
            </p>
            <p className="faq-content">
              If you've tried the above steps and are still unable to screen
              cast ETV Win to your TV Please visit &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link> &nbsp;
              to speak with a member of our customer care team. They will get in
              touch with you and take care of your problem.
            </p>
            <br />
            <p className="faq-question">
              
              I can't connect to the internet. Can I still watch content?
            </p>
            <p className="faq-content">
              
              No Without internet access, Content cannot be watched.
            </p>
            <br />
            <h2 className="question text-center">
              
              Content and Streaming
            </h2>
            <br />
            {/* <p className="faq-question">What type of content is available on the platform?</p> */}
            <p className="faq-question">
              
              How to delete my account from ETV Win?
            </p>
            <p className="faq-content">
              Movies, Shows, Serials , Web series , Events , Recipes , Health
              Videos , Before Tv content.
            </p>
            <br />
            <p className="faq-question">Are the subtitles available?</p>
            <p className="faq-content">
              No, subtitles are not available at this time. They will become
              accessible with feature App updates.
            </p>
            <br />
            <p className="faq-question">
              Can I download content for offline viewing?
            </p>
            <p className="faq-content">
              Yes You can download offline viewing content . the offline
              downloads get auto-deleted after 7 days
            </p>
            <br />
            <p className="faq-question">
              
              How frequently is new content added to the platform?
            </p>
            <p className="faq-content"> New Content is Updated Daily . </p>
            <br />
            <p className="faq-question">
              
              Is there parental control available for restricting content?
            </p>
            <p className="faq-content">
              
              No Parental control is not available at this time . they will
              become accessible with feature App updates
            </p>
            <br />
            <p className="faq-question"> How do I search for content?</p>
            <p className="faq-content">
              
              Click on Search Icon and the enter the text of content name what
              you want to watch
            </p>
            <br />
            <p className="faq-question">
              Can I download content to watch offline?
            </p>
            <p className="faq-content">
              Yes You can download content to watch offline
            </p>
            <br />
            <p className="faq-question">What is the video quality? </p>
            <p className="faq-content">
              Video Qualities are Low to Up to Full Hd
            </p>
            <br />
            <p className="faq-question">
              I'm having trouble streaming content. What should I do?
            </p>
            <p className="faq-content">
              Please check your internet issue if the issue is still persisting
              Please visit to &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link>
            </p>
            <br />
            <p className="faq-question">
              Do you have international content dubbed in Telugu?
            </p>
            <p className="faq-content">
              Yes we have some international dubbed content in Telugu .
            </p>
            <br />
            <p className="faq-question">
              Which other language content you have, which is dubbed in Telugu?
            </p>
            <p className="faq-content">
              We have Tamil & Malayalam movies dubbed in Telugu
            </p>
            <br />
            <p className="faq-question">What are the upcoming movies?</p>
            <p className="faq-content">
              You can check in home screen Upcoming movies section for daily
              upcoming movie updates
            </p>
            <p className="faq-question">
              Why the video is not playing in my device?
            </p>
            <p className="faq-content">
              Check your internet, if the issue is still persisting persisting
              Please visit to &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link>
            </p>
            <br />
            <p className="faq-question">
              Why I can't see Bal Bharat programs on ETV Win?
            </p>
            <p className="faq-content">
              You can Watch ETV Balbharat Programs on &nbsp;
              <a
                href="https://www.etvbalbharat.com/"
                className="activatetvwin"
                target="_blank"
                rel="noreferrer"
              >
                www.etvbalbharat.com
              </a>
            </p>
            <br />
            <p className="faq-question">
              Why I am unable to see all episodes of (name of the TV program /
              show?)
            </p>
            <p className="faq-content">
              Some Content like Sadhguru sai, suryaputra karna, jai ganesha will
              have content only up to a week.
            </p>
            <br />
            <h2 className="text-center">Device Compatibility</h2> <br />
            <p className="faq-question">
              Which devices are compatible with the platform?
            </p>
            <p className="faq-content">
              We are Available on Andriod Mobile , Andriod Tv , Website , LG TV
              , Tizen TV , Firestick TV .
            </p>
            <br />
            <p className="faq-question">
              Can I use the platform on multiple devices simultaneously?
            </p>
            <p className="faq-content">
              You can stream in different platforms for different subscription
              plans
            </p>
            <p className="faq-content">
              &#42; Youth Pack per Quaterly - Rs.99 For India - 1 device
            </p>
            <p className="faq-content">Other Region - 4 devices </p>
            <p className="faq-content">
              &#42;Premium per Month - Rs.99 Any 3 Devices but restricts to 1 Tv
              in 3
            </p>
            <p className="faq-content">
              &#42;Premium Per Year - Rs.499 Any 3 Devices but restricts to 1 Tv
              in 3
            </p>
            <br />
            <p className="faq-question">
              &#42;Premium Per Year - Rs.499 Any 3 Devices but restricts to 1 Tv
              in 3
            </p>
            <p className="faq-content">
              To Install ETV Win, please visit the below stores in respective
              Smart TV platforms:
            </p>
            <p className="faq-content">
              
              <strong>Android TV:</strong> Visit Google Play Store, Download &
              Install ETV Win
            </p>
            <p className="faq-content">
              <strong>Fire TV Stick:</strong> Visit Amazon App store, Download &
              Install ETV Win
            </p>
            <p className="faq-content">
              <strong>Lg Tv :</strong> Visit Lg App store, Download & Install
              ETV Win
            </p>
            <br />
            <p className="faq-question">
              Is there a mobile app available for IOS and Android devices?
            </p>
            <p className="faq-content">
              Yes ETV Win App is available on IOS & Android Mobiles
            </p>
            <br />
            <p className="faq-question">
              Can I watch content on my computer or laptop?
            </p>
            <p className="faq-content">
              Yes You can Watch Please visit &nbsp;
              <a
                className="activatetvwin"
                href="https://www.etvwin.com"
                style={{ Color: "#FFC908" }}
                target="_blank"
              >
                www.etvwin.com
              </a> &nbsp;
              to watch content on laptop or computer
            </p>
            <br />
            <p className="faq-question">
              Which CTVs brands ETV Win is available? (Android TV, Samsung, LG,
              FireTV)
            </p>
            <p className="faq-content">
              We are Available on Andriod Tv , Firestick Tv , Samsung Tv , Lg Tv
              .
            </p>
            <br />
            <h2 className="text-center">Billing & Payments</h2> <br />
            <p className="question">
              I have been charged incorrectly, what should I do now?
            </p>
            <p className="faq-content">
              You can Please visit to to speak with a member of our customer
              care team. They will get in touch with you and take care of your
              problem.
            </p>
            <br />
            <p className="question">How can I get a refund?</p>
            <p className="faq-content">There is no refund policy with us </p>
            <br />
            <p className="question">In how many days I can get a refund?</p>
            <p className="faq-content">There is no refund Policy with us </p>
            <br />
            <p className="question">
              How do I update my payment method / information?
            </p>
            <p className="faq-content">
              How do I update my payment method / information?
            </p>
            <br />
            <p className="question">
              Will I be notified before my subscription renews?
            </p>
            <p className="faq-content">
              
              Yes You will receive a Subscription renew Alert Mail before 10
              days
            </p>
            <br />
            <p className="question">
              Can I get a receipt for my subscription payments?
            </p>
            <p className="faq-content">
              
              Yes You will receive a Payment successful receipt via mail
            </p>
            <br />
            <p className="question">
              Are there any additional charges or hidden fees?
            </p>
            <p className="faq-content">
              
              There is no additional charges and hidden fees
            </p>
            <br />
            <p className="question">
              When will I be charged for my subscription?
            </p>
            <p className="faq-content">
              
              When will I be charged for my subscription?
            </p>
            <br />
            <p className="question">
              Can I cancel my subscription in between and get refund for unused
              days?
            </p>
            <p className="faq-content">
              
              No You cannot cancel subscription in between unused days.
            </p>
            <br />
            <h2 className="text-center"> Regional Access</h2> <br />
            <p className="question">
              Is the platform available in regions outside of India?
            </p>
            <p className="faq-content">
              
              Yes ETV Win Platform is Available all over the world
            </p>
            <br />
            {/* <p className='question'>Why some of the content is not available in my country (name of the country)? (Licencing Restrictions)</p>  */}
            {/* <p className='faq-content'> Yes ETV Win Platform is Available all over the world </p> */}
            {/* <br /> */}
            <p className="question">
              Can I access the platform while traveling abroad?
            </p>
            <p className="faq-content">
              
              You can access the content only in the region where you purchased
              the subscription
            </p>
            <br />
            <p className="question">
              Do you offer customer support in languages other than Telugu?
            </p>
            <p className="faq-content">
              
              Yes we do offer customer support in Hindi , English also
            </p>
            <br />
            <p className="question">
              How can I switch the language preferences on the platform?
            </p>
            <p className="faq-content">
              
              You can't switch the language in ETV win App because ETV Win
              Platform Is offering only Telugu content.
            </p>
            <br />
            <p className="question">
              Do you offer services in my country (name of the country)?
            </p>
            <p className="faq-content">
              
              ETV Win services is all over the world For Telugu language
            </p>
            <br />
            <p className="question">
              How much does a subscription cost in my currency?
            </p>
            <p className="">Inside India </p>
            <p className="">&#42; Youth Pack Quaterly &#8211; Rs 99/-</p>
            <p className="">&#42; Premium per Month &#8211; Rs 99/- </p>
            <p className="">&#42; Premium Per Year &#8211; Rs 499/-</p>
            <p className="">Outside India </p>
            <p className="">&#8226; Monthly (3 screens) &#8211; $7.99</p>
            <p className="">&#8226; Yearly (3 screens) &#8211; $39.99</p>
            <p className="">&#8226; Youth pack (4 screens) &#8211; $24.99</p>
            <br />
            <p className="faq-question">
              I took subscription in India; can I use it in the country I am
              presently residing in (name of the country)?
            </p>
            <p className="faq-content">
              You can watch content only in the region where you purchased the
              subscription
            </p>
            <br />
            <p className="faq-question">
              ETV Win is not available on (aggregator platform name)?
            </p>
            <p className="faq-content">
              You can Send the details where Etv win app is not available we
              will try to integrate with those platforms
            </p>
            <br />
            <h2 className="text-center">Feedback & Suggestions </h2> <br />
            <p className="question">
              How can I provide feedback about the platform or content?
            </p>
            <p className="faq-content">
              Go to &nbsp;
              <a
                className="activatetvwin"
                href="https://www.etvwin.com"
                style={{ Color: "#FFC908" }}
                target="_blank"
              >
                www.etvwin.com
              </a>
            </p>
            <p className="faq-content">
              Scroll down to footer part there you will find feed back &
              suggestions form
            </p>
            <p className="faq-content">Write your valuable feedback </p> <br />
            <p className="question">
              Can I suggest new features or improvements?
            </p>
            <p className="faq-content">
              Yes you can suggest new features or improvements because we will
              gave more focus on users suggestions
            </p>
            <br />
            {/* <p className="faq-question">Is there a community forum or platform for discussions with other users?</p> */}
            {/* <p className='faq-content'></p> */}
            <p className="faq-question">
              How can I contact customer support for further assistance?
            </p>
            <p className="faq-content">
              You can visit us on &nbsp;
              <Link to="/contactus" className="activatetvwin" target="_blank">
                https://www.etvwin.com/contactus
              </Link> &nbsp;
              or
              <p className="faq-content">
                Give us a call on phone:
                <a href="tel:+8142212345">+8142212345</a>,
                <a href="tel:8008020555">8008020555</a>
              </p>
              or write us to our <strong>Email</strong> :
              <a className="supportmail" href="mailto:contact@etvwin.com">
                contact@etvwin.com
              </a>
            </p>
            <br />
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Faq;
