import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  likeData: {},
  dislikeData: {},
  isLiked: false,
  initialCount: ""
};

export const likeFeatureSlice = createSlice({
  name: 'likeFeature',
  initialState,
  reducers: {
    setLikeDetails: (state, action) => {
      state.likeData = action.payload.likeData;
      state.dislikeData = action.payload.dislikeData;
    },
    isLikedVideo: (state, action) => {
      state.isLiked = true
    },
    isDisLikedVideo: (state) => {
        state.isLiked = false
    },
    likeVideo: (state) => {
      state.initialCount += 1;
    },
    dislikeVideo: (state) => {
      state.initialCount -= 1;
    },
  },
});

export const { setLikeDetails, likeVideo, dislikeVideo } = likeFeatureSlice.actions;

export default likeFeatureSlice.reducer;
